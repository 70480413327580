
module.exports.LIST_NETWORK_DEVICES = `query networkDevices ($netId: String) {
  networkDevices (netId: $netId) {
    id
    teamId
    netId
    userId
    name
    mac
    ip
    enabledUntil
    disabledUntil
    state { enabled, overriddenFor }
  }
}`;

module.exports.LIST_NETWORK_RULES = `query networkRules ($netId: String) {
  networkRules (netId: $netId) {
    id
    teamId
    netId
    name
    Devices { id, name }
  }
}`;

module.exports.GET_NETWORK_DEVICE = `
  query 
    (
      $id: String!
    ) {
  networkDevice (
    id: $id
  ) {
    id
    teamId
    netId
    userId
    name
    mac
    ip
    enabledUntil
    disabledUntil
    state { enabled, overriddenFor }
  }
}`;

module.exports.GET_NETWORK_RULE = `
  query 
    (
      $id: String!
    ) {
  networkRule (
    id: $id
  ) {
    id
    teamId
    netId
    name
    Devices { id, name }
    schedule {
        mon 
        tue 
        wed 
        thu 
        fri 
        sat 
        sun 
      }
    condition {
      completedTags
      }
  }
}`;

module.exports.GET_NETWORK = `
  query 
    (
      $id: String!
    ) {
  network (
    id: $id
  ) {
    id
    teamId
    name
    password
    dns
    key
  }
}`;
