import React, { useEffect } from 'react';
import {Link} from 'react-router-dom';
import {t} from '@lingui/macro';
import { useQuery} from 'urql'

import i18n from '../i18n';

import { LIST_REWARDS } from '@darescouts/api-graphql/rewards/rewardsQueries'
import { LIST_KIDS } from '@darescouts/api-graphql/kids/kidsQueries'
import NewAccountRedirector from '../components/NewAccountRedirector';
import PointType from '../components/PointType';
import Points from '../components/Points';
import {useSelector} from 'react-redux';
import {getCurrentTeam} from '../store/slices/teamsSlice';

const Rewards = () => {
  const [rewards] = useQuery({
    query: LIST_REWARDS,
    requestPolicy: 'cache-and-network',
  });

  const [kids] = useQuery({
    query: LIST_KIDS,
    requestPolicy: 'cache-and-network',
  });

  const currentTeam = useSelector(getCurrentTeam);

  if (rewards.fetching) return <p>Loading rewards...</p>;
  if (rewards.error) return <p>Error loading list of rewards<button className="bg-green-600 m-1 p-1 hover:bg-blue-500 rounded text-sm text-white" onClick={() => window.location.reload(true)}>
    Reload
  </button></p>;
  if (!rewards.data || !rewards.data.rewards) return <p>No rewards data ?<button className="bg-green-600 m-1 p-1 hover:bg-blue-500 rounded text-sm text-white" onClick={() => window.location.reload(true)}>
    Reload
  </button></p>;

  if (kids.fetching) return <p>Loading rewards...</p>;
  if (kids.error) return <p>Error loading list of rewards<button className="bg-green-600 m-1 p-1 hover:bg-blue-500 rounded text-sm text-white" onClick={() => window.location.reload(true)}>
    Reload
  </button></p>;
  if (!kids.data || !kids.data.kids) return <p>No kids data ?<button className="bg-green-600 m-1 p-1 hover:bg-blue-500 rounded text-sm text-white" onClick={() => window.location.reload(true)}>
    Reload
  </button></p>;

  const dateFormatOptions = new Intl.DateTimeFormat('en-au', {
    dateStyle: 'short',
  });

  // const pointsTypes = [
  //   { id: 'S', name: 'Silver' },
  //   { id: 'G', name: 'Gold' },
  //   { id: 'D', name: 'Diamond' },
  // ];

  return (<>
    <div className="pb-8">
      <h2 className="text-2xl pb-2">All rewards</h2>
      <p className="text-gray-700">
        This is a list of all rewards. <br/>
        <br/>
        <Link className="link" to="/rewards/new">
          {i18n._(t('rewards.new')`Add a new Reward`)}
        </Link>
        <br/>
        <Link className="link" to="/rewards_give/reward/Anyone">
          {i18n._(t('rewards.give_a_reward')`Give a Reward`)}
        </Link>

      </p>
    </div>
    <div className="col-span-2">
      {rewards.data.rewards.length === 0 && <div>
        No rewards
        <NewAccountRedirector />
      </div>}

      {rewards.data.rewards.map((reward) => {

        return (
          <div key={reward.id} className="pb-3 mb-6 border-b-2 border-gray-300">
            <div className="col-span-3">
              <h1 className="text-gray-700">
                {reward.availableHours}
              </h1>
              <div className="float-right">
                {['Anyone'].includes(reward.who[0]) &&  <div key={`reward-${reward.id}-teamOrAnyone`} className="mr-1 text-xs inline-flex items-center px-3 py-1 bg-blue-200 text-blue-700 rounded-full">
                  <Link className="" to={`/rewards_give/${reward.id}/${reward.who[0]}`}>
                    {reward.who[0]}
                  </Link>
                </div>}
                {!['Anyone'].includes(reward.who[0]) && kids.data.kids.filter(kid => reward.who.includes(kid.id) || reward.who.includes('Anyone')).map((kid) => {
                  return (
                    <div key={`reward-${reward.id}-${kid.id}`} className="mr-1 text-xs inline-flex items-center px-3 py-1 bg-blue-200 text-blue-700 rounded-full">
                      <Link className="" to={`/rewards_give/${reward.id}/${kid.id}`}>
                        {kid.name}
                      </Link>
                    </div>
                  )
                })}
              </div>
              <Link className="text-lg text-gray-800" to={`/rewards/${reward.id}`}>
                {reward.title}
              </Link> <br/>
              <span className="text-sm">
                <Points points={reward.points} type={reward.pointsType} />
              </span>
            </div>
          </div>
        )
      })}

      {!!rewards.data.rewards.length && <div className="text-sm text-gray-700">
        <strong>Tip:</strong> Select the reward to manage it.
      </div>}

    </div>
  </>);
};

export default Rewards;
