import {
  call,
  put,
  takeEvery,
} from 'redux-saga/effects';

import identityAPI from '../../identityAPI.js';

import memberUserEditSlice from '../slices/memberUserEditSlice';

function* load(action) {
  try {
    const data = yield call(identityAPI.loadMemberUser, action.payload);
    yield put(memberUserEditSlice.actions.success(data));
  } catch (error) {
    console.log('watchMemberUserEditLoad failed to load user', error);
    yield put(memberUserEditSlice.actions.error(error.message));
  }
}

export default function* watchMemberUserEditLoad() {
  yield takeEvery(memberUserEditSlice.actions.load, load);
}
