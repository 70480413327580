import React, { useEffect } from 'react';
import {Link} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {t} from '@lingui/macro';

import i18n from '../i18n';
import { getMembers } from '../store/slices/membersSlice';
import {getTeams} from '../store/slices/teamsSlice';
import NewAccountRedirector from '../components/NewAccountRedirector';

const Members = () => {
  const members = useSelector(getMembers);
  const teams = useSelector(getTeams);

  return (<>
    <div className="pb-8">
      <h2 className="text-2xl pb-2">Partners and Kids</h2>
      <p className="text-gray-700">
        Manage kids or invite partners from here.<br/>
        <br/>
        <Link className="link" to="/members/new?type=kid">
          - {i18n._(t('members.addKid')`Add kid`)}
        </Link>
        <br/>
        <Link className="link" to="/members/new?type=parent">
          - {i18n._(t('members.invitePartner')`Invite Partner`)}
        </Link>
        <br/>
      </p>
    </div>
    <div className="col-span-2">
      <NewAccountRedirector />
      {/*do not how Team Shared User*/}
      {members.items.filter(member => member.User.ownedByTeamId !== member.User.id).map((member) => {
        return (
          <div key={member.id} className="py-2">

            {(member.User.ownedByTeamId === teams.currentTeamId) && (
              <Link className="link" to={`/members/${member.id}`}>
                {member.User.name}
              </Link>
            )}

            {(member.User.ownedByTeamId !== teams.currentTeamId) && (
              <span>
                {member.User.name}
              </span>
            )}

            {member.Roles.filter((memberRole) => memberRole.code !== 'member').map((memberRole) => {
              return (
                <span key={memberRole.code} className="ml-2 mr-1 text-xs inline-flex items-center px-3 py-1 rounded-full bg-blue-200 text-blue-700">
                  {memberRole.name}
                </span>
              )
            })}
          </div>
        )
      })}
    </div>

  </>);
};

export default Members;
