import React from 'react';
import classNames from 'classnames';
import goldCoinSVG from '../images/gold_coin.svg';
import silverCoinSVG from '../images/silver_coin.svg';
import diamondCoinSVG from '../images/diamond_coin.svg';

const PointType = ({
  type,
  size = 24,
  color,
  coinClasses = [ 'inline-block' ]
}) => {
  let coin;

  switch (type) {
    case 'S':
      coin = silverCoinSVG;
      break;
    case 'G':
      coin = goldCoinSVG;
      break;
    case 'D':
      coin = diamondCoinSVG;
      break;
    default:
      coin = silverCoinSVG;
      break;
  }

  return <img color={color} className={classNames(coinClasses)} src={coin} width={size} />

}

export default PointType;
