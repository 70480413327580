import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import darescouts from '../images/darescouts.svg';
import {appName} from '../config';

const ScrollToTop = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    // window.scrollTo(1, 0)
    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 500);
  }, [pathname])

  return (<></>)
}

export default ScrollToTop;
