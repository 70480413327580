import React from 'react';
import { Link } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import { t } from '@lingui/macro';
import i18n from '../i18n';

const NotFound = () => (
  <div className="container">
    <div className="md:grid md:grid-cols-3 md:gap-4 pt-4 pb-4">
      <div className="pb-8">
        <h2 className="text-2xl pb-2">404</h2>
        <p>
          Page Not Found
        </p>
      </div>

      <div className="col-span-2">
        Sorry, we can't find the page you were looking for.<br/>
        <br/>
        <Link className="text-blue-600" to="/">Click here to return</Link>
      </div>
    </div>
  </div>
);

export default NotFound;
