import React, {useEffect, useState, useMemo} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import SignOut from '@iconscout/react-unicons/icons/uil-signout';
// eslint-disable-next-line import/no-extraneous-dependencies
import { t } from '@lingui/macro';
import i18n from './i18n';

import { Provider as UrqlProvider } from 'urql';

import authSlice, { getAuth } from './store/slices/authSlice';
import userSlice, { getUser } from './store/slices/userSlice';
import { getCurrentTeam } from './store/slices/teamsSlice';
import AuthorizeIcon from '@iconscout/react-unicons/icons/uil-shield-check';
import UsersIcon from '@iconscout/react-unicons/icons/uil-users-alt';
import DashboardIcon from '@iconscout/react-unicons/icons/uil-schedule';
import ChartIcon from '@iconscout/react-unicons/icons/uil-chart-line';
import SettingsIcon from '@iconscout/react-unicons/icons/uil-setting';
import TasksIcon from '@iconscout/react-unicons/icons/uil-clipboard-notes';
import NetworkIcon from '@iconscout/react-unicons/icons/uil-table-tennis';
import rewardsIconSVG from './images/gift.svg';
import useInterval from './hooks/interval';
import configureClient from './urqlClient';
import identityAPI from './identityAPI';

import Button from './components/Button';
import TopNav from './components/TopNav';
import ScrollToTop from './components/ScrollToTop';
import FaltalError from './components/FaltalError';
import darescouts from './images/darescouts.svg';
import {appName} from './config';
import Version from './Version';

const AppLayoutPrivate = ({ children, ...rest }) => {
  const dispatch = useDispatch();
  const auth = useSelector(getAuth);
  const user = useSelector(getUser);
  const currentTeam = useSelector(getCurrentTeam);

  const handleSignOut = async (e) => {
    e.preventDefault();
    await identityAPI.resetTokens();
    dispatch(authSlice.actions.signOut());
  };

  useEffect(() => {
    // no need to load the user on every render
    if (user.name) {
      return;
    }

    console.log('loading user...');
    dispatch(userSlice.actions.load('me'));
  }, [auth]);

  if (user.isLoading) return <div className="">
    <TopNav />
    <div className="container">
      <div className="md:grid md:grid-cols-3 md:gap-4 pt-4 pb-4 heightzer">
        {i18n._(t('privateLayout.loadingUser')`Loading user details`)}
      </div>
    </div>
  </div>;

  if (user.error) return  <FaltalError
    handleSignOut={(e) => handleSignOut(e)}
    title={i18n._(t('privateLayout.errorLoadingUser')`Error loading user details`)}
    error={user.error}
  />;

  // todo: investigate how to reset this configured client after logout (maybe passin the selected teamId)
  //       to avoid reloading after logout
  const client = configureClient();
  if (!client) return  <FaltalError
    handleSignOut={(e) => handleSignOut(e)}
    title={i18n._(t('privateLayout.loadingUser')`Unable to use specified client service`)}
  />;

  return (
    <UrqlProvider value={client}>
      <div className="w-full h-screen">
        <div className="">
          <Version />
          <ScrollToTop />
        </div>
        <div className="topNav">
          <TopNav />
        </div>
        <section id="bottom-navigation" className="block fixed inset-x-0 bottom-0 z-10 bg-white shadow">
          <div id="tabs" className="flex justify-between">

            <Link className="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1" to="/">
              <DashboardIcon color="#2D3748" className="inline-block mb-1" size={30} />
              <span className="tab tab-home block text-xs">
                {i18n._(t('links.dashboard')`Daily`)}
                </span>
            </Link>

            <Link className="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1" to="/tasks">
              <TasksIcon color="#2D3748" className="inline-block mb-1" size={30} />
              <span className="tab tab-home block text-xs">
                {i18n._(t('links.tasks.list')`Tasks`)}
                </span>
            </Link>

            <Link className="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1" to="/rewards">
              <img color="#2D3748" className="inline-block mb-1" src={rewardsIconSVG} width="30" />
              <span className="tab tab-home block text-xs">
                {i18n._(t('links.rewards.list')`Rewards`)}
                </span>
            </Link>

            <Link className="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1" to="/stats">
              <ChartIcon color="#2D3748" className="inline-block mb-1" size={30} />
              <span className="tab tab-home block text-xs">
                {i18n._(t('links.stats.list')`Stats`)}
                </span>
            </Link>

            {currentTeam.enabledActions.includes('FN_NET') && <Link className="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1" to="/net/devices">
              <NetworkIcon color="#2D3748" className="inline-block mb-1" size={30} />
              <span className="tab tab-home block text-xs">
                {i18n._(t('links.network.list')`Devices`)}
                </span>
            </Link>}

            <Link className="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1" to="/kids/authorize">
              <AuthorizeIcon color="#2D3748" className="inline-block mb-1" size={30} />
              <span className="tab tab-home block text-xs">
                {i18n._(t('links.authorizeKidsApp')`Authorize`)}
                </span>
            </Link>

            <Link className="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1" to="/settings">
              <SettingsIcon color="#2D3748" className="inline-block mb-1" size={30} />
              <span className="tab tab-home block text-xs">
                {i18n._(t('links.settings')`Settings`)}
                </span>
            </Link>

          </div>
        </section>

        <div className="container pb-24">
          <div className="md:grid md:grid-cols-3 md:gap-4 pt-4 pb-4">
            {children}
          </div>
        </div>
      </div>
      <div className="h-2">
        {/*space to force scroll bar to be available*/}
      </div>
    </UrqlProvider>
  );
};

export default AppLayoutPrivate;
