import qs from 'qs';
import { clientId, identityServer, refreshTokenBefore, expectedService, appVersion, appHost } from './config';

import { Storage } from '@capacitor/storage';

let alreadyRefreshingToken = false;
const supporttedTokenVersion = '0.1.3'; // must match identity server AuthService.generateSignedInJWTToken() payload.v
const currentTokens = {
  userId: '',
  accessToken: '',
  accessTokenExpiresAt: '',
  refreshToken: '',
  refreshTokenExpiresAt: '',
  decoded: {},
  availableTeams: [],
};

const appVersionDetails = {
  loaded: appVersion,
  available: '0.0.0',
  lastCheck: 0,
};

const getServiceDetails = () => {
  return {
    service: getService(),
    expectedService: expectedService,
  }
}

const getTokens = () => {
  console.log('getTokens: ', currentTokens);
  return {
    ...currentTokens,
  };
};

const loadTokens = async () => {
  console.log('loadTokens:');
  // todo: should reset if refreshTokenExpiresAt expired and then return
  try {
    const ret = await Storage.get({ key: 'currentTokens' });
    const decodedTokens = JSON.parse(ret.value);

    if (!decodedTokens) {
      console.log('first time? reset');
      await resetTokens();
      return currentTokens;
    }

    currentTokens.userId = decodedTokens.userId;
    currentTokens.accessToken = decodedTokens.accessToken;
    currentTokens.accessTokenExpiresAt = decodedTokens.accessTokenExpiresAt;
    currentTokens.refreshToken = decodedTokens.refreshToken;
    currentTokens.refreshTokenExpiresAt = decodedTokens.refreshTokenExpiresAt;
    currentTokens.decoded = JSON.parse(atob(currentTokens.accessToken.split('.')[1]));
    currentTokens.availableTeams = getTeams();

    console.log('loadTokens: userId', currentTokens.userId);
  } catch (e) {
    console.log('loadTokens: error', e);
    await resetTokens();
  }
  return currentTokens;
};

const getService = () => {
  if (!currentTokens.accessToken) {
    return {
      api: '',
      graphqlWS: '',
      graphqlUrl: '',
      accessToken: '',
    };
  }

  let decoded = JSON.parse(atob(currentTokens.accessToken.split('.')[1]));

  // get the selected team or the 1st one
  let selectedTeamIdx = decoded.t.findIndex(t => t.p) || 0;
  selectedTeamIdx = selectedTeamIdx >= 0 ? selectedTeamIdx : 0;

  // ensure decoded version
  if (decoded.v !== supporttedTokenVersion || !decoded.t[selectedTeamIdx]) {
    console.log('error with supporttedTokenVersion or no base team')
    return {
      api: '',
      graphqlWS: '',
      graphqlUrl: '',
      accessToken: '',
    };
  }

  const service = decoded.t[selectedTeamIdx].s.find(availableService => availableService.i === expectedService.id);
  return {
    api: `http${service.s ? 's' : ''}://${service.e}`,
    graphqlWS: `ws${service.s ? 's' : ''}://${service.e}/graphql`,
    graphqlUrl: `http${service.s ? 's' : ''}://${service.e}/graphql`,
    accessToken: currentTokens.accessToken, // top level token grants access to relevant services
  };
};

const saveTokens = async (tokens) => {
  console.log('saveTokens: userId', tokens.userId);
  currentTokens.userId = tokens.userId;
  currentTokens.accessToken = tokens.accessToken;
  currentTokens.accessTokenExpiresAt = tokens.accessTokenExpiresAt;
  currentTokens.refreshToken = tokens.refreshToken;
  currentTokens.refreshTokenExpiresAt = tokens.refreshTokenExpiresAt;
  currentTokens.decoded = JSON.parse(atob(tokens.accessToken.split('.')[1]));
  currentTokens.availableTeams = getTeams();

  await Storage.set({
    key: 'currentTokens',
    value: JSON.stringify(currentTokens)
  });
};

const resetTokens = async (reload) => {
  console.log('resetTokens');
  currentTokens.userId = '';
  currentTokens.accessToken = '';
  currentTokens.accessTokenExpiresAt = '';
  currentTokens.refreshToken = '';
  currentTokens.refreshTokenExpiresAt = '';
  currentTokens.decoded = {};
  currentTokens.availableTeams = getTeams();

  await Storage.remove({ key: 'currentTokens' });

  // because we need a way to kill urlql on logout
  if (reload) {
    window.location.reload(true);
  }
};

const callApi = async (params) => {
  const clientPublicSecret = 'not_so_secret';
  const clientAuth = Buffer.from(
    `${clientId}:${clientPublicSecret}`,
    'utf8'
  ).toString('base64');

  // console.log(params)

  // if (params.accessToken) {
  //   console.log('callApi decoding');
  //   const decoded = JSON.parse(atob(params.accessToken.split('.')[1]));
  //   console.log(decoded);
  //   const expiration = decoded.exp;
  //   const currTime = parseInt(Date.now() / 1000);
  //   const validFor = parseInt( (decoded.exp - currTime) / 60 );
  //   console.log('valid for ', validFor, 'minutes')
  // }

  if (params.accessToken) { // just used as a flag to know when to use it, but we always use it "fresh" from storage
    await refreshToken(getTokens()); // always check and try
    params.accessToken = getTokens().accessToken; // always!
  }

  const headers = {
    'Accept': 'application/json',
    authorization: params.accessToken ? `Bearer ${params.accessToken}` : `Basic ${clientAuth}`,
  };

  if (params.body && params.json) {
    headers['Content-Type'] = 'application/json';
    params.body = JSON.stringify(params.body);
  } else if (params.body) {
    headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
    params.body = qs.stringify(params.body);
  }

  let response;
  try {
    response = await fetch(`${identityServer}${params.endpoint}`, {
      method: params.method || 'POST',
      mode: 'cors',
      headers,
      ...params.body && { body: params.body },
    });
  } catch (error) {
    // network issues will be caught here
    console.log('fetch erroed!', error);
    throw new Error('Failed to fetch');
  }

  if (!response.ok) {
    console.log('fetch response NOT ok', response.status);
    let errorMessage;
    // try to digest custom message from the response
    try {
      const errorBody = await response.json();
      errorMessage = errorBody.message || response.statusText;
    } catch (e) {
      errorMessage = response.statusText;
    }

    console.log('not ok response', errorMessage);
    throw Error(errorMessage);
  }

  return response.json();
};

const fetchCurrentAppVersion = async (params) => {
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  };

  // use lastCheck as a flat to avoid trying to fetch again until done/fail
  appVersionDetails.lastCheck = new Date();

  const response = await fetch(`/version.json?${(new Date()).getTime()}`, {
    method: 'GET',
    mode: 'cors',
    headers,
  });

  if (!response.ok) {
    let errorMessage;
    // try to digest custom message from the response
    try {
      const errorBody = await response.json();
      errorMessage = errorBody.message || response.statusText;
    } catch (e) {
      errorMessage = response.statusText;
    }

    console.log('error loading UI version', errorMessage);
    appVersionDetails.lastCheck = 0;
    throw Error(errorMessage);
  }

  const available = await response.json();
  console.log('available UI server', available);

  appVersionDetails.available = available.version;
  appVersionDetails.lastCheck = new Date();

  return available;
};

const getAppVersionDetails = () => {
  return appVersionDetails;
};

const refreshAppVersionDetails = async (params, callback) => {
  let answer = null;
  let failure;
  try {
    const timeSinceLastCheck = new Date() - appVersionDetails.lastCheck;
    // check only every 5 min
    if (timeSinceLastCheck > (5 * 60 * 1000)) {
      answer = await fetchCurrentAppVersion();
    }

  } catch (error) {
    console.log('refreshAppVersionDetails', error);
    failure = error.message;
  }

  return callback(failure, !!answer);
};

const signIn = async (params, callback) => {

  // this is a social login, append some details with the token
  const username = params.email;
  const password = params.social ? JSON.stringify({
    social: params.social,
    tokenId: params.tokenId,
  }) : params.password;

  let answer;
  let failure;
  try {
    answer = await callApi({
      endpoint: '/oauth/token',
      body: {
        grant_type: 'password',
        username,
        password,
      }
    });

    await saveTokens({ ...answer, userId: answer.user.id });
  } catch (error) {
    console.log(error);
    failure = error.message;
    await resetTokens();
  }

  return callback(failure);
};

const codeSignIn = async (code, callback) => {
  let answer;
  let failure;
  try {
    answer = await callApi({
      endpoint: '/oauth/token',
      body: {
        grant_type: 'authorization_code',
        code,
      }
    });

    await saveTokens({ ...answer, userId: answer.user.id });
  } catch (error) {
    console.log(error);
    failure = error.message;
    await resetTokens();
  }

  return callback(failure);
};

const expiresIn = (expiresAt) => {
  const expiration = new Date(expiresAt).getTime();
  const currentTime = Date.now();
  return (expiration - currentTime) / 1000;
};

const isTokenRefreshTime = (expiresAt) => {
  return expiresIn(expiresAt) <= refreshTokenBefore;
};

const delay = async function delay(seconds, callback) {
  if (callback) {
    return (setTimeout(callback, seconds * 1000));
  }

  return new Promise((resolve) => setTimeout(resolve, seconds * 1000));
};

const refreshToken = async (tokens, force) => {
  // todo: should prevent parallel executions (other tabs?)
  console.log('refreshToken?', currentTokens.accessTokenExpiresAt);

  // todo: this is not preventing the UI from refreshing the token multiple times
  //  when parallel calls to refreshToken() are made, ex. multiple UI lists loading, failing and requiring refresh
  if (alreadyRefreshingToken) {
    console.log('already refreshing, waiting');
    await delay(0.2);
    return refreshToken(tokens);
  }

  try {
    if (isTokenRefreshTime(currentTokens.accessTokenExpiresAt) || force) {
      alreadyRefreshingToken = true;
      const answer = await callApi({
        endpoint: '/oauth/token',
        body: {
          grant_type: 'refresh_token',
          refresh_token: currentTokens.refreshToken,
        }
      });
      await saveTokens({ ...answer, userId: answer.user.id });
      alreadyRefreshingToken = false;
    }
  } catch (error) {
    alreadyRefreshingToken = false;
    console.log('refreshToken', error);

    // if the failure is not network related, reset the tokens
    if (error.message !== 'Failed to fetch') {
      console.log('refreshToken calling resetTokens');
      await resetTokens();
      console.log('refreshToken calling resetTokens done');
    }

    console.log('refreshToken throw new NO because is ignored :(');
    // throw new Error(error.message);
  }

  console.log('refreshToken returning');
  return tokens.accessToken !== currentTokens.accessToken;

};

const loadUser = async (id) => callApi({
  endpoint: `/users/${id}`,
  method: 'GET',
  accessToken: currentTokens.accessToken,
});

const loadMemberUser = async (params) => callApi({
  endpoint: `/teams/${params.teamId}/members/${params.memberId}/user`,
  method: 'GET',
  accessToken: currentTokens.accessToken,
});

const updateMemberUser = async (params) => callApi({
  endpoint: `/teams/${params.teamId}/members/${params.memberId}/user`,
  method: 'POST',
  accessToken: currentTokens.accessToken,
  json: true,
  body: params.user
});

const listMembers = async (params) => callApi({
  endpoint: `/teams/${params.teamId}/members/`,
  method: 'GET',
  accessToken: currentTokens.accessToken,
});

const createAuthorizationCode = async (params) => callApi({
  endpoint: `/users/managed/${params.teamId}/${params.userId}/createAuthorizationCode`,
  method: 'POST',
  accessToken: currentTokens.accessToken,
  body: params.AuthorizationCode
});

const createUserAndJoin = async (params) => callApi({
  endpoint: `/teams/${params.teamId}/members/createUserAndJoin`,
  method: 'POST',
  accessToken: currentTokens.accessToken,
  body: params
});

const invite = async (params) => callApi({
  endpoint: `/teams/${params.teamId}/invite`,
  method: 'POST',
  accessToken: currentTokens.accessToken,
  body: params
});

const recover = async (params) => callApi({
  endpoint: `/account/recover`,
  method: 'POST',
  body: params
});

const updateUser = async (user) => callApi({
  endpoint: `/users/${user.id}`,
  method: 'POST',
  accessToken: currentTokens.accessToken,
  body: user
});

const getCurrentTeamId = () => {
  if (!currentTokens.decoded || !currentTokens.decoded.t) {
    return null;
  }

  // default to 1st one if there is only one
  if (currentTokens.decoded.t.length === 1) {
    return currentTokens.decoded.t[0].i;
  }

  // return nothing if there is no team
  // or if there are more than one but not one selected (do not default so user can choose)
  const currentTeam = currentTokens.decoded.t.find(t => t.p);
  return currentTeam ? currentTeam.i : null
};

const switchTeam = async (teamId) => {
  await callApi({
    endpoint: '/account/oauth2/switchTeam',
    method: 'POST',
    accessToken: currentTokens.accessToken,
    body: {
      client_id: clientId,
      refresh_token: currentTokens.refreshToken,
      teamId: teamId,
    }
  })
  await refreshToken(getTokens(), true);
};

const getTeams = () => {
  if (!currentTokens.decoded || !currentTokens.decoded.t) {
    return [];
  }

  return currentTokens.decoded.t.map(t => ({
    id: t.i,
    name: t.n,
  }))
};

export default {
  loadUser,
  listMembers,
  signIn,
  recover,
  codeSignIn,
  refreshToken,
  getTokens,
  getCurrentTeamId,
  getTeams,
  switchTeam,
  loadTokens,
  resetTokens,
  getService,
  createAuthorizationCode,
  createUserAndJoin,
  invite,
  isTokenRefreshTime,
  getAppVersionDetails,
  getServiceDetails,
  refreshAppVersionDetails,
  updateUser,
  loadMemberUser,
  updateMemberUser,
  delay,
};
