import React, {useEffect, useRef, useState} from 'react';
import {Link, Redirect, useLocation, useParams} from 'react-router-dom';
import {t} from '@lingui/macro';
import {useMutation, useQuery} from 'urql'

import i18n from '../i18n';

import { LIST_REWARDS } from '@darescouts/api-graphql/rewards/rewardsQueries'
import { LIST_KIDS } from '@darescouts/api-graphql/kids/kidsQueries'
import PointType from '../components/PointType';
import Points from '../components/Points';
import Button from '../components/Button';
import {GIVE_REWARD} from '@darescouts/api-graphql/rewards/rewardsMutations';

const RewardGive = () => {
  const [rewards] = useQuery({
    query: LIST_REWARDS,
    requestPolicy: 'cache-and-network',
  });

  const [kids] = useQuery({
    query: LIST_KIDS,
    requestPolicy: 'cache-and-network',
  });

  const { rewardId, userOrTeamId } = useParams();
  const returnTo = new URLSearchParams(useLocation().search).get('returnTo');

  const firstInput = useRef(null);

  const [form, setValues] = useState({
    rewardId: rewardId !== 'reward' ? rewardId : null,
    receiverId: userOrTeamId !== 'Anyone' ? userOrTeamId : null,
    pointsType: 'S',
    points: 1,
  });

  const selectedReward = rewards.data && rewards.data.rewards ? rewards.data.rewards.find(r => r.id === form.rewardId) : null;

  useEffect(() => {
    setValues({
      ...form,
      rewardId: selectedReward ? selectedReward.id : null,
      pointsType: selectedReward ? selectedReward.pointsType : 'S',
      points: selectedReward ? selectedReward.points : 1,
    });
  },[selectedReward]);


  const updateField = e => {
    e.preventDefault();
    setValues({
      ...form,
      [e.target.name]: e.target.value
    });
  };

  const handleSelectReceiver = (receiverId) => {
    setValues({
      ...form,
      receiverId: receiverId,
    });
  };

  const handleSelectReward = (rewardId) => {
    setValues({
      ...form,
      rewardId: rewardId,
    });
  };

  const handleSelectPointsType = (pointsType) => {
    let points = 1
    if (pointsType === form.pointsType) {
      points = parseInt(form.points) < 5 ? parseInt(form.points) + 1 : 0;
    }

    setValues({
      ...form,
      pointsType: pointsType,
      points: points,
    });
  };

  const [giveRewardResult, giveReward] = useMutation(GIVE_REWARD);
  const { giving, data, error } = giveRewardResult;


  const handleGive = async (e) => {
    e.preventDefault();
    giveReward(form).then(result => {
      // The result is almost identical to `updateTodoResult` with the exception
      // of `result.fetching` not being set.
      console.log('give -> handleGive', result);
    });
  };

  if (!error && data) {
    return <Redirect to={returnTo ? `${returnTo}` : '/rewards'} />;
  }

  if (rewards.fetching) return <p>Loading rewards...</p>;
  if (rewards.error) return <p>Error loading list of rewards<button className="bg-green-600 m-1 p-1 hover:bg-blue-500 rounded text-sm text-white" onClick={() => window.location.reload(true)}>
    Reload
  </button></p>;
  if (!rewards.data || !rewards.data.rewards) return <p>No rewards data ?<button className="bg-green-600 m-1 p-1 hover:bg-blue-500 rounded text-sm text-white" onClick={() => window.location.reload(true)}>
    Reload
  </button></p>;

  if (kids.fetching) return <p>Loading rewards...</p>;
  if (kids.error) return <p>Error loading list of rewards<button className="bg-green-600 m-1 p-1 hover:bg-blue-500 rounded text-sm text-white" onClick={() => window.location.reload(true)}>
    Reload
  </button></p>;
  if (!kids.data || !kids.data.kids) return <p>No kids data ?<button className="bg-green-600 m-1 p-1 hover:bg-blue-500 rounded text-sm text-white" onClick={() => window.location.reload(true)}>
    Reload
  </button></p>;

  const selectedReceiver = kids.data.kids.find(k => k.id === form.receiverId);
  const availablePointsType = selectedReceiver ? selectedReceiver.totalPointsByType.find(pt => pt.pointsType === form.pointsType) : null;
  const availablePoints = availablePointsType ? availablePointsType.points : 0;
  const ready = form.receiverId && form.rewardId && availablePoints >= form.points;

  return (<>
    <div className="pb-8">
      <h2 className="text-2xl pb-2">
        {i18n._(t('rewardGive.new')`Give a reward`)}
      </h2>

      <p className="text-gray-700">
        Select who the reward, the receiver and optionally customize it's cost.
      </p>
    </div>
    <div className="col-span-2">
      <div className="text-gray-700 py-2">
        Give a reward to:

        <div className="flex flex-wrap">
          {kids.data.kids.map(({ id, name }) => (
            <div key={`kid-${id}`} className="py-2 m-1">
              <Button
                size="extra-small"
                variant={
                  (form.receiverId === id) ?
                    'primary' : 'light'
                }
                onClick={() => handleSelectReceiver(id)}
              >
                {name}
              </Button>
            </div>
          ))}
        </div>
      </div>
      {selectedReceiver && <div className="">
        Available points for {selectedReceiver.name}:

        <div className="flex flex-wrap">
          {selectedReceiver.totalPointsByType.map((points) => {
            return (
              <div key={`points-${points.pointsType}`} className="py-2 m-1 pr-2">
                {points.points} x <PointType type={points.pointsType} size={24} />
              </div>
            )
          })}
        </div>

      </div>}
      <div className="text-gray-700 py-2">
        Reward:
        <div className="flex flex-wrap">

          {rewards.data.rewards.map((reward) => {
            return (
              <div key={`reward-${reward.id}`} className="py-2 m-1">
                <Button
                  size="extra-small"
                  variant={
                    (form.rewardId === reward.id) ?
                      'primary' : 'light'
                  }
                  onClick={() => handleSelectReward(reward.id)}
                >
                  {reward.title}
                </Button>
              </div>
            )
          })}
          <br/>
        </div>
      </div>
      {selectedReward && !selectedReward.who.includes('Anyone') && !selectedReward.who.includes(form.receiverId) && <div className="">
        Be aware this reward does not include <strong>{selectedReceiver.name}</strong> as someone allowed to collect it. <br/><br/>
      </div>}


      <div className="text-gray-700 py-2">
        Customize the cost
      </div>
      <div className="flex flex-wrap">
        {[
          { id: 'S', name: 'Silver' },
          { id: 'G', name: 'Gold' },
          { id: 'D', name: 'Diamond' },
        ].map(({ id, name }) => (
          <div key={`schedule-mode-${id}`} className="py-2 m-1">
            <Button
              size="extra-small"
              variant={
                (form.pointsType === id) ?
                  'primary' : 'light'
              }
              onClick={() => handleSelectPointsType(id)}
            >
              <Points points={form.pointsType === id ? form.points : 0} type={id} />
            </Button>
          </div>
        ))}
      </div>

      {form.receiverId && form.points > availablePoints && <p className="text-xs italic text-red-500">Not enough points.</p>}

      <div className="grid grid-cols-2 gap-2 py-2">
        <div className="">
          <Button
            size="extra-small"
            variant="actions-cancel"
            disabled={giving}
            to={returnTo ? `${returnTo}` : '/rewards'}
          >
            {i18n._(t('RewardGive.cancel')`Cancel`)}
          </Button>
        </div>
        <div className="">
          <Button
            size="extra-small"
            variant="actions-accept"
            disabled={!ready || giving}
            onClick={handleGive}
          >
            {i18n._(t('RewardGive.update')`Give`)}
          </Button>
        </div>
      </div>

      {error && <p className="text-xs italic text-red-500">{error.message}</p>}

    </div>
  </>);
};

export default RewardGive;
