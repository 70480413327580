
// export const LIST_CURRENT_TASKS = `query ($date: String) {
module.exports.LIST_CURRENT_TASKS = `query currentTasks ($date: String) {
  currentTasks(date: $date) {
    id
    title
    scheduleMode
    effortMode
    pointsType
    points
    who
    log {
      id
      achievedAt
      doneById
    }
    when2
    when {
      Once { at }
      Daily { 
        at
      }
      Weekly {
        mon { at }
        tue { at }
        wed { at }
        thu { at }
        fri { at }
        sat { at }
        sun { at }
      }
    }
  }
}`;

module.exports.LIST_TASK = `query tasks {
  tasks {
    id
    title
    effortMode
    scheduleMode
    pointsType
    points
    who
    when2
    when {
      Once { at }
      Daily { at }
      Weekly {
        mon { at }
        tue { at }
        wed { at }
        thu { at }
        fri { at }
        sat { at }
        sun { at }
      }
    }
    startsAt
  }
}`;

module.exports.GET_TASK = `
  query 
    (
      $id: String!
      $kidId: String
      $date: String
    ) {
  task (
    id: $id
    kidId: $kidId
    date: $date
  ) {
    id
    title
    effortMode
    scheduleMode
    pointsType
    points
    who
    tags
    when {
      Once { at }
      Daily { at }
      Weekly {
        mon { at }
        tue { at }
        wed { at }
        thu { at }
        fri { at }
        sat { at }
        sun { at }
      }
    }
    startsAt
    log {
      id
      achievedAt
      pointsType
      points
      doneBy {
        id
        name
      }
    }
  }
}`;
