import React, { useState} from 'react';

// eslint-disable-next-line import/no-extraneous-dependencies
import { t } from '@lingui/macro';
import i18n from './i18n';
import TopNav from './components/TopNav';
import { identityServer, appName } from './config';

const AppLayoutPublic = ({ children, ...rest }) => {

  return (
    <div className="">
      <TopNav />

      <div className="container">
        <div className="md:grid md:grid-cols-3 md:gap-4 pt-4 pb-4">
          {children}
        </div>
      </div>

      <div className="w-full pt-2 mt-2 text-center border-t-2">
        <span className="text-sm">{appName}</span> -
        <a href={`${identityServer}/privacy`} className="link">Privacy</a> -
        <a href={`${identityServer}/tscs`} className="link">Terms and Conditions</a>
      </div>
    </div>
  );
};

export default AppLayoutPublic;
