import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import {identityServer, appUrl, clientId, appName} from '../config';

// todo: https://github.com/moberwasserlechner/capacitor-oauth2
import {OAuth2Client} from "@byteowls/capacitor-oauth2";

import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import { t } from '@lingui/macro';
import i18n from '../i18n';
import SwitchTeam from './SwitchTeam';

import authSlice, { getAuth } from '../store/slices/authSlice';

import identityAPI from '../identityAPI.js';

const SignIn = () => {
  const dispatch = useDispatch();
  const auth = useSelector(getAuth);

  const [email, setEmail] = useState(process.env.NODE_ENV === 'development' ? 'cristianszwarc@gmail.com' : '');
  const [password, setPassword] = useState(process.env.NODE_ENV === 'development' ? '123123' : '');
  const [error, setError] = useState('');
  const [fetching, setFetching] = useState(false);

  const handleSignIn = (e) => {
    e.preventDefault();
    setFetching(true);
    identityAPI.signIn({ email, password },(apiError) => {
        if (apiError) {
          setError(apiError);
          setFetching(false);
          return;
        }

        dispatch(authSlice.actions.signInSuccess());
      }
    );
  };

  // the process went ok, redirect to the next step
  if (auth.accessToken) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <div className="pb-8">
        <h2 className="text-2xl pb-2">Sign In</h2>
        <p className="text-gray-700">
          Sign in with your {appName} account.
        </p>
      </div>

      <div className="col-span-2">
        <form className="w-full">
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="username">
                E-mail
              </label>
              <input
                className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="username"
                type="email"
                placeholder="your@email.com"
                value={email}
                disabled={fetching}
                onChange={e => setEmail(e.target.value)}
              />
              <p className="text-gray-600 text-xs italic"></p>
            </div>
          </div>

          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="password">
                Password
              </label>
              <input
                className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="password"
                type="password"
                placeholder="**********"
                value={password}
                disabled={fetching}
                onChange={e => setPassword(e.target.value)}
              />
              <p className="text-gray-600 text-xs italic"></p>
            </div>
          </div>

          <div className="md:flex md:items-center">
            <div className="md:w-1/3">
              <button
                className="hover:bg-blue-500 shadow bg-blue-400 hover:bg-blue-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                type="button"
                disabled={fetching}
                onClick={handleSignIn}
              >
                Sign In
              </button>
              {error && <p className="text-red-500 text-xl italic float-right">{JSON.stringify(error)}</p>}
            </div>
          </div>

          <div className="text-center mt-4">

            <a className="link mr-1" href="#" onClick={() => {
              OAuth2Client.authenticate(
                {
                  // instead of using google/fb oauth, use own identity server
                  // at identity server, user will be able to log-in with password/google/fb
                  // authorizationBaseUrl: "https://accounts.google.com/o/oauth2/auth",
                  authorizationBaseUrl: `${identityServer}/account/oauth2/authorize`,

                  // when accessTokenEndpoint is set, this plugin will try to exchange code by token
                  // we don't use it. instead we get the code and use it with identityAPI.codeSignIn
                  // accessTokenEndpoint: "https://www.googleapis.com/oauth2/v4/token",

                  // we don't use this plugin resource loading option (resourceUrl)
                  // scope: "email profile",
                  // resourceUrl: "https://www.googleapis.com/userinfo/v2/me",
                  logsEnabled: true,

                  // additional params for the oAuth server
                  additionalParameters: {
                    mode: 'google', // if not already logged, identity server will start a google auth
                  },

                  web: {
                    appId: clientId,
                    responseType: "code", // we want a code which will be used by identityAPI.codeSignIn

                    // when using windowTarget "_self"
                    // this plugin loses control and can't catch the returning call
                    // in this case a route that can deal with the returning call is required
                    // when using _blank (default), this redirect url is not required to exist
                    // BUT when using _blank and code, the exchange needs to be called from "then"
                    redirectUrl: `${appUrl}/oauth2continue`,
                    windowOptions: "height=600,left=0,top=0",
                    // windowTarget: '_self',
                  },
                  android: {
                    appId: clientId,
                    responseType: "code", // if you configured a android app in google dev console the value must be "code"
                    redirectUrl: "com.darescouts.app:/" // package name from google dev console
                  },
                  // ios: {
                  //   appId: environment.oauthAppId.google.ios,
                  //   responseType: "code", // if you configured a ios app in google dev console the value must be "code"
                  //   redirectUrl: "com.companyname.appname:/" // Bundle ID from google dev console
                  // }
                }
              ).then(response => {
                if (response.authorization_response.error) {
                  throw new Error(response.authorization_response.error);
                }
                // this is here for when the oAuth screen is independent
                // and the return happens by code
                // for url returns types /oauth2continue will be triggered
                console.log("oAuth", response.authorization_response.code);
                setFetching(true);
                identityAPI.codeSignIn(response.authorization_response.code,(apiError) => {
                    if (apiError) {
                      throw new Error(apiError);
                    }
                    dispatch(authSlice.actions.signInSuccess());
                  }
                );
              }).catch(reason => {
                console.error("OAuth rejected", reason);
                setError('failed');
                setFetching(false);
              });
            }}>Sign In with Google</a> <br/><br/>
            <Link className="link" to="/recover">
              {i18n._(t('signIn.recover')`Recover Password`)}
            </Link>
          </div>
        </form>
      </div>
    </>
  );
};

export default SignIn;
