import {
  call,
  put,
  takeEvery,
} from 'redux-saga/effects';

import identityAPI from '../../identityAPI.js';

import userSlice from '../slices/userSlice';
import teamsSlice from '../slices/teamsSlice';

function* load(action) {
  try {
    const data = yield call(identityAPI.loadUser, action.payload);
    yield put(userSlice.actions.success(data));

    // pre populate other details
    console.log('watchUserLoad set teams');
    yield put(teamsSlice.actions.setTeams(data.Teams));

    console.log('watchUserLoad set current team');
    yield put(teamsSlice.actions.setCurrentTeamId(identityAPI.getCurrentTeamId()));

  } catch (error) {
    console.log('watchUserLoad failed to load user', error);
    yield put(userSlice.actions.error(error.message));
  }
}

export default function* watchUserLoad() {
  yield takeEvery(userSlice.actions.load, load);
}
