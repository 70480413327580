
module.exports.GET_GENERAL_TEAM_SETTINGS = `
  query 
    (
      $teamId: String!
    ) {
  getGeneralTeamSettings (
    teamId: $teamId
  ) {
    discountPointsType
    discountPoints
  }
}`;
