
module.exports.UPDATE_GENERAL_TEAM_SETTINGS = `
  mutation upsertGeneralTeamSettings(
      $teamId: String
      $discountPointsType: String!
      $discountPoints: Int!
    ) {
    upsertGeneralTeamSettings(
      teamId: $teamId
      discountPointsType: $discountPointsType
      discountPoints: $discountPoints
    )
  }
`;
