import React, { useState } from 'react';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { identityServer, appUrl, clientId } from '../config';

import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import { t } from '@lingui/macro';
import i18n from '../i18n';

import authSlice, { getAuth } from '../store/slices/authSlice';

import identityAPI from '../identityAPI.js';

const SignIn = () => {
  const code = new URLSearchParams(useLocation().search).get('code');
  const errorFromUrl = new URLSearchParams(useLocation().search).get('error');

  const dispatch = useDispatch();
  const auth = useSelector(getAuth);

  const [error, setError] = useState(errorFromUrl ? errorFromUrl : '');
  const [fetching, setFetching] = useState(false);

  const handleCodeSignIn = (codeToUse) => {
    setFetching(true);
    identityAPI.codeSignIn(codeToUse,(apiError) => {
        if (apiError) {
          setError(apiError);
          setFetching(false);
          return;
        }

        dispatch(authSlice.actions.signInSuccess());
      }
    );
  };

  // the process went ok, redirect to the next step
  if (auth.accessToken) {
    return <Redirect to="/" />;
  }

  if (fetching) {
    return (<>Signing you in...</>);
  }

  if (error) {
    return (<>error signing you in...</>);
  }

  if (code) {
    handleCodeSignIn(code);
    return (<>Signing you in...</>);
  }



  return <Redirect to="/signIn" />;

};

export default SignIn;
