import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { identityServer, appUrl, clientId } from '../config';

import { useDispatch, useSelector } from 'react-redux';
import authSlice, { getAuth } from '../store/slices/authSlice';

const AutoSignIn = () => {
  const auth = useSelector(getAuth);

  const [error, setError] = useState('');
  const [fetching, setFetching] = useState(false);

  // no need
  if (auth.accessToken) {
    return <Redirect to="/" />;
  }

  window.location = `${identityServer}/account/oauth2/authorize?response_type=code&redirect_uri=${encodeURIComponent(`${appUrl}/oauth2continue`)}&client_id=${clientId}&state=`;
  return <></>
};

export default AutoSignIn;
