import React, { useEffect, useState } from 'react';
import {Link, Redirect, useParams} from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'urql';

import { LIST_KIDS } from '@darescouts/api-graphql/kids/kidsQueries'
import { LIST_CURRENT_TASKS } from '@darescouts/api-graphql/tasks/tasksQueries'
import NewAccountRedirector from '../components/NewAccountRedirector';
import TasksIcon from '@iconscout/react-unicons/icons/uil-clipboard-notes';
import HouseIcon from '@iconscout/react-unicons/icons/uil-house-user';
import i18n from '../i18n';
import {t} from '@lingui/macro';
import Points from '../components/Points';
import teamIcon from '../images/team.svg';
import { isIsoDate, getTimeFromDate } from '../utils';

import {utcToZonedTime} from "date-fns-tz";
import {getUser} from '../store/slices/userSlice';

const Dashboard = () => {
  const daysOfWeek = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
  const { date } = useParams();

  const user = useSelector(getUser);

  // todo: this date should be ensured to be local time of team
  const currentDateTime = new Date();
  const currentDateFormatted = [
    currentDateTime.getFullYear(),
    ('0' + (currentDateTime.getMonth() + 1)).slice(-2),
    ('0' + currentDateTime.getDate()).slice(-2)
  ].join('-');

  // used to calculate previous and next date
  const selectedDateSplitted = date ? date.split('-') : currentDateFormatted.split('-');

  // todo: this date should be ensured to be local time of team
  const selectedDateTime = new Date(selectedDateSplitted[0], selectedDateSplitted[1] - 1, selectedDateSplitted[2]);
  const selectedDateFormatted = [
    selectedDateTime.getFullYear(),
    ('0' + (selectedDateTime.getMonth() + 1)).slice(-2),
    ('0' + selectedDateTime.getDate()).slice(-2)
  ].join('-');

  const dateFormatOptions = new Intl.DateTimeFormat('en-au', {
    dateStyle: 'short',
  });

  let displaySelectedDate = dateFormatOptions.format(selectedDateTime);
  if (selectedDateFormatted === currentDateFormatted) {
    displaySelectedDate = 'Today';
  }

  const selectedNextDateTime = new Date(selectedDateSplitted[0], selectedDateSplitted[1] - 1, selectedDateSplitted[2]);
  selectedNextDateTime.setDate(selectedNextDateTime.getDate() + 1);
  const selectedNextDateFormatted = [
    selectedNextDateTime.getFullYear(),
    ('0' + (selectedNextDateTime.getMonth() + 1)).slice(-2),
    ('0' + selectedNextDateTime.getDate()).slice(-2)
  ].join('-');

  const selectedPreviousDateTime = new Date(selectedDateSplitted[0], selectedDateSplitted[1] - 1, selectedDateSplitted[2]);
  selectedPreviousDateTime.setDate(selectedPreviousDateTime.getDate() - 1);
  const selectedPreviousDateFormatted = [
    selectedPreviousDateTime.getFullYear(),
    ('0' + (selectedPreviousDateTime.getMonth() + 1)).slice(-2),
    ('0' + selectedPreviousDateTime.getDate()).slice(-2)
  ].join('-');


  const weekDay = daysOfWeek[selectedDateTime.getDay()];

  // we use real current minutes only if we are checking current date
  // if selected date is other, we set the minutes to late at night for past dates and first time for future dates
  let currentMinutes;
  if (selectedDateFormatted === currentDateFormatted) { // current date becasue yyyy-mm-dd matches
    currentMinutes = (currentDateTime.getHours() * 60) + currentDateTime.getMinutes();
  } else if (selectedDateTime > currentDateTime) { // future date
    currentMinutes = 0;
  } else if (selectedDateTime < currentDateTime) { // past date
    currentMinutes = 1440;
  }

  const [kids] = useQuery({
    query: LIST_KIDS,
    requestPolicy: 'cache-and-network',
  });

  const [tasks, reloadTasks] = useQuery({
    query: LIST_CURRENT_TASKS,
    requestPolicy: 'cache-and-network',
    variables: { date: selectedDateFormatted }
  });

  useEffect(() => {
    // if not executing query then is finished
    if (!tasks.fetching && !tasks.stale) {
      // set a timer for the next reload
      const id = setTimeout(() => {
        // execute the reload after the specified time
        reloadTasks({
          requestPolicy: 'cache-and-network',
        });
      }, 60000);
      return () => clearTimeout(id); // return an action to clean things for when the page is unloaded
    }
  }, [tasks.fetching, tasks.stale, reloadTasks]);

  if (kids.fetching) return <p>Loading kids...</p>;
  if (kids.error) return <p>
    Error loading list of kids <button className="bg-green-600 m-1 p-1 hover:bg-blue-500 rounded text-sm text-white" onClick={() => window.location.reload(true)}>
    Reload
  </button>
  </p>;

  console.log('dash kids', kids)
  console.log('dash tasks', tasks.data)

  if (tasks.fetching) return <p>Loading tasks...</p>;
  if (tasks.error) return <p>Error loading list of tasks <button className="bg-green-600 m-1 p-1 hover:bg-blue-500 rounded text-sm text-white" onClick={() => window.location.reload(true)}>
    Reload
  </button></p>;

  if (
    !tasks.data.currentTasks ||
    tasks.data.currentTasks.length < 1 ||
    !kids.data.kids ||
    kids.data.kids.length < 1
  ) {
    console.log('dash is new account')
    return <NewAccountRedirector />
  }

  const parseWhen = (when2) => {
    // 11:00;mon 10:00;tue 11:30-12:00;sun 15:00
    const parts = when2.split(';')
      .filter(i => i)
      .map(i => {
        // is full date, return "as is"
        if (isIsoDate(i)) {
          return i;
        }

        // mon 10:00-10:30
        const innerParts = i.trim().split(' ');

        // if there is time but no day, add a placeholder
        // ex 10:00
        if (innerParts.length === 1) {
          // move the time to 2nd place and add a placeholder at 0
          innerParts[1] = innerParts[0];
          innerParts[0] = '%%%'; // placeholder
        }

        // ensure is a time or range
        if (
          !innerParts[1].includes(':')
        ) {
          innerParts[0] = '12:34';
          console.error('wrong time for', i);
        }

        // slice time range if any
        const timeRange = innerParts[1].split('-');
        innerParts[1] = timeRange[0]; // start
        innerParts[2] = timeRange[1] || timeRange[0]; // end or start again

        return innerParts; // ['mon', '10:00', '10:30']
      });

    // extract fix dates 1st
    const parsed = {
      dates: parts.filter(i => !Array.isArray(i)),
      weekly: {
        mon: {},
        tue: {},
        wed: {},
        thu: {},
        fri: {},
        sat: {},
        sun: {},
      },
    };

    // ['mon-fri', '10:00', '10:30']
    const partsToParse = parts.filter(i => Array.isArray(i))

    partsToParse.forEach(part => {
      // same time for any day
      if (part[0] === '%%%') {
        parsed.weekly.mon = { from: part[1], to: part[2] };
        parsed.weekly.tue = { from: part[1], to: part[2] };
        parsed.weekly.wed = { from: part[1], to: part[2] };
        parsed.weekly.thu = { from: part[1], to: part[2] };
        parsed.weekly.fri = { from: part[1], to: part[2] };
        parsed.weekly.sat = { from: part[1], to: part[2] };
        parsed.weekly.sun = { from: part[1], to: part[2] };
      }

      // shortcut weekdays
      if (part[0] === 'mon-fri') {
        parsed.weekly.mon = { from: part[1], to: part[2] };
        parsed.weekly.tue = { from: part[1], to: part[2] };
        parsed.weekly.wed = { from: part[1], to: part[2] };
        parsed.weekly.thu = { from: part[1], to: part[2] };
        parsed.weekly.fri = { from: part[1], to: part[2] };
      }

      // shortcut weekends
      if (part[0] === 'sat-sun') {
        parsed.weekly.sat = { from: part[1], to: part[2] }
        parsed.weekly.sun = { from: part[1], to: part[2] }
      }

      // exact day
      if (['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'].includes(part[0])) {
        parsed.weekly[part[0]] = { from: part[1], to: part[2] }
      }

      // todo: add support for monthly things
      // parsed.monthly.counting.1 // 1st
      // parsed.monthly.discounting.1 // last day
      // parsed.monthly.first.fri // first friday
      // parsed.monthly.second.fri // first friday
      // parsed.monthly.last.fri // last friday
    });

    return parsed;
  }

  const parsedTasks = tasks.data.currentTasks.map((task) => {
    const definedWhen = parseWhen (task.when2);

    // either HH:mm converted from the 1st fixed date found in parsed.dates (for Once)
    // or raw HH:mm from parsed.weekly for Weekly or Daily
    task.at = task.scheduleMode === 'Once' ? getTimeFromDate(utcToZonedTime(definedWhen.dates[0], user.timezone)) : definedWhen.weekly[weekDay].from;

    // at midnight definedWhen[weekDay].at can be null
    // because the current date is changed but "currentTasks" can still be cached from previous day
    // ignore tasks without a defined `at`
    if (!task.at) {
      return;
    }

    task.atMinute = (parseInt (task.at.split(':')[0]) * 60) + parseInt (task.at.split(':')[1]);

    const availableSince = task.atMinute - 30; // - 30 min margin
    task.availableUntil = task.atMinute + 30; // + 30 min margin
    task.isAvailable = (currentMinutes >= availableSince) && (currentMinutes <= task.availableUntil);
    task.isPast = (currentMinutes > task.availableUntil);

    task.statusByKids = kids.data.kids.filter(kid => task.who.includes(kid.id) || task.who.includes('Anyone')).map((kid) => {
      let status;
      // this tasks has been already done by this kid?
      if  (task.log.find(log => log.doneById === kid.id)) {
        status = 'success';
      } else if  (task.isPast) {
        // this tasks is not currently available
        status = 'past';
      } else if (!task.isAvailable) {
        // this tasks is not currently available
        status = 'disabled';
      } else if  (task.effortMode === 'Race' && task.log.length) {
        // this tasks is mode "Race" and it has been already done
        status = 'missed';
      } else {
        status = 'available';
        task.isCalling = true;
      }

      return {
        status,
        kid,
      }
    });

    return task;

    // filter ignored tasks and order by time
  }).filter(task => task).sort((a, b) => (a.atMinute > b.atMinute) ? 1 : -1);

  return (<>
    {/*<div className=""></div>*/}
    <div className="col-span-3">
      <div className="grid grid-cols-3 mb-4">
        <div className="text-center mt-2">
          <Link className="block text-center rounded-lg center text-lg bg-gray-300 hover:bg-gray-400" to={`/tasks/byDate/${selectedPreviousDateFormatted}`}>
            {'<'}
          </Link>
        </div>
        <div>
          <h1 className="text-xl mb-2 mt-2 text-center">
            {displaySelectedDate}
          </h1>
        </div>
        <div className="text-center mt-2">
          <Link className="block text-center rounded-lg center text-lg bg-gray-300 hover:bg-gray-400" to={`/tasks/byDate/${selectedNextDateFormatted}`}>
            {'>'}
          </Link>
        </div>

      </div>

      {parsedTasks.length === 0 && <div>
        <h1 className="text-center text-xl">
          No tasks for this day.
        </h1>
        <div className="text-gray-700 py-2 mb-3">
          <TasksIcon color="#2D3748" className="inline-block mb-1 mr-2" size={30} />
          Use the <strong>{i18n._(t('links.tasks.list')`Tasks`)}</strong> icon to <strong>schedule</strong> some tasks.
        </div>
      </div>}

      {parsedTasks.map((task) => {
        return (
          <div key={task.id} className="pb-3 mb-6 border-b-2 border-gray-300">
            <h1 className="text-gray-700">
              {task.at}
            </h1>
            <div className="float-right">
              {
                task.effortMode === 'Team' &&
                  <img
                    src={teamIcon}
                    className="inline-block mr-1"
                    alt="Team"
                    width="24"
                  />
              }
              {task.statusByKids.map((statusByKid) => {
                const kid = statusByKid.kid;
                const thisTaskStatus = statusByKid.status;
                const stylesByStatus = {
                  disabled: 'bg-gray-300 text-gray-700',  // this tasks is not available
                  past: 'bg-orange-200 text-orange-700',  // has been missed
                  success: 'bg-green-400 text-white', // this tasks has been already done by this kid?
                  missed: 'bg-gray-200 text-gray-700',    // this tasks has is mode "Race" and it has been already done
                  available: 'bg-blue-200 text-blue-700',
                };

                return (
                  <div key={`task-${task.id}-${kid.id}`} className={`mr-1 text-xs inline-flex items-center px-3 py-1 rounded-full ${stylesByStatus[thisTaskStatus]}`}>
                    <Link className="" to={`/tasks/${task.id}/${selectedDateFormatted}`}>
                      {kid.name}
                    </Link>
                  </div>
                )
              })}
            </div>
            <Link className="text-lg text-gray-800" to={`/tasks/${task.id}/${selectedDateFormatted}`}>
              {task.title} <Points points={task.points} type={task.pointsType} />
            </Link>
          </div>
        )
      })}
      {!!parsedTasks.length && <div className="text-sm text-gray-700">
        <strong>Tip:</strong> Select the task name to manage who finished it.
      </div>}

    </div>
  </>);
};

export default Dashboard;
