import version from './version.json';

// this app id
export const clientId = '206dc468-92c7-4a3a-9022-496c8caf2288';

// this UI version
export const appName = 'Dare Scouts';
export const appVersion = version.version;
export const appUrl = process.env.APP_URL || 'http://parents.darescouts.test:8080';
export const appHost = process.env.APP_HOST || 'parents.darescouts.test';

// what api service is the one we expect to use by default
// and what versions this UI expects
export const expectedService = {
  id: 'api',
  acceptVersion: '0.1.1', // what version of the API we know to use, used in calls to allow the api server to reject us if this UI is incompatible
};

export const kidsAppClientId = '32a14232-306d-4561-a06c-4be0294f71b9';   // used to generate auth codes

// how many seconds before expiration
export const refreshTokenBefore = 20 * 60;

export const identityServer = process.env.IDENTITY_SERVER || 'http://identity.darescouts.test:3000';
