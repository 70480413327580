import {
  call,
  put,
  takeEvery,
  select,
} from 'redux-saga/effects';

import identityAPI from '../../identityAPI.js';

import membersSlice from '../slices/membersSlice';

function* load(action) {
  try {
    const data = yield call(identityAPI.listMembers, { teamId: action.payload });
    yield put(membersSlice.actions.success(data));
  } catch (error) {
    yield put(membersSlice.actions.error(error.message));
  }
}

export default function* watchMembersLoad() {
  yield takeEvery(membersSlice.actions.load, load);
}
