import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import {t} from '@lingui/macro';
import { useMutation, useQuery} from 'urql'

import i18n from '../i18n';

import {GET_NETWORK, LIST_NETWORK_DEVICES} from '@darescouts/api-graphql/networks/networksQueries'
import { OVERRIDE_NETWORK_DEVICE, RESET_OVERRIDE_NETWORK_DEVICE } from '@darescouts/api-graphql/networks/networksMutations'
import { LIST_KIDS } from '@darescouts/api-graphql/kids/kidsQueries'
import {useSelector} from 'react-redux';
import {getCurrentTeam} from '../store/slices/teamsSlice';
import Button from '../components/Button';

const NetworkDevices = () => {

  const [network] = useQuery({
    query: GET_NETWORK,
    variables: { id: 'main' },
    requestPolicy: 'cache-and-network',
  });

  const [networkDevices, refetchNetworkDevices] = useQuery({
    query: LIST_NETWORK_DEVICES,
    requestPolicy: 'cache-and-network',
    pause: !(network && network.data && network.data.network),
    variables: { netId: 'main' },
  });

  const [kids] = useQuery({
    query: LIST_KIDS,
    variables: { andTeamUser: true, },
    requestPolicy: 'cache-and-network',
  });

  const [resetOverrideNetworkDeviceResult, resetOverrideNetworkDevice] = useMutation(RESET_OVERRIDE_NETWORK_DEVICE);
  const [overrideNetworkDeviceResult, overrideNetworkDevice] = useMutation(OVERRIDE_NETWORK_DEVICE);
  const [done, setDone] = useState(false);
  const [error, setError] = useState('');
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const currentMinutes = (currentDateTime.getHours() * 60) + currentDateTime.getMinutes();

  const handleOverride = async (enable, id, minutes) => {
    try {
      const result = await overrideNetworkDevice({
        id: id,
        ...enable && { enableFor: minutes },
        ...!enable && { disableFor: minutes },
      });

      if (result.error) {
        throw new Error(result.error)
      }

      setDone(true);
      refetchNetworkDevices({ requestPolicy: 'network-only' });
    } catch (e) {
      console.log(e);
      setError(e.message || e);
    }
  };

  const handleResetOverride = async (id) => {
    try {
      const result = await resetOverrideNetworkDevice({
        id: id,
      });

      if (result.error) {
        throw new Error(result.error)
      }

      setDone(true);
      refetchNetworkDevices({ requestPolicy: 'network-only' });
    } catch (e) {
      console.log(e);
      setError(e.message || e);
    }
  };

  const remainingMinutes = (until) => {
    const untilDate = new Date(until);
    const untilMinutes = (untilDate.getHours() * 60) + untilDate.getMinutes();

    return untilMinutes - currentMinutes;
  }

  const currentTeam = useSelector(getCurrentTeam);

  if (network.fetching) return <p>Loading network details...</p>;
  if (network.error) return <p>Error loading network settings: {network.error.message}<button className="bg-green-600 m-1 p-1 hover:bg-blue-500 rounded text-sm text-white" onClick={() => window.location.reload(true)}>
    Reload
  </button></p>;

  if (!network.data.network) {
    return <div>
      <h2 className="text-2xl pb-2">Network</h2>
      <p className="text-gray-700">
        To manage internet access for your home devices, you first need to configure a network.
      </p>
      <Link className="link" to="/net/settings/new">
        Click here to configure a network.
      </Link>
    </div>
  }

  if (networkDevices.fetching) return <p>Loading devices...</p>;
  if (networkDevices.error) return <p>Error loading list of devices<button className="bg-green-600 m-1 p-1 hover:bg-blue-500 rounded text-sm text-white" onClick={() => window.location.reload(true)}>
    Reload
  </button></p>;
  if (!networkDevices.data || !networkDevices.data.networkDevices) return <p>No devices data ?<button className="bg-green-600 m-1 p-1 hover:bg-blue-500 rounded text-sm text-white" onClick={() => window.location.reload(true)}>
    Reload
  </button></p>;

  if (kids.fetching) return <p>Loading kids...</p>;
  if (kids.error) return <p>Error loading list of kids<button className="bg-green-600 m-1 p-1 hover:bg-blue-500 rounded text-sm text-white" onClick={() => window.location.reload(true)}>
    Reload
  </button></p>;
  if (!kids.data || !kids.data.kids) return <p>No kids data ?<button className="bg-green-600 m-1 p-1 hover:bg-blue-500 rounded text-sm text-white" onClick={() => window.location.reload(true)}>
    Reload
  </button></p>;

  return (<>
    <div className="pb-8">
      <h2 className="text-2xl pb-2">Network Devices</h2>
      <p className="text-gray-700">
        This is a list of all devices configured in your network. <br/>
        <br/>
        <Link className="link" to="/net/devices">
          {i18n._(t('net.devices')`Devices`)}
        </Link>
        <br/>
        <Link className="link" to="/net/devices/new">
          {i18n._(t('net.newDevice')`New Device`)}
        </Link>

      </p>
    </div>
    <div className="col-span-2">
      {networkDevices.data.networkDevices.length === 0 && <div>
        No devices
      </div>}

      {networkDevices.data.networkDevices.map((networkDevice) => {

        return (
          <div key={networkDevice.id} className="pb-3 mb-6 border-b-2 border-gray-300">
            <div className="col-span-3">
              <h1 className="text-gray-700">
                {networkDevice.state.enabled ? 'Enabled' : 'Disabled'}
                <small>{networkDevice.state.overriddenFor ? ` for ${networkDevice.state.overriddenFor} minutes` : ''}</small>
              </h1>
              <div className="float-right">
                {[networkDevice.userId].map((id) => kids.data.kids.find(k => k.id === id) || { id: id, name: 'Unknown'}).map((kid) => {
                  return (
                    <div key={`reward-${networkDevice.id}-${kid.id}`} className="mr-1 text-xs inline-flex items-center px-3 py-1 bg-blue-200 text-blue-700 rounded-full">
                      <Link className="" to="#">
                        {kid.name}
                      </Link>
                    </div>
                  )
                })}
              </div>
              <Link className="text-lg text-gray-800" to={`/net/devices/${networkDevice.id}`}>
                {networkDevice.name}
              </Link>
              <br/>

              <div className="flex flex-wrap">
                <div key={`dis-15-${networkDevice.id}`} className="py-2 m-1">
                  <Button
                    size="extra-small"
                    variant="danger"
                    onClick={() => handleOverride(false, networkDevice.id, 15)}
                  >
                    Disable +15
                  </Button>
                </div>
                <div key={`ena-15-${networkDevice.id}`} className="py-2 m-1">
                  <Button
                    size="extra-small"
                    variant="success"
                    onClick={() => handleOverride(true, networkDevice.id, 15)}
                  >
                    Enable +15
                  </Button>
                </div>
                <div key={`auto-${networkDevice.id}`} className="py-2 m-1">
                  <Button
                    size="extra-small"
                    variant="primary"
                    onClick={() => handleResetOverride(networkDevice.id)}
                  >
                    Auto
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )
      })}

    </div>
  </>);
};

export default NetworkDevices;
