import { setupI18n } from '@lingui/core';

import catalogEs from './locales/es/messages';
import catalogEn from './locales/en/messages';

const catalogs = {
  es: catalogEs,
  en: catalogEn,
};

const i18n = setupI18n({ language: 'en', catalogs });

export default i18n;
