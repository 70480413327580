import dateFnsFormat from 'date-fns/format';
import dateFnsParse from 'date-fns/parse';
import isValid from 'date-fns/isValid'

export const randomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const generateRandomString = (length, allowedChars) => {
  let string = '';

  if (!allowedChars) {
    allowedChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  }

  for (let i = 0; i < length; i++) { string += allowedChars.charAt(randomNumber(0, allowedChars.length - 1)); }

  return string;
};


export const parseDate = (str, format, locale) => {
  const parsed = dateFnsParse(str, format, new Date(), { locale });
  if (isValid(parsed)) {
    return parsed;
  }
  return undefined;
};

export const formatDate = (date, format, locale) => {
  return dateFnsFormat(date, format, { locale });
}

export const getTimeFromDate = (date) => {
  // return date.toTimeString().slice(0,5);
  return formatDate(date, 'HH:mm')
}

export const getDateFromDate = (date) => {
  // return date.toTimeString().slice(0,10);
  return formatDate(date, 'yyyy-MM-dd')

}

export const addMinutesToDate = (date, minutes) => {
  return new Date(date.getTime() + minutes * 60000);
}

export const isIsoDate = (str) => {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
  var d = new Date(str);
  return d.toISOString()===str;
}

export const addTimeToDate = (date, time) => {
  const timeParts = time.split(':');
  const minutes = (parseInt(timeParts[0]) * 60) + timeParts[1];
  return addMinutesToDate(date, minutes);
}
