import React from 'react';
import i18n from '../i18n';
import {t} from '@lingui/macro';
import Button from './Button';
import TopNav from './TopNav';
import {appName} from '../config';

const FaltalError = ({title, error, handleSignOut}) => {

  if (error && JSON.stringify(error).includes('invalid refresh token')) {
    window.location.reload(true);
  }

  return (
    <div className="">
      <TopNav />

      <div className="container">
        <div className="md:grid md:grid-cols-3 md:gap-4 pt-4 pb-4">
          <div className="pb-8">
            <h2 className="text-2xl pb-2">{title}</h2>
            <p className="text-gray-700">
              Try again by signing-out and in again.
            </p>
          </div>

          <div className="col-span-2">
            {error && <span> Reason: {error}</span>}
            <div className="md:flex md:items-center">
              <div className="md:w-1/3">
                <Button
                  size="extra-small"
                  variant="primary"
                  onClick={handleSignOut}
                >
                  {i18n._(t('links.signOut')`Sign Out`)}
                </Button>
              </div>
            </div>
          </div>        </div>
      </div>
    </div>
  )
}

export default FaltalError;
