import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

// interface Props {
//   variant?:
//     | 'success'
//     | 'danger'
//     | 'light'
//     | 'disabled'
//     | 'outlined-white'
//     | 'danger-white'
//     | 'primary'
//     | 'warning'
//     | 'fatal'
//     | 'add'
//     | 'skip'
//     | 'select'
//   to?: string
//   size?: 'small' | 'medium' | 'large' | 'extra-small'
//   disabled?: boolean
//   onClick?: () => void
// }

const Button = ({
  children,
  variant,
  to,
  onClick,
  size = 'medium',
  disabled = false,
}) => {
  let buttonClasses = [
    'block',
    'text-center',
    'font-bold',
    'rounded-lg',
    'center',
    'focus:outline-none',
    'w-full',
  ];

  switch (size) {
    case 'extra-extra-small':
      buttonClasses = buttonClasses.concat(['py-1', 'px-1', 'text-xs']);
      break;
    case 'extra-small':
      buttonClasses = buttonClasses.concat(['py-2', 'px-2', 'text-xs']);
      break;
    case 'small':
      buttonClasses = buttonClasses.concat(['py-2', 'px-4', 'text-lg']);
      break;
    case 'medium':
      buttonClasses = buttonClasses.concat(['py-2', 'px-4', 'text-2xl']);
      break;
    case 'large':
      buttonClasses = buttonClasses.concat(['py-2', 'px-4', 'text-4xl']);
      break;
  }

  if (disabled) {
    variant = 'disabled';
  }

  switch (variant) {
    case 'success':
      buttonClasses = buttonClasses.concat([
        'bg-green-500',
        'hover:bg-green-600',
        'active:bg-green-700',
        'focus:shadow-outline',
        'border-green-500',
        'text-white',
      ])
      break
    case 'danger':
      buttonClasses = buttonClasses.concat([
        'bg-red-500',
        'hover:bg-red-600',
        'active:bg-red-700',
        'focus:shadow-outline',
        'border-red-500',
        'text-white',
      ])
      break
    case 'fatal':
      buttonClasses = buttonClasses.concat([
        'bg-red-700',
        'hover:bg-red-800',
        'border-red-800',
        'text-white',
      ])
      break
    case 'light':
      buttonClasses = buttonClasses.concat([
        'bg-gray-300',
        'hover:bg-gray-500',
        'border-gray-500',
        'text-gray-800',
      ])
      break
    case 'disabled':
      buttonClasses = buttonClasses.concat([
        'bg-gray-300',
        'hover:bg-gray-300',
        'border-gray-300',
        'text-gray-500',
      ])
      break
    case 'outlined-white':
      buttonClasses = buttonClasses.concat([
        'bg-transparent',
        'text-white',
        'border',
        'border-white',
      ])
      break
    case 'danger-white':
      buttonClasses = buttonClasses.concat([
        'bg-white',
        'text-red-700',
        'border',
        'border-white',
      ])
      break
    case 'warning':
      buttonClasses = buttonClasses.concat([
        'bg-orange-500',
        'hover:bg-orange-600',
        'active:bg-orange-700',
        'border-orange-500',
        'text-white',
      ])
      break
    case 'primary':
      buttonClasses = buttonClasses.concat([
        'bg-blue-500',
        'hover:bg-blue-600',
        'active:bg-blue-700',
        'border-blue-500',
        'text-white',
      ])
      break

    case 'actions-start':
    case 'actions-accept':
    case 'actions-finalise':
      buttonClasses = buttonClasses.concat([
        'bg-green-500',
        'hover:bg-green-600',
        'active:bg-green-700',
        'focus:shadow-outline',
        'border-green-500',
        'text-white',
      ])
      break
    case 'actions-reject':
    case 'actions-cancel':
      buttonClasses = buttonClasses.concat([
        'bg-red-500',
        'hover:bg-red-600',
        'active:bg-red-700',
        'focus:shadow-outline',
        'border-red-500',
        'text-white',
      ])
      break

  }
  return to ? (
    <Link to={to} className={classNames(buttonClasses)}>
      {children}
    </Link>
  ) : (
    <button
      disabled={disabled}
      onClick={onClick}
      className={classNames(buttonClasses)}
    >
      {children}
    </button>
  )
}

export default Button;
