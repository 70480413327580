import { hot } from 'react-hot-loader/root';
import React from 'react';
// import { I18nProvider } from '@lingui/react';
// import i18n from './i18n';

import { Provider } from 'react-redux';
import 'unfetch/polyfill';

import './css/tailwind.css';
import 'react-day-picker/lib/style.css';

import store from './store';

import AppRouter from './AppRouter';

const App = () => {
  return (
    <Provider store={store}>
      <AppRouter />
    </Provider>
  );
};

export default hot(App);
