import React, { useState, useEffect, useRef } from 'react';
import {Link, Redirect, useLocation, useParams} from 'react-router-dom';

import { t } from '@lingui/macro';

import i18n from '../i18n';

import Button from '../components/Button';

import { useMutation, useQuery } from 'urql';
import { UPSERT_NETWORK_RULE } from '@darescouts/api-graphql/networks/networksMutations';
import { GET_NETWORK_RULE, LIST_NETWORK_DEVICES } from '@darescouts/api-graphql/networks/networksQueries';

import {useSelector} from 'react-redux';
import {getCurrentTeam} from '../store/slices/teamsSlice';

const NetworkRuleEdit = () => {
  const currentTeam = useSelector(getCurrentTeam);
  const { ruleId } = useParams();

  const firstInput = useRef(null);
  const returnTo = new URLSearchParams(useLocation().search).get('returnTo');

  const [networkDevices, refetchNetworkDevices] = useQuery({
    query: LIST_NETWORK_DEVICES,
    requestPolicy: 'cache-and-network',
    variables: { netId: 'main' },
  });

  const [networkRule] = useQuery({
    query: GET_NETWORK_RULE,
    variables: { id: ruleId },
    pause: !ruleId,
    requestPolicy: 'cache-and-network',
  });

  const [upsertnetworkRuleResult, upsertnetworkRule] = useMutation(UPSERT_NETWORK_RULE);
  const { upserting, upsertData, upsertingError } = upsertnetworkRuleResult;
  const [ready, setReady] = useState(true); // todo: add validation
  const [done, setDone] = useState(false);
  const [error, setError] = useState('');

  const [form, setValues] = useState({
    teamId: currentTeam.id,
    netId: 'main',
    name: 'New Rule',
    devices: [],
    schedule: {},
    condition: {
      completedTags: [],
    }
  });

  useEffect(() => {
    if (firstInput.current) {
      firstInput.current.focus();
    }
  }, []);

  useEffect(() => {
    if (!networkRule || networkRule.fetching || networkRule.error || !networkRule.data || !networkRule.data.networkRule) return;

    // convert loaded schedule into 5 minute map
    const schedule = {
      mon: [],
      tue: [],
      wed: [],
      thu: [],
      fri: [],
      sat: [],
      sun: [],
    }

    Object.keys(schedule).forEach(day => {
      networkRule.data.networkRule.schedule[day].forEach(selectedRange => {
        schedule[day] = applySelectTime(schedule[day], fullToMinute(selectedRange[0]), fullToMinute(selectedRange[1]))
      });
    });

    const initialLoadedForm = {
      id: networkRule.data.networkRule.id,
      teamId: networkRule.data.networkRule.teamId,
      netId: networkRule.data.networkRule.netId,
      name: networkRule.data.networkRule.name,
      devices: networkRule.data.networkRule.Devices.map(d => d.id),
      schedule: schedule,
      condition: {
        completedTags: networkRule.data.networkRule.condition.completedTags,
      }
    };

    setValues(initialLoadedForm);
  }, [networkRule]);

  const updateField = e => {
    e.preventDefault();
    setValues({
      ...form,
      [e.target.name]: e.target.value
    });
  };

  const handleUpsert = async (e) => {
    e.preventDefault();
    try {

      const compressedSchedule = {};
      Object.keys(form.schedule).forEach(day => {
        compressedSchedule[day] = form.schedule[day].sort(([a, b], [c, d]) => {
          return a - c;
        });
        compressedSchedule[day] = form.schedule[day].reduce(
          (accumulator, currentValue) => {
            // if the last time range ending is same than current frame start
            // concatenate, else, append new frame
            if (accumulator.length && accumulator[accumulator.length - 1][1] === currentValue[0]) {
              accumulator[accumulator.length - 1][1] = currentValue[1];
            } else {
              accumulator.push(currentValue);
            }
            return accumulator;
          },
          []
        );

        // convert minutes to hours
        compressedSchedule[day] = compressedSchedule[day].map(frame => {
          const since = (Math.floor(frame[0] / 60) * 100) + frame[0] % 60;
          const until = (Math.floor(frame[1] / 60) * 100) + frame[1] % 60;
          return [since, until]
        });
      });

      // console.log('after', compressedSchedule)
      // console.log('after', JSON.stringify(compressedSchedule))
      const result = await upsertnetworkRule({
        ...form,
        schedule: compressedSchedule,
      });

      if (result.error) {
        throw new Error(result.error)
      }

      setDone(true);
    } catch (e) {
      console.log(e);
      setError(e.message || e);
    }
  };

  const handleSelectDevice = (deviceId) => {
    const newListOfDevices = form.devices.filter(d => d !== deviceId);
    if (newListOfDevices.length === form.devices.length) {
      newListOfDevices.push(deviceId)
    }

    setValues({
      ...form,
      devices: newListOfDevices,
    });
  };

  const handleSelectTag = (newTag) => {
    let newTags = form.condition.completedTags;
    if (!form.condition.completedTags.includes(newTag)) {
      newTags.push(newTag);
    } else {
      newTags = newTags.filter(t => t !== newTag);
    }

    setValues({
      ...form,
      condition: {
        completedTags: newTags,
      },
    });
  };

  if (done) {
    return <Redirect to={returnTo ? `${returnTo}` : '/net/rules'} />;
  }

  if (networkRule.fetching) return <p>Loading details...</p>;
  if (networkRule.error) return <p>Error loading details: {networkRule.error.message}<button className="bg-green-600 m-1 p-1 hover:bg-blue-500 rounded text-sm text-white" onClick={() => window.location.reload(true)}>
    Reload
  </button></p>;

  const tags = ['Epic', 'Home', 'School', 'Chores', 'Internet', 'Games']; // once this is loaded from settings, ensure previously used tags can still be rendered

  const weekdays = [
    {
      id: 'time',
      name: 'Time',
    },
    {
      id: 'mon',
      name: 'Mon',
    },
    {
      id: 'tue',
      name: 'Tue',
    },
    {
      id: 'wed',
      name: 'Wed',
    },
    {
      id: 'thu',
      name: 'Thu',
    },
    {
      id: 'fri',
      name: 'Fri',
    },
    {
      id: 'sat',
      name: 'Sat',
    },
    {
      id: 'sun',
      name: 'Sun',
    },
  ];
  const hours = [
    600, 630,
    700, 730,
    800, 830,
    900, 930,
    1000, 1030,
    1100, 1130,
    1200, 1230,
    1300, 1330,
    1400, 1430,
    1500, 1530,
    1600, 1630,
    1700, 1730,
    1800, 1830,
    1900, 1930,
    2000, 2030,
    2100, 2130,
  ];

  const applySelectTime = (dayArray, selectedHourInMinutes, selectedUntilHourInMinutes) => {
    // add any non selected frame
    for (let i = selectedHourInMinutes; i < selectedUntilHourInMinutes; i = i+5) {
      const selectedIdx = dayArray.findIndex(scheduledTime => scheduledTime[0] === i);
      if (selectedIdx < 0) {
        dayArray.push([i, i + 5]);
      }
    }
    return dayArray;
  }

  const applyDeSelectTime = (dayArray, selectedHourInMinutes, selectedUntilHourInMinutes) => {
    // remove any selected frame
    for (let i = selectedHourInMinutes; i < selectedUntilHourInMinutes; i = i+5) {
      const selectedIdx = dayArray.findIndex(scheduledTime => scheduledTime[0] === i);
      if (selectedIdx > -1) {
        dayArray.splice(selectedIdx, 1);
      }
    }
    return dayArray;
  }

  const fullToMinute = (selectedHour) => {
    const hourPart = Math.floor(selectedHour / 100);
    const minutePart = selectedHour - (hourPart * 100);
    return (hourPart * 60) + minutePart;
  }

  const selectTime = (selectedWeekdayId, selectedHour) => {
    const selectedHourIdx = hours.findIndex(h => h === selectedHour)
    const untilHour = selectedHourIdx + 1 === hours.length ? hours[selectedHourIdx] : hours[selectedHourIdx + 1]
    // console.log(selectedWeekdayId, selectedHour, untilHour)

    const selectedHourInMinutes = fullToMinute(selectedHour);
    const selectedUntilHourInMinutes = fullToMinute(untilHour);

    // console.log(selectedHourInMinutes, selectedUntilHourInMinutes)
    const selectedDays = selectedWeekdayId === 'time' ? ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'] : [selectedWeekdayId];
    const newSchedule = form.schedule;
    selectedDays.forEach(weekdayId => {
      if (!newSchedule[weekdayId]) {
        newSchedule[weekdayId] = [];
      }

      if (newSchedule[weekdayId].findIndex(scheduledTime => scheduledTime[0] === selectedHourInMinutes) < 0) {
        newSchedule[weekdayId] = applySelectTime(newSchedule[weekdayId], selectedHourInMinutes, selectedUntilHourInMinutes);
      } else {
        newSchedule[weekdayId] = applyDeSelectTime(newSchedule[weekdayId], selectedHourInMinutes, selectedUntilHourInMinutes);
      }
    });


    setValues({
      ...form,
      schedule: newSchedule,
    });
  };

  const timeIsSelected = (weekdayId, selectedHour) => {
    const hourPart = Math.floor(selectedHour / 100);
    const minutePart = selectedHour - (hourPart * 100);
    const selectedHourInMinutes = (hourPart * 60) + minutePart;

    if (!form.schedule[weekdayId]) {
      form.schedule[weekdayId] = [];
    }

    // console.log('timeIsSelected', weekdayId, selectedHour, selectedHourInMinutes, !!form.schedule[weekdayId].find(scheduledTime => scheduledTime[0] === selectedHourInMinutes))
    return !!form.schedule[weekdayId].find(scheduledTime => scheduledTime[0] === selectedHourInMinutes)
  }

  return (<>
    <div className="pb-8">
      <h2 className="text-2xl pb-2">
        {i18n._(t('networkRules.edit')`Edit Rule`)}
      </h2>

      <p className="text-gray-700">
        Configure the Rule.
      </p>
    </div>
    <div className="col-span-2">
      <div className="">
        <div className="text-gray-700 py-2 mt-3">
          Name
        </div>
        <input
          ref={firstInput}
          className="input"
          type="text"
          placeholder={i18n._(t('networkSettings.ruleName')`Rule Name`)}
          name="name"
          value={form.name}
          disabled={upserting}
          onChange={updateField}
        />
      </div>

      <div className="text-gray-700 py-2">
        Tasks to be completed:<br/>
        {!!!form.condition.completedTags.length && <span className="text-xs">No categories selected, this rule won't check if tasks has been completed.</span>}
        {!!form.condition.completedTags.length && <span className="text-xs">This rule will require all tasks under the selected categories to be completed.</span>}

      </div>
      <div className="flex flex-wrap">
        {tags.map((name) => (
          <div key={`schedule-tag-${name}`} className="py-2 m-1">
            <Button
              size="extra-small"
              variant={
                (form.condition.completedTags.includes(name)) ?
                  'primary' : 'light'
              }
              onClick={() => handleSelectTag(name)}
            >
              {name}
            </Button>
          </div>
        ))}
      </div>

      <div className="text-gray-700 py-2">
        Devices: <br/>
        {!!!form.devices.length && <span className="text-sm"> No device selected, this rule will be used for all devices.</span>}
        {!!form.devices.length && <span className="text-sm">This rule will affect only the selected device(s).</span>}
      </div>
      {networkDevices.data &&
      <div className="flex flex-wrap">
        {networkDevices.data.networkDevices.map(({ id, name }) => (
          <div key={`device-${id}`} className="py-2 m-1">
            <Button
              size="extra-small"
              variant={
                (form.devices.includes(id)) ?
                  'primary' : 'light'
              }
              onClick={() => handleSelectDevice(id)}
            >
              {name}
            </Button>
          </div>
        ))}
      </div>
      }

      {networkDevices.fetching &&<div className="">loading devices</div>}
      {networkDevices.error &&<div className="">{networkDevices.error.message}</div>}

      <div className="">
        <div className="text-gray-700 py-2 mt-3">
          Schedule
        </div>

        <div className="grid grid-cols-8 gap-4 text-xs">
          {weekdays.map(weekday => (
            <div id={weekday.id}>
              <span onClick={() => selectTime(weekday.id)}>{weekday.name}</span>
              {hours.map(hour => (
                <div
                  id={`${weekday.id}-${hour}`}

                >
                  {weekday.id === 'time' ? <span onClick={() => selectTime(weekday.id, hour)}>{hour}</span> : <span onClick={() => selectTime(weekday.id, hour)}>{timeIsSelected(weekday.id, hour) ? 'x' : '_'}</span>}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
      <br/>

      <div className="grid grid-cols-2 gap-2 py-2">
          <div className="">
            <Button
              size="extra-small"
              variant="actions-cancel"
              disabled={upserting}
              to={returnTo ? `${returnTo}` : '/net/rules'}
            >
              {i18n._(t('networkSettings.cancel')`Cancel`)}
            </Button>
          </div>
          <div className="">
            <Button
              size="extra-small"
              variant="actions-accept"
              disabled={!ready || upserting}
              onClick={handleUpsert}
            >
              {i18n._(t('networkSettings.deviceSave')`Save`)}
            </Button>
          </div>
        </div>


      {error && <p className="text-xs italic text-red-500">{error}</p>}

    </div>
  </>);
};

export default NetworkRuleEdit;
