
// export const UPSERT_REWARD = ...
module.exports.UPSERT_REWARD = `
  mutation upsertReward(
      $id: String
      $title: String
      $description: String
      $icon: String
      $status: String
      $availableHours: String
      $webhookStart: String
      $duration: Int
      $webhookEnd: String
      $pointsType: String
      $points: Int
      $who: [String!]
      $availableSince: Date
      $availableUntil: Date
    ) {
    upsertReward(
      id: $id
      title: $title
      description: $description
      icon: $icon
      status: $status
      availableHours: $availableHours
      webhookStart: $webhookStart
      duration: $duration
      webhookEnd: $webhookEnd
      pointsType: $pointsType
      points: $points
      who: $who
      availableSince: $availableSince
      availableUntil: $availableUntil
    )
  }
`;

module.exports.GIVE_REWARD = `
  mutation giveReward(
      $rewardId: String
      $receiverId: String
      $pointsType: String
      $points: Int
    ) {
    giveReward(
      rewardId: $rewardId
      receiverId: $receiverId
      pointsType: $pointsType
      points: $points
    )
  }
`;

module.exports.DELETE_REWARD = `
  mutation deleteReward(
      $id: String!
    ) {
    deleteReward(
      id: $id
    )
  }
`;
