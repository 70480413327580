import React from 'react';
import classNames from 'classnames';
import PointType from '../components/PointType';

const Points = ({
  points = 0,
  type,
  size = 24,
  color,
  pointClasses = [
    'inline-block',
    'text-xs',
    'text-gray-800',
    'rounded-lg',
    'py-1',
    'px-2',
  ]
}) => {
  let bgColor;

  switch (type) {
    case 'S':
      bgColor = 'bg-gray-300';
      break;
    case 'G':
      bgColor = 'bg-yellow-300';
      break;
    case 'D':
      bgColor = 'bg-blue-200';
      break;
    default:
      bgColor = 'bg-gray-300';
      break;
  }

  return <div className={classNames(pointClasses.concat(bgColor))}>
    <span className="">{points} x <PointType type={type} size={size} color={color} /> </span>
  </div>

}

export default Points;
