import React, { useState, useEffect, useRef } from 'react';
import {Link, Redirect, useLocation, useParams} from 'react-router-dom';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz';
import {useSelector} from 'react-redux';
import omitDeep from '../utils/omitDeep';

import { t } from '@lingui/macro';

import i18n from '../i18n';
import { formatDate, parseDate, getDateFromDate, getTimeFromDate } from '../utils';

import Button from '../components/Button';

import { getUser } from '../store/slices/userSlice';

import { useMutation, useQuery } from 'urql';
import {UPSERT_TASK, DELETE_TASK} from '@darescouts/api-graphql/tasks/tasksMutations';
import { LIST_KIDS } from '@darescouts/api-graphql/kids/kidsQueries'
import {GET_TASK} from '@darescouts/api-graphql/tasks/tasksQueries';
import Points from '../components/Points';

const tags = ['Epic', 'Home', 'School', 'Chores', 'Internet', 'Games']; // once this is loaded from settings, ensure previously used tags can still be rendered
const times = [
  '05:00', '05:05', '05:10', '05:15', '05:20', '05:25', '05:30', '05:35', '05:40', '05:45', '05:50', '05:55',
  '06:00', '06:05', '06:10', '06:15', '06:20', '06:25', '06:30', '06:35', '06:40', '06:45', '06:50', '06:55',
  '07:00', '07:05', '07:10', '07:15', '07:20', '07:25', '07:30', '07:35', '07:40', '07:45', '07:50', '07:55',
  '08:00', '08:05', '08:10', '08:15', '08:20', '08:25', '08:30', '08:35', '08:40', '08:45', '08:50', '08:55',
  '09:00', '09:05', '09:10', '09:15', '09:20', '09:25', '09:30', '09:35', '09:40', '09:45', '09:50', '09:55',
  '10:00', '10:05', '10:10', '10:15', '10:20', '10:25', '10:30', '10:35', '10:40', '10:45', '10:50', '10:55',
  '11:00', '11:05', '11:10', '11:15', '11:20', '11:25', '11:30', '11:35', '11:40', '11:45', '11:50', '11:55',
  '12:00', '12:05', '12:10', '12:15', '12:20', '12:25', '12:30', '12:35', '12:40', '12:45', '12:50', '12:55',
  '13:00', '13:05', '13:10', '13:15', '13:20', '13:25', '13:30', '13:35', '13:40', '13:45', '13:50', '13:55',
  '14:00', '14:05', '14:10', '14:15', '14:20', '14:25', '14:30', '14:35', '14:40', '14:45', '14:50', '14:55',
  '15:00', '15:05', '15:10', '15:15', '15:20', '15:25', '15:30', '15:35', '15:40', '15:45', '15:50', '15:55',
  '16:00', '16:05', '16:10', '16:15', '16:20', '16:25', '16:30', '16:35', '16:40', '16:45', '16:50', '16:55',
  '17:00', '17:05', '17:10', '17:15', '17:20', '17:25', '17:30', '17:35', '17:40', '17:45', '17:50', '17:55',
  '18:00', '18:05', '18:10', '18:15', '18:20', '18:25', '18:30', '18:35', '18:40', '18:45', '18:50', '18:55',
  '19:00', '19:05', '19:10', '19:15', '19:20', '19:25', '19:30', '19:35', '19:40', '19:45', '19:50', '19:55',
  '20:00', '20:05', '20:10', '20:15', '20:20', '20:25', '20:30', '20:35', '20:40', '20:45', '20:50', '20:55',
  '21:00', '21:05', '21:10', '21:15', '21:20', '21:25', '21:30', '21:35', '21:40', '21:45', '21:50', '21:55',
  '22:00', '22:05', '22:10', '22:15', '22:20', '22:25', '22:30', '22:35', '22:40', '22:45', '22:50', '22:55',
];

const TaskEditor = () => {
  const { taskId } = useParams();
  const user = useSelector(getUser);
  const firstInput = useRef(null);
  const returnTo = new URLSearchParams(useLocation().search).get('returnTo');
  const [deleteTaskResult, deleteTask] = useMutation(DELETE_TASK);

  const [listKidsQuery] = useQuery({
    query: LIST_KIDS,
    requestPolicy: 'cache-and-network',
  });

  const [task] = useQuery({
    query: GET_TASK,
    variables: { id: taskId },
    pause: !taskId,
    requestPolicy: 'cache-and-network',
  });

  const [createTaskResult, createTask] = useMutation(UPSERT_TASK);
  const { fetching, data, error } = createTaskResult;
  const [ready, setReady] = useState(false);
  const [step, setStep] = useState(0);
  const [stepsStarted, setStepsStarted] = useState(true); // assumes create
  const [deleting, setDeleting] = useState(false);

  const steps = [
    {
      tag: 'title',
    },
    {
      tag: 'effortMode',
    },
    {
      tag: 'who',
    },
    {
      tag: 'schedule',
    },
  ];

  // round current date and time to nearest 5 minutes ex. 11:23 becomes 11:25
  // (new Date((Math.round((new Date().getTime() + 150000) / 300000)) * 300000)).toTimeString().slice(0,5)
  const currentRoundedDateTime = new Date((Math.round((new Date().getTime() + 150000) / 300000)) * 300000);

  const [form, setValues] = useState({
    title: '',
    scheduleMode: 'Daily',
    who: [],
    tags: [],
    effortMode: 'Individual',
    when: {},
    pointsType: 'S',
    points: 1,
    startsAt: currentRoundedDateTime.toISOString(),
  });

  useEffect(() => {
    if (firstInput.current) {
      firstInput.current.focus();
    }
  }, []);

  useEffect(() => {
    if (!task || task.fetching || task.error || !task.data || !task.data.task) return;
    console.log('task loaded')
    const initialLoadedForm = {
      id: task.data.task.id,
      title: task.data.task.title,
      scheduleMode: task.data.task.scheduleMode,
      who: task.data.task.who,
      tags: task.data.task.tags,
      effortMode: task.data.task.effortMode,
      when:  omitDeep(task.data.task.when, ['__typename']),
      pointsType: task.data.task.pointsType,
      points: task.data.task.points,
      startsAt: task.data.task.startsAt,
    };

    setStepsStarted(false);
    setValues(initialLoadedForm);
  }, [task]);

  useEffect(() => {
    let isReady;

    if (
      step < steps.findIndex(s => s.tag === 'who')
      && form.title
    ) {
      isReady = true;
    } else if (
      form.title
      && form.startsAt
      && form.who
      && form.who.length > 0
    ) {
      isReady = true;
    } else {
      isReady = false;
    }

    setReady(isReady);
  }, [form, step]);

  const updateField = e => {
    e.preventDefault();
    setValues({
      ...form,
      [e.target.name]: e.target.value
    });
  };

  const handleSelectKid = (kidId) => {
    let newWho;

    if(kidId === 'Anyone') {
      newWho = [kidId];
    } else {
      // keep only the items that are NOT the kidId
      newWho = form.who.filter(who => who !== kidId && !['Anyone'].includes(who));

      if (!form.who.includes(kidId)) {
        newWho.push(kidId);
      }
    }

    setValues({
      ...form,
      who: newWho,
    });
  };

  const handleSelectScheduleMode = (scheduleMode) => {
    if (form.scheduleMode !== scheduleMode) {
      form.when = {};
    }

    // some resets
    if (scheduleMode === 'Weekly' && !form.when.Weekly) {
      form.when.Weekly = {};
      form.startsAt = currentRoundedDateTime.toISOString();
    }

    setValues({
      ...form,
      scheduleMode: scheduleMode,
    });
  };

  const handleWeeklyChange = (day) => {
    const newWhen = form.when;

    // set
    if (!newWhen.Weekly[day] || !newWhen.Weekly[day].at) {
      newWhen.Weekly[day] = {
        at: getTimeFromDate(utcToZonedTime(form.startsAt, user.timezone)),
      }
    } else {
      // un-set
      delete newWhen.Weekly[day];
    }

    setValues({
      ...form,
      when: newWhen,
    });
  };

  const handleWeeklyChangeTime = (day, e) => {
    e.preventDefault();
    const newSelectedTime = e.target.value;

    form.when.Weekly[day] = {
      at: newSelectedTime,
    };

    setValues({
      ...form,
    });
  };

  const handleSelectEffortMode = (effortMode) => {
    setValues({
      ...form,
      effortMode: effortMode,
    });
  };

  const handleSelectTag = (newTag) => {
    let newTags = form.tags;
    if (!form.tags.includes(newTag)) {
      newTags.push(newTag);
    } else {
      newTags = newTags.filter(t => t !== newTag);
    }

    setValues({
      ...form,
      tags: newTags,
    });
  };

  const handleSelectPointsType = (pointsType) => {
    let points = 1
    if (pointsType === form.pointsType) {
      points = parseInt(form.points) < 5 ? parseInt(form.points) + 1 : 1;
    }

    setValues({
      ...form,
      pointsType: pointsType,
      points: points,
    });
  };

  const handleStartDateChange = (date) => {
    // form.startsAt is UTC, convert it to user local to get the selected local time
    const timePartLocal = getTimeFromDate(utcToZonedTime(form.startsAt, user.timezone));

    // the new selected date is browser local, get it as a yyyy-MM-dd string
    const newDatePartLocal = getDateFromDate(date);

    // with the new selected yyyy-MM-dd (which is browser local) + the previously selected time, create a new date (which will be user local)
    const newDateAndTimeLocal = zonedTimeToUtc(`${newDatePartLocal} ${timePartLocal}`, user.timezone);

    setValues({
      ...form,
      startsAt: zonedTimeToUtc(newDateAndTimeLocal, user.timezone).toISOString(), // store it as UTC
    });
  };

  const handleStartTimeChange = (e) => {
    e.preventDefault();
    const newSelectedTime = e.target.value;

    // form.startsAt is UTC, convert it to user local and get the date part
    const datePartLocal = getDateFromDate(utcToZonedTime(form.startsAt, user.timezone));

    // with the current yyyy-MM-dd + the new selected time, create a new date (which will be user local)
    const newDateAndTimeLocal = zonedTimeToUtc(`${datePartLocal} ${newSelectedTime}`, user.timezone);

    setValues({
      ...form,
      startsAt: zonedTimeToUtc(newDateAndTimeLocal, user.timezone).toISOString(), // store it as UTC
    });
  };

  const handleCreate = async (e) => {
    e.preventDefault();
    createTask(form).then(result => {
      // The result is almost identical to `updateTodoResult` with the exception
      // of `result.fetching` not being set.
      console.log('newTask -> handlecreateTask', result);
    });
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    const reallyDelete = confirm('Are you sure you want to delete this item?')
    if (!reallyDelete) {
      return;
    }
    setDeleting(true);
    deleteTask({ id: taskId }).then(result => {
      console.log('deleteTask', result);
    });
  };

  if (!error && data) {
    return <Redirect to={returnTo ? `${returnTo}` : '/tasks'} />;
  }

  if (deleting && deleteTaskResult.data && deleteTaskResult.data.deleteTask) {
    return <Redirect to="/tasks" />;
  }

  if (task.fetching) return <p>Loading task...</p>;
  if (task.error) return <p>Error loading task: {task.error.message}<button className="bg-green-600 m-1 p-1 hover:bg-blue-500 rounded text-sm text-white" onClick={() => window.location.reload(true)}>
    Reload
  </button></p>;

  return (<>
    <div className="pb-8">
      {!form.id && <h2 className="text-2xl pb-2">
        {i18n._(t('TaskEditor.new')`Create a new task`)}
      </h2>}
      {form.id && <h2 className="text-2xl pb-2">
        {i18n._(t('TaskEditor.edit')`Task editor`)}
      </h2>}

      {!form.id && <p className="text-gray-700">
        Give the new task a title and schedule it.
      </p>}
      {form.id && <p className="text-gray-700">
        Update or delete this task.
      </p>}
    </div>
    <div className="col-span-2">

      {!stepsStarted &&
      <>
        <h1 className="text-2xl text-gray-900">
          {form.title}
        </h1>
        <div className="text-gray-700 py-2">
          {form.scheduleMode === 'Once' && <div>
            Once on {form.when.Once.at}
          </div>}
          {form.scheduleMode === 'Daily' && <div>
            Daily at {form.when.Daily.at}
          </div>}
          {form.scheduleMode === 'Weekly' && <div>
            Every: <br/> {[
            { id: 'mon', name: i18n._(t('days.mon')`Monday`) },
            { id: 'tue', name: i18n._(t('days.tue')`Tuesday`) },
            { id: 'wed', name: i18n._(t('days.wed')`Wednesday`) },
            { id: 'thu', name: i18n._(t('days.thu')`Thursday`) },
            { id: 'fri', name: i18n._(t('days.fri')`Friday`) },
            { id: 'sat', name: i18n._(t('days.sat')`Saturday`) },
            { id: 'sun', name: i18n._(t('days.sun')`Sunday`) },
          ].filter(day => form.when.Weekly[day.id] && form.when.Weekly[day.id].at).map(day => {

            return <div key={`day-${day.id}`}>{day.name} at {form.when.Weekly[day.id].at}</div>
          })}
          </div>}
        </div>

        <div className="grid grid-cols-3 gap-2 py-2">
          <div className="">
            <Button
              size="extra-small"
              variant="warning"
              disabled={false}
              onClick={() => setStepsStarted(true)}
            >
              {i18n._(t('TaskEditor.actionEdit')`Modify`)}
            </Button>
          </div>
          <div className="">
            <Button
              size="extra-small"
              variant="actions-cancel"
              disabled={false}
              onClick={handleDelete}
            >
              {i18n._(t('TaskEditor.delete')`Delete`)}
            </Button>
          </div>
          <div className="">
            <Button
              size="extra-small"
              variant="actions-accept"
              disabled={false}
              to="/tasks"
            >
              {i18n._(t('TaskEditor.cancel')`Done`)}
            </Button>
          </div>
        </div>
      </>
      }

      {stepsStarted && <>
        {steps[step].tag === 'title' &&
        <>
          <div className="text-gray-700 py-2">
            Title for the new task:
          </div>
          <div className="">
            <input
              ref={firstInput}
              className="input"
              type="text"
              placeholder={i18n._(t('TaskEditor.title')`Title`)}
              name="title"
              value={form.title}
              onChange={updateField}
            />
          </div>

          <div className="text-gray-700 py-2">
            Points
          </div>
          <div className="flex flex-wrap">
            {[
              { id: 'S', name: 'Silver' },
              { id: 'G', name: 'Gold' },
              { id: 'D', name: 'Diamond' },
            ].map(({ id, name }) => (
              <div key={`schedule-mode-${id}`} className="py-2 m-1">
                <Button
                  size="extra-small"
                  variant={
                    (form.pointsType === id) ?
                      'primary' : 'light'
                  }
                  onClick={() => handleSelectPointsType(id)}
                >
                  <Points points={form.pointsType === id ? form.points : 0} type={id} />
                </Button>
              </div>
            ))}
          </div>

          <div className="text-gray-700 py-2">
            Tags
          </div>
          <div className="flex flex-wrap">
            {tags.map((name) => (
              <div key={`schedule-tag-${name}`} className="py-2 m-1">
                <Button
                  size="extra-small"
                  variant={
                    (form.tags.includes(name)) ?
                      'primary' : 'light'
                  }
                  onClick={() => handleSelectTag(name)}
                >
                  {name}
                </Button>
              </div>
            ))}
          </div>
        </>
        }

        {steps[step].tag === 'effortMode' &&
        <>
          <div className="text-gray-700 py-2">
            <strong>Individual</strong> every kid who perform the task get points.<br/><br/>
            <strong>Race</strong> task can be claimed only by one kid.<br/><br/>
            <strong>Team</strong> points are given each participant only if everyone achieve the task. <br/>
          </div>
          <div className="flex flex-wrap">
            {[
              { id: 'Individual', name: 'Individual' },
              { id: 'Race', name: 'Race' },
              { id: 'Team', name: 'Team' },
            ].map(({ id, name }) => (
              <div key={`schedule-mode-${id}`} className="py-2 m-1">
                <Button
                  size="extra-small"
                  variant={
                    (form.effortMode === id) ?
                      'primary' : 'light'
                  }
                  onClick={() => handleSelectEffortMode(id)}
                >
                  {name}
                </Button>
              </div>
            ))}
          </div>
        </>
        }

        {steps[step].tag === 'who' &&
        <>
          <div className="text-gray-700 py-2">
            Select who is expected or allowed to perform this task:
          </div>
          {listKidsQuery.data &&
          <div className="flex flex-wrap">
            {[{ id: 'Anyone', name: 'Anyone' }].concat(listKidsQuery.data.kids).map(({ id, name }) => (
              <div key={`kid-${id}`} className="py-2 m-1">
                <Button
                  size="extra-small"
                  variant={
                    (form.who.includes(id)) ?
                      'primary' : 'light'
                  }
                  onClick={() => handleSelectKid(id)}
                >
                  {name}
                </Button>
              </div>
            ))}
          </div>
          }
          {listKidsQuery.fetching &&<div className="">loading kids</div>}
          {listKidsQuery.error &&<div className="">{listKidsQuery.error.message}</div>}
        </>
        }

        {steps[step].tag === 'schedule' &&
        <>
          <div className="text-gray-700 py-2">
            Schedule
          </div>
          <div className="flex flex-wrap">
            {[
              { id: 'Once', name: 'Once' },
              { id: 'Daily', name: 'Daily' },
              { id: 'Weekly', name: 'Weekly' },
            ].map(({ id, name }) => (
              <div key={`schedule-mode-${id}`} className="py-2 m-1">
                <Button
                  size="extra-small"
                  variant={
                    (form.scheduleMode === id) ?
                      'primary' : 'light'
                  }
                  onClick={() => handleSelectScheduleMode(id)}
                >
                  {name}
                </Button>
              </div>
            ))}
          </div>
          {form.scheduleMode === 'Once' && <div className="grid grid-cols-2 gap-2 py-2">
            <div className="">
              {/*{form.startsAt}*/}
              on
              <DayPickerInput
                value={formatDate(utcToZonedTime(form.startsAt, user.timezone), 'dd/MM/yyyy')}
                dayPickerProps={{modifiers: {disabled: [{before: new Date()}]}}}
                formatDate={formatDate}
                format={'dd/MM/yyyy'}
                parseDate={parseDate}
                onDayChange={handleStartDateChange}
              />
            </div>
            <div className="">
              {/*at {getTimeFromDate(utcToZonedTime(form.startsAt, user.timezone))}*/}
              at
              <select
                className="input"
                id="timeSelector"
                value={getTimeFromDate(utcToZonedTime(form.startsAt, user.timezone))}
                onChange={handleStartTimeChange}
              >
                {times.map((time, index) => (
                  <option key={`time-${index}`} value={time}>{time}</option>
                ))}
              </select>
            </div>
          </div>}

          {form.scheduleMode === 'Daily' && <div className="grid grid-cols-2 gap-2 py-2">
            <div className="">
              {/*{form.startsAt}*/}
              since
              <DayPickerInput
                value={formatDate(utcToZonedTime(form.startsAt, user.timezone), 'dd/MM/yyyy')}
                dayPickerProps={{modifiers: {disabled: [{before: new Date()}]}}}
                formatDate={formatDate}
                format={'dd/MM/yyyy'}
                parseDate={parseDate}
                onDayChange={handleStartDateChange}
              />
            </div><div className="">
            {/*at {getTimeFromDate(utcToZonedTime(form.startsAt, user.timezone))}*/}
            at
            <select
              className="input"
              id="timeSelector"
              value={getTimeFromDate(utcToZonedTime(form.startsAt, user.timezone))}
              onChange={handleStartTimeChange}
            >
              {times.map((time, index) => (
                <option key={`time-daily-${index}`} value={time}>{time}</option>
              ))}
            </select>
          </div>
          </div>}

          {form.scheduleMode === 'Weekly' && <div className="grid grid-cols-2 gap-1 ">
            {[
              { id: 'mon', name: 'Monday' },
              { id: 'tue', name: 'Tuesday' },
              { id: 'wed', name: 'Wednesday' },
              { id: 'thu', name: 'Thursday' },
              { id: 'fri', name: 'Friday' },
              { id: 'sat', name: 'Saturday' },
              { id: 'sun', name: 'Sunday' },
            ].map(({ id, name }) => (
              <div key={`schedule-weekly-${id}`} className="grid grid-cols-2 gap-1 py-1 bg-green-100">
                <div className="">
                  <Button
                    size="extra-small"
                    variant={
                      (form.when.Weekly && form.when.Weekly[id] && form.when.Weekly[id].at) ?
                        'primary' : 'light'
                    }
                    onClick={() => handleWeeklyChange(id)}
                  >
                    {name}
                  </Button>
                </div>
                <div className="">
                  {(form.when.Weekly && form.when.Weekly[id] && form.when.Weekly[id].at) &&
                  <select
                    className=" py-2 px-2 mt-0 text-sm"
                    id="timeSelector"
                    value={form.when.Weekly[id].at}
                    onChange={(e) => handleWeeklyChangeTime(id, e)}
                  >
                    {times.map((time, index) => (
                      <option key={`time-weekly-${id}-${index}`} value={time}>{time}</option>
                    ))}
                  </select>}
                </div>
              </div>
            ))}
          </div>}
        </>
        }
        <div className="grid grid-cols-2 gap-2 py-2">
          <div className="">
            {step === 0 &&<Button
              size="extra-small"
              variant="actions-cancel"
              disabled={fetching}
              to={returnTo ? `${returnTo}` : '/tasks'}
            >
              {i18n._(t('TaskEditor.cancel')`Cancel`)}
            </Button>}
            {step > 0 &&<Button
              size="extra-small"
              variant="warning"
              disabled={fetching}
              onClick={() => setStep(step - 1)}
            >
              {i18n._(t('TaskEditor.previousStep')`Previous`)}
            </Button>}
          </div>
          <div className="">
            {step < steps.length - 1 &&<Button
              size="extra-small"
              variant="primary"
              disabled={!ready || fetching}
              onClick={() => setStep(step + 1)}
            >
              {i18n._(t('TaskEditor.continue')`Continue`)}
            </Button>}
            {step === steps.length - 1 && !form.id &&<Button
              size="extra-small"
              variant="actions-accept"
              disabled={!ready || fetching}
              onClick={handleCreate}
            >
              {i18n._(t('TaskEditor.create')`Create`)}
            </Button>}
            {step === steps.length - 1 && form.id &&<Button
              size="extra-small"
              variant="actions-accept"
              disabled={!ready || fetching}
              onClick={handleCreate}
            >
              {i18n._(t('TaskEditor.update')`Update`)}
            </Button>}
          </div>
        </div>
      </>}


      {error && <p className="text-xs italic text-red-500">{error.message}</p>}



    </div>
  </>);
};

export default TaskEditor;
