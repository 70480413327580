import React, { useEffect } from 'react';
import {Link} from 'react-router-dom';
import {t} from '@lingui/macro';
import { useQuery} from 'urql'

import i18n from '../i18n';

import { LIST_TASK } from '@darescouts/api-graphql/tasks/tasksQueries'
import { LIST_KIDS } from '@darescouts/api-graphql/kids/kidsQueries'
import NewAccountRedirector from '../components/NewAccountRedirector';
import PointType from '../components/PointType';
import Points from '../components/Points';

const Tasks = () => {
  const [tasks] = useQuery({
    query: LIST_TASK,
    requestPolicy: 'cache-and-network',
  });

  const [kids] = useQuery({
    query: LIST_KIDS,
    requestPolicy: 'cache-and-network',
  });

  if (tasks.fetching) return <p>Loading tasks...</p>;
  if (tasks.error) return <p>Error loading list of tasks<button className="bg-green-600 m-1 p-1 hover:bg-blue-500 rounded text-sm text-white" onClick={() => window.location.reload(true)}>
    Reload
  </button></p>;
  if (!tasks.data || !tasks.data.tasks) return <p>No tasks data ?<button className="bg-green-600 m-1 p-1 hover:bg-blue-500 rounded text-sm text-white" onClick={() => window.location.reload(true)}>
    Reload
  </button></p>;

  if (kids.fetching) return <p>Loading tasks...</p>;
  if (kids.error) return <p>Error loading list of tasks<button className="bg-green-600 m-1 p-1 hover:bg-blue-500 rounded text-sm text-white" onClick={() => window.location.reload(true)}>
    Reload
  </button></p>;
  if (!kids.data || !kids.data.kids) return <p>No kids data ?<button className="bg-green-600 m-1 p-1 hover:bg-blue-500 rounded text-sm text-white" onClick={() => window.location.reload(true)}>
    Reload
  </button></p>;

  const dateFormatOptions = new Intl.DateTimeFormat('en-au', {
    dateStyle: 'short',
  });

  return (<>
    <div className="pb-8">
      <h2 className="text-2xl pb-2">All tasks</h2>
      <p className="text-gray-700">
        This is a list of all past, current and future tasks. <br/>
        <br/>
        <Link className="link" to="/tasks/new">
          {i18n._(t('tasks.new')`Add a new task or chore`)}
        </Link>
      </p>
    </div>
    <div className="col-span-2">
      {tasks.data.tasks.length === 0 && <div>
        No tasks
        <NewAccountRedirector />
      </div>}

      {tasks.data.tasks.map((task) => {

        task.humanSince = dateFormatOptions.format(new Date(task.startsAt))
        return (
          <div key={task.id} className="pb-3 mb-6 border-b-2 border-gray-300">
            <div className="col-span-3">
              <h1 className="text-gray-700">
                {task.scheduleMode} {task.when2} <small className="text-ultra-small">since {task.humanSince}</small>
              </h1>
              <div className="float-right">
                {kids.data.kids.filter(kid => task.who.includes(kid.id) || task.who.includes('Anyone')).map((kid) => {
                  return (
                    <div key={`task-${task.id}-${kid.id}`} className="mr-1 text-xs inline-flex items-center px-3 py-1 bg-blue-200 text-blue-700 rounded-full">
                      {kid.name}
                    </div>
                  )
                })}
              </div>
              <Link className="text-lg text-gray-800" to={`/tasks/${task.id}`}>
                {task.title}
              </Link> <br/>
              <span className="text-sm">
                <Points points={task.points} type={task.pointsType} />
              </span>
            </div>
          </div>
        )
      })}

      {!!tasks.data.tasks.length && <div className="text-sm text-gray-700">
        <strong>Tip:</strong> Select the task to manage it.
      </div>}

    </div>
  </>);
};

export default Tasks;
