import React, {useState, useEffect, } from 'react';
import { Link } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import { t } from '@lingui/macro';
import i18n from '../i18n';

import authSlice, {getAuth} from '../store/slices/authSlice';
import SignOut from '@iconscout/react-unicons/icons/uil-signout';
import {useDispatch, useSelector} from 'react-redux';
import Button from '../components/Button';
import identityAPI from '../identityAPI.js';
import Points from '../components/Points';
import {useMutation, useQuery} from 'urql';
import {GET_GENERAL_TEAM_SETTINGS} from '@darescouts/api-graphql/team/teamSettingsQueries';
import {UPDATE_GENERAL_TEAM_SETTINGS} from '@darescouts/api-graphql/team/teamMutations';
import {getCurrentTeam, getTeams} from '../store/slices/teamsSlice';
import omitDeep from '../utils/omitDeep';
import UsersIcon from '@iconscout/react-unicons/icons/uil-users-alt';

const Settings = () => {
  const auth = useSelector(getAuth);
  const dispatch = useDispatch();
  const teams = useSelector(getTeams);
  const currentTeam = useSelector(getCurrentTeam);

  const [getGeneralTeamSettings] = useQuery({
    query: GET_GENERAL_TEAM_SETTINGS,
    variables: { teamId: teams.currentTeamId },
    pause: !teams.currentTeamId,
    requestPolicy: 'cache-and-network',
  });

  const [updateGeneralSettingsResult, updateGeneralSettings] = useMutation(UPDATE_GENERAL_TEAM_SETTINGS);
  const { updating, data, error } = updateGeneralSettingsResult;

  const [appVersionDetails, setAppVersionDetails] = useState({
    ...identityAPI.getAppVersionDetails()
  });

  const [form, setValues] = useState({
    teamId: teams.currentTeamId,
    discountPointsType: 'S',
    discountPoints: 0,
  });

  useEffect(() => {
    if (!getGeneralTeamSettings || getGeneralTeamSettings.fetching || getGeneralTeamSettings.error || !getGeneralTeamSettings.data || !getGeneralTeamSettings.data.getGeneralTeamSettings) return;
    console.log('generalSettings loaded', teams.currentTeamId)
    const initialLoadedForm = {
      teamId: teams.currentTeamId,
      discountPointsType: getGeneralTeamSettings.data.getGeneralTeamSettings.discountPointsType,
      discountPoints: getGeneralTeamSettings.data.getGeneralTeamSettings.discountPoints,
    };

    setValues(initialLoadedForm);
  }, [getGeneralTeamSettings]);

  const handleSignOut = async (e) => {
    e.preventDefault();
    await identityAPI.resetTokens(true); // reload:true because we need a way to kill urlql on logout
    // dispatch(authSlice.actions.signOut());
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    updateGeneralSettings(form).then(result => {
      // The result is almost identical to `updateTodoResult` with the exception
      // of `result.fetching` not being set.
      console.log('updateGeneralSettings', result);
    });
  };

  const handleSelectDiscount = (pointsType) => {
    let points = 1
    if (pointsType === form.discountPointsType) {
      points = parseInt(form.discountPoints) <= -5 ? 5 : parseInt(form.discountPoints) - 1;
    }

    setValues({
      ...form,
      discountPointsType: pointsType,
      discountPoints: points,
    });
  };

  return <>
    <div className="pb-8">
      <h2 className="text-2xl pb-2">Settings</h2>
      <p className="text-gray-700">
        Manage your profile or logout.
      </p>
    </div>
    <div className="col-span-2">

      <div className="block mt-1 mb-4">
        <span className="text-gray-700 py-2 mt-4">Subscriptions: </span>
        {currentTeam.enabledActions.includes('FN_BASICS') &&
        <span className="text-white text-xs p-1 bg-blue-400 rounded mr-2">
          Core
        </span>
        }
        {currentTeam.enabledActions.includes('FN_NET') &&
        <span className="text-white text-xs p-1 bg-blue-400 rounded mr-2">
          Devices
        </span>
        }
      </div>

      <Button
        size="extra-small"
        variant="primary"
        to="/profile"
      >
        Manage Profile
      </Button>

      {currentTeam.enabledActions.includes('LIST_TEAM_MEMBERS') && <div className="block mt-4 mb-1"><Button
        size="extra-small"
        variant="primary"
        to="/members"
        >
        {i18n._(t('links.members.list')`Kids and Users`)}
      </Button></div>}

      {auth.decoded.t.length > 1 && <div className="block mt-4 mb-1">
        <Button
          size="extra-small"
          variant="primary"
          to="/switch-team"
        >
          Switch teams ({auth.decoded.t.length})
        </Button>
      </div>}

      {currentTeam.enabledActions.includes('FN_NET')&& <div className="block mt-4 mb-1">
        <Button
          size="extra-small"
          variant="primary"
          to="/net/settings"
        >
          {i18n._(t('net.network')`Network`)}
        </Button>
      </div>}

      {currentTeam.enabledActions.includes('FN_NET')&& <div className="block mt-4 mb-1">
        <Button
          size="extra-small"
          variant="primary"
          to="/net/rules"
        >
          {i18n._(t('net.rules')`Rules`)}
        </Button>
      </div>}

      <div className="text-gray-700 py-2 mt-4">
        Daily Discount or Allowance
      </div>
      <div className="flex flex-wrap">
        {[
          { id: 'S', name: 'Silver' },
          { id: 'G', name: 'Gold' },
          { id: 'D', name: 'Diamond' },
        ].map(({ id, name }) => (
          <div key={`schedule-mode-${id}`} className="py-2 m-1">
            <Button
              size="extra-small"
              variant={
                (form.discountPointsType === id) ?
                  'primary' : 'light'
              }
              onClick={() => handleSelectDiscount(id)}
            >
              <Points points={form.discountPointsType === id ? form.discountPoints : 0} type={id} />
            </Button>
          </div>
        ))}
      </div>

      <Button
        size="extra-small"
        variant="actions-accept"
        disabled={updating}
        onClick={handleUpdate}
      >
        {i18n._(t('Settings.save')`Save`)}
      </Button>

      {error && <p className="text-xs italic text-red-500">{error.message}</p>}

      <br/>
      <div className="text-sm text-center">
        App version:
        <span className="ml-2 mt-2 text-gray-700">
           available {appVersionDetails.available}, loaded: {appVersionDetails.loaded}
        </span>
      </div>

      <Button
        size="extra-small"
        variant="success"
        onClick={() => window.location.reload(true)}
      >
        Reload
      </Button>

      <br/>
      <Button
        size="extra-small"
        variant="actions-cancel"
        onClick={handleSignOut}
      >
        {i18n._(t('links.signOut')`Sign Out`)} <SignOut color="#ffffff" className="inline-block mb-1" size={18} />
      </Button>

    </div>
  </>
};

export default Settings;
