import React, { useState, useEffect, useRef } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import { t } from '@lingui/macro';
import i18n from '../i18n';

import { getAuth } from '../store/slices/authSlice';
import { getTeams } from '../store/slices/teamsSlice';

import userSlice, { getUser } from '../store/slices/userSlice';
import { generateRandomString } from '../utils';
import { kidsAppClientId } from '../config';

import identityAPI from '../identityAPI';
import Button from '../components/Button';
import {useQuery} from 'urql';
import {LIST_KIDS} from '@darescouts/api-graphql/kids/kidsQueries';

const AuthorizeKidsApp = () => {
  const teams = useSelector(getTeams);

  const [kids] = useQuery({
    query: LIST_KIDS,
    variables: { andTeamUser: true, },
    requestPolicy: 'cache-and-network',
  });

  const [form, setValues] = useState({
    targetUserId: null,
    code: `${generateRandomString(2, 'QWERTYUPASDFGHKZXCVBNM')}${generateRandomString(3, '1234567890')}`,
    clientId: kidsAppClientId,  // because the code will be used from another app, we need to provide that id instead of ours
  });

  const [sending, setSending] = useState(false);
  const [error, setError] = useState(false);
  const [done, setDone] = useState(false);

  const handleCreate = async (e) => {
    e.preventDefault();
    setSending(true);

    try {
      await identityAPI.createAuthorizationCode(
        {
          userId: form.targetUserId,
          teamId: teams.currentTeamId,
          AuthorizationCode: {
            code: form.code,
            clientId: form.clientId,
          },
        });
      setSending(false);
      setDone(true);
    } catch (apiError) {
      console.log(apiError);
      setError(apiError.message);
      setSending(false);
    }
  };

  const handleSelectTargetUser = (targetUserId) => {
    setValues({
      ...form,
      targetUserId: targetUserId,
    });
  };

  if (kids.fetching) return <p>Loading rewards...</p>;
  if (kids.error) return <p>Error loading list of rewards<button className="bg-green-600 m-1 p-1 hover:bg-blue-500 rounded text-sm text-white" onClick={() => window.location.reload(true)}>
    Reload
  </button></p>;
  if (!kids.data || !kids.data.kids) return <p>No kids data ?<button className="bg-green-600 m-1 p-1 hover:bg-blue-500 rounded text-sm text-white" onClick={() => window.location.reload(true)}>
    Reload
  </button></p>;

  const incomplete = !form.targetUserId;

  return (
    <>
      <div className="pb-8">
        <h2 className="text-2xl pb-2">Authorize Kid's Dashboard</h2>
        <p className="text-gray-700">
          <strong>Dare Scouts</strong> works best when kids can manage their tasks and challenges themselves.
        </p>
      </div>

      {!done && <div className="col-span-2">
        <div className="text-gray-700 py-2 mb-3">
          To start using the Kid's dashboard, generate an access code here and then open <a href="https://kids.darescouts.com" target="_blank" className="text-blue-700">kids.darescouts.com</a> on the device you want to use it.
        </div>

        <div className="text-gray-700 py-2">
          Select the user:

          <div className="flex flex-wrap">
            {kids.data.kids.map(({ id, name }) => (
              <div key={`kid-${id}`} className="py-2 m-1">
                <Button
                  size="extra-small"
                  variant={
                    (form.targetUserId === id) ?
                      'primary' : 'light'
                  }
                  onClick={() => handleSelectTargetUser(id)}
                >
                  {name}
                </Button>
              </div>
            ))}
          </div>
        </div>

        <div className="text-gray-700 py-2 mb-3 text-sm">
          <strong>Team Shared User</strong> Use it when the device is shared by multiple kids.
        </div>

        <div className="text-gray-700 py-2 mb-3 text-sm">
          <strong>Tip:</strong> Each code can be used only once and they are valid only for a few minutes, but don't worry, you can generate new codes at any time.
        </div>

        {error && <p className="text-xs italic text-red-500">{error}</p>}

        <div className="grid grid-cols-2 gap-2">
          <div className="">
            <Button
              size="extra-small"
              variant="actions-cancel"
              disabled={sending}
              to="/"
            >
              {i18n._(t('AuthorizeKidsApp.cancel')`Cancel`)}
            </Button>
          </div>
          <div className="">
            <Button
              size="extra-small"
              variant="actions-accept"
              disabled={incomplete || sending}
              onClick={handleCreate}
            >
              {i18n._(t('AuthorizeKidsApp.continue')`Generate`)}
            </Button>
          </div>
        </div>
      </div>}

      {done && <div className="col-span-2">
        <div className="text-gray-700 py-2">
          To start using the Kid's dashboard, open <a href="https://kids.darescouts.com" target="_blank" className="text-blue-700">kids.darescouts.com</a> on the device you want to enable and use the following code when prompted.
          <div className="text-2xl m-6 p-2 border-2 border-dashed border-gray-400 text-center">{form.code}</div>
        </div>

        <div className="grid grid-cols-2 gap-2">
          <div className="">
            <Button
              size="extra-small"
              variant="actions-accept"
              disabled={sending}
              to="/"
            >
              {i18n._(t('AuthorizeKidsApp.done')`Done`)}
            </Button>
          </div>
        </div>
      </div>}

    </>
  );
};

export default AuthorizeKidsApp;
