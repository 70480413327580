import { createSlice } from '@reduxjs/toolkit';

const teams = createSlice({
  name: 'teams',
  initialState: {
    currentTeamId: null,
    currentTeam: {
      enabledActions: [],
    },
    Teams: [],
  },
  reducers: {
    setCurrentTeamId: ((state, action) => ({
      currentTeamId: action.payload,
      currentTeam: state.Teams.find(team => team.id === action.payload),
      Teams: state.Teams,
    })),

    setTeams: ((state, action) => ({
      currentTeamId: state.currentTeamId,
      currentTeam: state.currentTeam,
      Teams: action.payload,
    })),
  }
});

export const getTeams = (state) => state.teams;

export const getCurrentTeam = (state) => state.teams.currentTeam;

export const getCurrentTeamId = (state) => state.teams.currentTeamId;

export default teams;
