import React from 'react';
import { Link } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import { t } from '@lingui/macro';
import i18n from '../i18n';
import {useQuery} from 'urql';
import {LIST_TASK} from '@darescouts/api-graphql/tasks/tasksQueries';
import {LIST_KIDS} from '@darescouts/api-graphql/kids/kidsQueries';
import Button from '../components/Button';
import DashboardIcon from '@iconscout/react-unicons/icons/uil-schedule';
import AuthorizeIcon from '@iconscout/react-unicons/icons/uil-shield-check';
import UsersIcon from '@iconscout/react-unicons/icons/uil-users-alt';
import TasksIcon from '@iconscout/react-unicons/icons/uil-clipboard-notes';
import SignOut from '@iconscout/react-unicons/icons/uil-signout';
import SettingsIcon from '@iconscout/react-unicons/icons/uil-setting';

const Welcome = () => {
  const [tasks] = useQuery({
    query: LIST_TASK,
    requestPolicy: 'cache-and-network',
  });

  const [kids] = useQuery({
    query: LIST_KIDS,
    requestPolicy: 'cache-and-network',
  });

  if (tasks.fetching) return <div>Loading...</div>;
  if (tasks.error) return <div>Error loading: tasks<button className="bg-green-600 m-1 p-1 hover:bg-blue-500 rounded text-sm text-white" onClick={() => window.location.reload(true)}>
    Reload
  </button></div>;
  if (!tasks.data || !tasks.data.tasks) return <>Something is not ok, no tasks object<button className="bg-green-600 m-1 p-1 hover:bg-blue-500 rounded text-sm text-white" onClick={() => window.location.reload(true)}>
    Reload
  </button></>;

  if (kids.fetching) return <div>Loading...</div>;
  if (kids.error) return <div>Error loading: kids<button className="bg-green-600 m-1 p-1 hover:bg-blue-500 rounded text-sm text-white" onClick={() => window.location.reload(true)}>
    Reload
  </button></div>;
  if (!kids.data || !kids.data.kids) return <>Something is not ok, no kids object<button className="bg-green-600 m-1 p-1 hover:bg-blue-500 rounded text-sm text-white" onClick={() => window.location.reload(true)}>
    Reload
  </button></>;

  let step = 'done';
  if (!kids.data.kids.length) {
    step = 'kids';
  } else if (!tasks.data.tasks.length) {
    step = 'tasks';
  }

  if (step === 'done') {
    return <>
      <div className="pb-8">
        <h2 className="text-2xl pb-2">Congratulations!</h2>
        <p className="text-gray-700">
          You are ready to start using Dare Scouts.
        </p>
      </div>
      <div className="col-span-2">

        <div className="text-gray-700 py-2">
          Here some useful tips:
        </div>
        <div className="text-gray-700 py-2 mb-3">
          <DashboardIcon color="#2D3748" className="inline-block mb-1 mr-2" size={30} />
          <strong>{i18n._(t('links.dashboard')`Daily`)}</strong> allows you to access the list of tasks for any given day.
        </div>
        <div className="text-gray-700 py-2 mb-3">
          <AuthorizeIcon color="#2D3748" className="inline-block mb-1 mr-2" size={30} />
          Use the <strong>{i18n._(t('links.authorizeKidsApp')`Authorize`)}</strong> option to create an one time access code to setup the kids dashboard <a
          href="https://kids.darescouts.com" target="_blank" className="text-blue-700">kids.darescouts.com</a> on a device.
        </div>
        <div className="text-gray-700 py-2 mb-3">
          <TasksIcon color="#2D3748" className="inline-block mb-1 mr-2" size={30} />
          Schedule, Modify or remove tasks on the <strong>{i18n._(t('links.tasks.list')`Tasks`)}</strong> icon.
        </div>
        <div className="text-gray-700 py-2 mb-3">
          <SettingsIcon color="#2D3748" className="inline-block mb-1 mr-2" size={30} />
          Invite a partner and modify Kids avatars using the <strong>{i18n._(t('links.members.list')`Kids and Users`)}</strong> option inside <strong>{i18n._(t('links.settings')`Settings`)}</strong>.
        </div>
      </div>
    </>
  }

  return <>
    <div className="pb-8">
      <h2 className="text-2xl pb-2">Welcome to Dare Scouts</h2>
      <p className="text-gray-700">
        To start using Dare Scouts you need to configure the names of your kids and a first task.
      </p>
    </div>

    <div className="col-span-2">
      <div>
        {step === 'kids' && <div className="text-gray-700 py-2">
          Start by adding a kid. <br/>
          <br/>

          <div className="w-32">
            <Button
              size="extra-small"
              variant="primary"
              to="/members/new?type=kid&returnTo=%2Fwelcome"
            >
              Add a kid
            </Button>
          </div>
        </div>}

        {step !== 'kids' && <div className="text-gray-700 py-2">
          <h2 className="text-lg">
            Kids:
          </h2>
          <span className="ml-4">
              {kids.data.kids.map(kid => kid.name).join(', ')} <br/>
            </span>
          <br/>

          <div className="w-32">
            <Button
              size="extra-small"
              variant="primary"
              to="/members/new?type=kid&returnTo=%2Fwelcome"
            >
              Add another kid
            </Button>
          </div>
        </div>}


        {step === 'tasks' && <div className="text-gray-700 py-2">
          Almost done!, the next step is to add a task.<br/>
          <br/>
          <div className="w-32">
            <Button
              size="extra-small"
              variant="primary"
              to="/tasks/new?returnTo=%2Fwelcome"
            >
              Add a task
            </Button>
          </div>
        </div>}

      </div>

    </div>
  </>
};

export default Welcome;
