import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import {t} from '@lingui/macro';
import { useMutation, useQuery} from 'urql'

import i18n from '../i18n';

import {GET_NETWORK, LIST_NETWORK_RULES} from '@darescouts/api-graphql/networks/networksQueries'

import {useSelector} from 'react-redux';
import {getCurrentTeam} from '../store/slices/teamsSlice';

const NetworkRules = () => {

  const [network] = useQuery({
    query: GET_NETWORK,
    variables: { id: 'main' },
    requestPolicy: 'cache-and-network',
  });

  const [networkRules, refetchNetworkRules] = useQuery({
    query: LIST_NETWORK_RULES,
    requestPolicy: 'cache-and-network',
    pause: !(network && network.data && network.data.network),
    variables: { netId: 'main' },
  });

  const currentTeam = useSelector(getCurrentTeam);

  if (network.fetching) return <p>Loading network details...</p>;
  if (network.error) return <p>Error loading network settings: {network.error.message}<button className="bg-green-600 m-1 p-1 hover:bg-blue-500 rounded text-sm text-white" onClick={() => window.location.reload(true)}>
    Reload
  </button></p>;

  if (!network.data.network) {
    return <div>
      <h2 className="text-2xl pb-2">Network</h2>
      <p className="text-gray-700">
        To manage internet access for your home devices, you first need to configure a network.
      </p>
      <Link className="link" to="/net/settings/new">
        Click here to configure a network.
      </Link>
    </div>
  }

  if (networkRules.fetching) return <p>Loading rules...</p>;
  if (networkRules.error) return <p>Error loading list of rules ({networkRules.error.message}) <button className="bg-green-600 m-1 p-1 hover:bg-blue-500 rounded text-sm text-white" onClick={() => window.location.reload(true)}>
    Reload
  </button></p>;
  if (!networkRules.data || !networkRules.data.networkRules) return <p>No rules data ?<button className="bg-green-600 m-1 p-1 hover:bg-blue-500 rounded text-sm text-white" onClick={() => window.location.reload(true)}>
    Reload
  </button></p>;

  return (<>
    <div className="pb-8">
      <h2 className="text-2xl pb-2">Network Rules</h2>
      <p className="text-gray-700">
        This is a list of all rules configured for your network. <br/>
        <br/>
        <Link className="link" to="/net/rules">
          {i18n._(t('net.rules')`Rules`)}
        </Link>
        <br/>
        <Link className="link" to="/net/rules/new">
          {i18n._(t('net.newRule')`New Rule`)}
        </Link>

      </p>
    </div>
    <div className="col-span-2">
      {networkRules.data.networkRules.length === 0 && <div>
        No rules
      </div>}

      {networkRules.data.networkRules.map((networkRule) => {

        return (
          <div key={networkRule.id} className="pb-3 mb-6 border-b-2 border-gray-300">
            <div className="col-span-3">
              <div className="float-right">
                {networkRule.Devices.map((device) => {
                  return (
                    <div key={`reward-${networkRule.id}-${device.id}`} className="mr-1 text-xs inline-flex items-center px-3 py-1 bg-blue-200 text-blue-700 rounded-full">
                      <Link className="" to="#">
                        {device.name}
                      </Link>
                    </div>
                  )
                })}
              </div>
              <Link className="text-lg text-gray-800" to={`/net/rules/${networkRule.id}`}>
                {networkRule.name}
              </Link>
              <br/>
            </div>
          </div>
        )
      })}

    </div>
  </>);
};

export default NetworkRules;
