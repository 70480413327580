import {
  call,
  put,
  takeEvery,
} from 'redux-saga/effects';

import identityAPI from '../../identityAPI.js';

import userEditSlice from '../slices/userEditSlice';

function* load(action) {
  try {
    const data = yield call(identityAPI.loadUser, action.payload);
    yield put(userEditSlice.actions.success(data));
  } catch (error) {
    console.log('watchUserEditLoad failed to load user', error);
    yield put(userEditSlice.actions.error(error.message));
  }
}

export default function* watchUserEditLoad() {
  yield takeEvery(userEditSlice.actions.load, load);
}
