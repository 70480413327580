import React from 'react';
import {Link, Redirect, useParams} from 'react-router-dom'

import {GET_TASK} from '@darescouts/api-graphql/tasks/tasksQueries'
import {DONE_TASK_MANUAL, DELETE_TASK_LOG} from '@darescouts/api-graphql/tasks/tasksMutations'
import {useMutation, useQuery} from 'urql';
import Button from '../components/Button';
import { t } from '@lingui/macro';
import i18n from '../i18n';
import {LIST_KIDS} from '@darescouts/api-graphql/kids/kidsQueries';

const TaskDateView = () => {
  const { taskId, date } = useParams();
  const [doneTaskManualResult, doneTaskManual] = useMutation(DONE_TASK_MANUAL);
  const [deleteAchievementResult, deleteAchievement] = useMutation(DELETE_TASK_LOG);
  const selectedDateSplitted = date.split('-');
  const selectedDateTime = new Date(selectedDateSplitted[0], selectedDateSplitted[1] - 1, selectedDateSplitted[2]);

  const dateFormatOptions = new Intl.DateTimeFormat('en-au', {
    dateStyle: 'short',
  });

  const dateTimeFormatOptions = new Intl.DateTimeFormat('en-au', {
    dateStyle: 'short',
    timeStyle: 'short',
  });

  let displaySelectedDate = dateFormatOptions.format(selectedDateTime);

  const [task, refetchTask] = useQuery({
    query: GET_TASK,
    variables: {
      id: taskId,
      date: date,
    },
    requestPolicy: 'cache-and-network',
  });

  const [listKidsQuery] = useQuery({
    query: LIST_KIDS,
    requestPolicy: 'cache-and-network',
  });

  const handleRemoveAchievement = async (AchievementId) => {
    deleteAchievement({
      id: AchievementId,
    }).then(result => {
      console.log('AchievementId removed', result);
      refetchTask({ requestPolicy: 'network-only' });
    });
  };

  const handleManualFinishKid = async (kidId) => {
    doneTaskManual({
      taskId: taskId,
      doneById: kidId,
      dateAt: `${date} 15:14`,
    }).then(result => {
      console.log('manual entry', result);
      refetchTask({ requestPolicy: 'network-only' });
    });
  };

  if (task.fetching) return <p>Loading task...</p>;
  if (task.error) return <p>Error loading task: {task.error.message}<button className="bg-green-600 m-1 p-1 hover:bg-blue-500 rounded text-sm text-white" onClick={() => window.location.reload(true)}>
    Reload
  </button></p>;
  if (!task.data || !task.data.task) return <p>No task data ?</p>;

  const doneKids = task.data.task.log.map(Achievement => Achievement.doneBy.id);

  // of list of kids is available, calculate pending ones
  const pendingKids = listKidsQuery.data && listKidsQuery.data.kids ? listKidsQuery.data.kids.filter(kid => !doneKids.includes(kid.id)) : [];

  const pointsTypes = [
    { id: 'S', name: 'Silver' },
    { id: 'G', name: 'Gold' },
    { id: 'D', name: 'Diamond' },
  ];

  return (<>
    <div className="pb-8">
      <h2 className="text-2xl pb-2">{task.data.task.title}</h2>
      <p className="text-gray-700">
        Manage who completed a task on {displaySelectedDate}<br/>
      </p>
    </div>
    <div className="col-span-2">
      <h2 className=" ">Runs:</h2>
      <div className="text-gray-700 py-2">
        {task.data.task.scheduleMode === 'Once' && <div>
          Once on {task.data.task.when.Once.at}
        </div>}
        {task.data.task.scheduleMode === 'Daily' && <div>
          Daily at {task.data.task.when.Daily.at}
        </div>}
        {task.data.task.scheduleMode === 'Weekly' && <div>
          Every: <br/> {[
          { id: 'mon', name: i18n._(t('days.mon')`Monday`) },
          { id: 'tue', name: i18n._(t('days.tue')`Tuesday`) },
          { id: 'wed', name: i18n._(t('days.wed')`Wednesday`) },
          { id: 'thu', name: i18n._(t('days.thu')`Thursday`) },
          { id: 'fri', name: i18n._(t('days.fri')`Friday`) },
          { id: 'sat', name: i18n._(t('days.sat')`Saturday`) },
          { id: 'sun', name: i18n._(t('days.sun')`Sunday`) },
        ].filter(day => task.data.task.when.Weekly[day.id] && task.data.task.when.Weekly[day.id].at).map(day => {

          return <div key={`day-${day.id}`}>{day.name} at {task.data.task.when.Weekly[day.id].at}</div>
        })}
        </div>}
      </div>

      <h2 className=" ">Points:</h2>
      <span className="text-sm">
                {pointsTypes.find(l => l.id === task.data.task.pointsType).name}
        </span>

      <div>
        <h2 className="text-xl mt-4">{displaySelectedDate}:</h2>
        <h3 className="text-gray-700">Completed by:</h3>
        {task.data.task.log.map((Achievement) => {
          const doneAt = new Date(Achievement.achievedAt);
          return (
            <div key={Achievement.id} className="flex flex-wrap mt-4 mb-4">
              <div className="mt-1">
                <span className=" font-bold">
                  {Achievement.doneBy.name}
                </span> {dateTimeFormatOptions.format(doneAt)}
              </div>
              <div key={`log-${Achievement.id}`} className="block ml-2">
                <Button
                  size="extra-small"
                  variant="danger"
                  onClick={() => handleRemoveAchievement(Achievement.id)}
                >
                  Remove
                </Button>
              </div>

            </div>
          )
        })}
      </div>

      {pendingKids.length > 0 && <div>
        <h2 className="text-xl mt-4">Manual entry</h2>
        <h3 className="text-gray-700">Select who else completed the task</h3>
        {listKidsQuery.fetching &&<div className="">loading kids</div>}
        {listKidsQuery.error &&<div className="">{listKidsQuery.error.message}</div>}

        <div className="flex flex-wrap">
          {pendingKids.map(({ id, name }) => (
            <div key={`kid-${id}`} className="py-2 m-1">
              <Button
                size="extra-small"
                variant="primary"
                onClick={() => handleManualFinishKid(id)}
              >
                {name}
              </Button>
            </div>
          ))}
        </div>

      </div>}

      <br/><br/>
      <div className="grid grid-cols-2 gap-2 py-2">
        <div className="">
          <Button
            size="extra-small"
            variant="actions-accept"
            to={`/tasks/byDate/${date}`}
          >
            {i18n._(t('TaskDateView.done')`Done`)}
          </Button>
        </div>
      </div>
    </div>
  </>)
};

export default TaskDateView;
