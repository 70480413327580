import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import darescouts from '../images/darescouts.svg';
import {appName} from '../config';

const TopNav = () => {
  return (
    <nav className="bg-blue-400 p-2 mt-0 w-full">
      <div className="container mx-auto items-center">
        <div className="flex w-full justify-center text-white font-extrabold">
          <a className="italic no-underline hover:no-underline" href="/">
                <span className="inline-block text-md">
                      <img className="mt-2 mb-2" src={darescouts} width="144" alt={appName} />
                </span>
          </a>
        </div>

      </div>
    </nav>
  )
}

export default TopNav;
