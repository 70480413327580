
module.exports.LIST_REWARDS = `query rewards {
  rewards {
    id
    title
    icon
    status
    availableHours
    pointsType
    points
    who
  }
}`;

module.exports.GET_REWARD = `
  query 
    (
      $id: String!
    ) {
  reward (
    id: $id
  ) {
    id
    title
    description
    icon
    status
    availableHours
    webhookStart
    duration
    webhookEnd
    pointsType
    points
    who
    availableSince
    availableUntil
  }
}`;
