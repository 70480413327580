import React, {useEffect, useRef, useState} from 'react';
import {Link, Redirect, useParams} from 'react-router-dom'

import Button from '../components/Button';
import { t } from '@lingui/macro';
import i18n from '../i18n';
import memberUserEditSlice, { getMemberUserEdit } from '../store/slices/memberUserEditSlice';
import {useDispatch, useSelector} from 'react-redux';
import identityAPI from '../identityAPI';
import { identityServer } from '../config';
import membersSlice from '../store/slices/membersSlice';
import {getTeams} from '../store/slices/teamsSlice';

const MemberEditView = () => {
  const firstInput = useRef(null);
  const { memberId } = useParams();
  const dispatch = useDispatch();
  const user = useSelector(getMemberUserEdit);
  const teams = useSelector(getTeams);

  const avatars = [
    '145864',
    '145866',
    '163801',
    '163807',
    '163809',
    '163811',
    '163812',
    '163816',
    '163823',
    '163824',
    '163828',
    '163830',
    '163834',
    'avatar',
  ];

  const [sending, setSending] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [done, setDone] = useState(false);
  const [originalLoadedForm, setOriginalLoadedForm] = useState('');

  const [form, setValues] = useState({
    id: '',
    name: '',
  });

  const [error, setError] = useState(null);

  useEffect(() => {
    if (firstInput.current) {
      firstInput.current.focus();
    }
  }, []);

  useEffect(() => {
    if (!user || user.isLoading) return;
    const initialLoadedForm = {
      id: user.id,
      name: user.name,
      avatar: user.avatar || 'avatar',
    };

    setOriginalLoadedForm(JSON.stringify(initialLoadedForm));
    setValues(initialLoadedForm);

  }, [user]);

  useEffect(() => {
    if (!teams.currentTeamId || !memberId) { return }
    dispatch(memberUserEditSlice.actions.load({ teamId: teams.currentTeamId, memberId: memberId}));
  }, [memberId, teams]);

  const updateField = async (e, target, value) => {
    if (e) {
      e.preventDefault();
    }
    const updatedForm = {
      ...form,
      [target || e.target.name]: value || e.target.value,
    }

    let updated = JSON.stringify(updatedForm) !== originalLoadedForm

    if (updated && updatedForm.password !== updatedForm.passwordConfirmation) {
      updated = false;
    }

    setValues(updatedForm);
    setUpdated(updated);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setSending(true);
    try {
      await identityAPI.updateMemberUser({
        teamId: teams.currentTeamId,
        memberId: memberId,
        user: form,
      });
      dispatch(membersSlice.actions.load(teams.currentTeamId));
      setSending(false);
      setDone(true);
    } catch (apiError) {
      console.log(apiError);
      setError(apiError.message);
      setSending(false);
    }
  };

  if (done) {
    return <Redirect to="/members" />;
  }

  if (!form.id) {
    return (<div>Loading</div>);
  }
  return (<>
    <div className="pb-8">
      <h2 className="text-2xl pb-2">Member Details</h2>
      <p className="text-gray-700">
        You can only update details from members you created.
      </p>
    </div>
    <div className="col-span-2">
      {user.error}
      {error}

      <div className="">
        <div className="text-gray-700 py-2 mt-3">
          Name or nickname
        </div>
        <input
          ref={firstInput}
          className="input"
          type="text"
          placeholder={i18n._(t('profile.fullName')`Full Name`)}
          name="name"
          value={form.name}
          disabled={sending}
          onChange={updateField}
        />
      </div>
      <div className="">
        {avatars.map(avatar => {
          return <div key={`avatar-${avatar}`}
                      onClick={(e) => updateField(null, 'avatar', avatar)}
                      className={`${avatar === form.avatar ? 'border-blue-300' : 'border-gray-300'} cursor-pointer w-16 float-left m-1 border-2`}>
            <img
              src={`${identityServer}/avatars/${avatar}.svg`}
              className="p-2 "
              alt=""
            />
          </div>
        })}
      </div>
      <div className="clear-both grid grid-cols-2 gap-2 py-2">
        <div className="">
          <Button
            size="extra-small"
            variant="actions-cancel"
            disabled={sending}
            to="/members"
          >
            {i18n._(t('MembersEdit.cancel')`Cancel`)}
          </Button>
        </div>
        <div className="">
          <Button
            size="extra-small"
            variant="actions-accept"
            disabled={!updated || sending}
            onClick={handleUpdate}
          >
            {i18n._(t('MembersEdit.update')`Update`)}
          </Button>
        </div>
      </div>

    </div>
  </>)
};

export default MemberEditView;
