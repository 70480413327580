import { createSlice } from '@reduxjs/toolkit';

const user = createSlice({
  name: 'user',
  initialState: {
    isLoading: false,
    error: null,
    name: null,
    id: null,
    enabledActions: [],
    timezone: 'UTC',
  },
  reducers: {
    load: ((state, action) => ({
      isLoading: true,
      error: null,
      name: null,
      id: null,
      enabledActions: [],
      timezone: 'UTC',
    })),
    success: ((state, action) => ({
      isLoading: false,
      error: null,
      name: action.payload.name,
      id: action.payload.id,
      enabledActions: action.payload.enabledActions,
      timezone: action.payload.timezone,
    })),
    error: ((state, action) => ({
      isLoading: false,
      error: action.payload,
      name: null,
      id: null,
      enabledActions: [],
      timezone: 'UTC',
    })),
  }
});

export const getUser = (state) => state.user;

export default user;
