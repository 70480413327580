import React from 'react';
import { Link } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import { t } from '@lingui/macro';
import i18n from '../i18n';
import teamIcon from '../images/team.svg';

import {useQuery} from 'urql';
import {LIST_KIDS} from '@darescouts/api-graphql/kids/kidsQueries';
import {identityServer} from '../config';
import darescouts from '@darescouts/kids/src/images/darescouts.svg';
import {appName} from '@darescouts/kids/src/config';
import Button from '../components/Button';
import PointType from '../components/PointType';

const Stats = () => {

  const [kids] = useQuery({
    query: LIST_KIDS,
    requestPolicy: 'cache-and-network',
  });

  if (kids.fetching) return <div>Loading...</div>;
  if (kids.error) return <div>Error loading: kids <button className="bg-green-600 m-1 p-1 hover:bg-blue-500 rounded text-sm text-white" onClick={() => window.location.reload(true)}>
    Reload
  </button></div>;
  if (!kids.data || !kids.data.kids) return <>Something is not ok, no kids object</>;

  return <>
    <div className="pb-8">
      <h2 className="text-2xl pb-2">Stats</h2>
      <p className="text-gray-700">
        Let's see some numbers.
      </p>
    </div>
    <div className="col-span-2">
      {kids.data.kids.map((kid) => {
        return (<div key={kid.id} className="flex p-3 pb-6">
          <div className="w-1/6">
            <img
              src={`${identityServer}/avatars/${kid.avatar ? kid.avatar : 'avatar'}.svg`}
              className=" ml-auto mr-auto"
              alt={kid.name}
              width="w-full"
            />
          </div>
          <div className="text-left">
            <span className="text-lg">
              {kid.name}
            </span> <br/>
            {!kid.totalPoints && <span className="text-sm">
              <strong>{kid.totalPoints}</strong> points. <br/>
            </span>}
            <div className="flex flex-wrap">
              {kid.totalPointsByType.filter( p=> p.points).map((points) => {
                return (
                  <div key={`points-${points.pointsType}`} className="py-2 m-1 pr-2">
                    {points.points} x <PointType type={points.pointsType} size={24} />
                  </div>
                )
              })}
            </div>
            <div className="w-12 pt-1">
              <Button
                size="extra-extra-small"
                variant="primary"
                disabled={!kid.totalPoints}
                to={`/rewards_give/reward/${kid.id}?returnTo=/stats`}
              >
                {i18n._(t('stats.spend')`Spend`)}
              </Button>
            </div>
          </div>
        </div>)
      })}
    </div>
  </>
};

export default Stats;
