import {
  call,
  put,
  takeEvery,
} from 'redux-saga/effects';

import identityAPI from '../../identityAPI.js';

import teamsSlice from '../slices/teamsSlice';
import membersSlice from '../slices/membersSlice';

function* setCurrentTeamId(action) {
  try {
    const data = yield call(identityAPI.listMembers, { teamId: action.payload });
    yield put(membersSlice.actions.success(data));
  } catch (error) {
    yield put(membersSlice.actions.error(error.message));
  }
}

export default function* watchSetCurrentTeamId() {
  yield takeEvery(teamsSlice.actions.setCurrentTeamId, setCurrentTeamId);
}
