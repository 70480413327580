import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { t } from '@lingui/macro';

import i18n from '../i18n';

import { getTeams } from '../store/slices/teamsSlice';
import membersSlice from '../store/slices/membersSlice';
import identityAPI from '../identityAPI';
import Button from '../components/Button';
import { generateRandomString } from '../utils';

const MembersNew = () => {
  const dispatch = useDispatch();
  const teams = useSelector(getTeams);
  const firstInput = useRef(null);
  const type = new URLSearchParams(useLocation().search).get('type');
  const returnTo = new URLSearchParams(useLocation().search).get('returnTo');

  const [sending, setSending] = useState(false);
  const [userType, setUserType] = useState(type ? type : null);
  const [ready, setReady] = useState(false);
  const [error, setError] = useState(false);
  const [done, setDone] = useState(false);

  const [form, setValues] = useState({
    name: '',
    email: '',
    password: '',
  });

  useEffect(() => {
    if (firstInput.current) {
      firstInput.current.focus();
    }
  }, []);

  useEffect(() => {
    if (
      userType === 'kid'
      && form.name
    ) {
      setReady(true);
    } else if (
      userType === 'parent'
      && form.name
      && form.email
    ) {
      setReady(true);
    } else {
      setReady(false);
    }
  }, [userType, form]);

  const updateField = e => {
    e.preventDefault();
    setValues({
      ...form,
      [e.target.name]: e.target.value
    });
  };

  const handleCreate = async (e) => {
    e.preventDefault();
    setSending(true);
    try {

      // kids accounts are directly created
      if (userType === 'kid') {
        const user = {
          roleCode: userType,
          name: form.name,
          email: `${generateRandomString(24)}@darescouts.com`,
          password: generateRandomString(32),
          teamId: teams.currentTeamId,
        };

        user.passwordConfirmation = user.password;
        await identityAPI.createUserAndJoin(user);
      }

      if (userType === 'parent') {
        const invitation = {
          roleCode: userType,
          teamId: teams.currentTeamId,
          name: form.name,
          email: form.email,
        };
        await identityAPI.invite(invitation);
      }

      // parent's accounts are invitations

      setSending(false);
      setDone(true);
      dispatch(membersSlice.actions.load(teams.currentTeamId));
    } catch (apiError) {
      console.log(apiError);
      setError(apiError.message);
      setSending(false);
    }
  };

  let title = i18n._(t('membersNew.new')`Add a new member`);
  if (userType === 'kid') {
    title = i18n._(t('membersNew.newKid')`Add a kid`);
  } else if (userType === 'parent') {
    title = i18n._(t('membersNew.newParent')`Invite a partner`);
  }

  return (
    <>
      <div className="pb-4">
        <h2 className="text-2xl pb-2">{title}</h2>
      </div>
      <div className="col-span-2">

        {!done && <>
          {!userType && <>
            <div className="text-gray-700 py-2">
              Select the type of member to add
            </div>
            <div className="grid grid-cols-2 gap-2">
              <div className="">
                <Button
                  size="extra-small"
                  variant="primary"
                  disabled={sending}
                  onClick={() => setUserType('parent')}
                >
                  {i18n._(t('membersNew.parentType')`Parent`)}
                </Button>
              </div>
              <div className="">
                <Button
                  size="extra-small"
                  variant="primary"
                  disabled={sending}
                  onClick={() => setUserType('kid')}
                >
                  {i18n._(t('membersNew.kidType')`Kid`)}
                </Button>
              </div>
            </div>
          </>}

          {(userType === 'parent') && <>
            <div className="text-gray-700 py-2">
              Adults are required to manage their own accounts. <br/>
            </div>
            <div className="text-gray-700 py-2 mb-3">
              This means that you need to provide a valid email address to where an invitation will be sent.
            </div>
            <div className="text-gray-700 py-2 mb-3">
              Then, it is up to the recipient to follow the instructions and join you at Dare Scouts.
            </div>
            <div className="">
              <input
                ref={firstInput}
                className="input"
                type="text"
                placeholder={i18n._(t('membersNew.fullName')`Full Name`)}
                name="name"
                value={form.name}
                onChange={updateField}
              />

              <input
                className="input"
                type="email"
                placeholder={i18n._(t('membersNew.email')`Email`)}
                name="email"
                value={form.email}
                onChange={updateField}
              />

            </div>
          </>}

          {(userType === 'kid') && <>
            <div className="text-gray-700 py-2">
              You are adding a <span className="text-black">Kid</span>, feel free to use a first name, short name or nick name here.
            </div>
            <div className="">
              <input
                ref={firstInput}
                className="input"
                type="text"
                placeholder={i18n._(t('membersNew.fullName')`Name or nickname`)}
                name="name"
                value={form.name}
                onChange={updateField}
              />

            </div>
          </>}

          {error && <p className="text-xs italic text-red-500">{error}</p>}


          <div className="grid grid-cols-2 gap-2 py-2">
            <div className="">
              <Button
                size="extra-small"
                variant="actions-cancel"
                disabled={sending}
                to={returnTo ? `${returnTo}` : '/members'}
              >
                {i18n._(t('membersNew.cancel')`Cancel`)}
              </Button>
            </div>
            <div className="">
              <Button
                size="extra-small"
                variant="actions-accept"
                disabled={!ready || sending}
                onClick={handleCreate}
              >
                {userType === 'kid' ? i18n._(t('membersNew.create')`Create`) : i18n._(t('membersNew.invite')`Invite`)}
              </Button>
            </div>
          </div>
        </>}

        {done && <>
          <div className="text-gray-700 py-2">
            {userType === 'kid' ? i18n._(t('membersNew.created')`New user successfully created`) : i18n._(t('membersNew.invited')`Invitation sent`)}
          </div>

          <div className="grid grid-cols-2 gap-2">
            <div className="">
              <Button
                size="extra-small"
                variant="actions-accept"
                disabled={sending}
                to={returnTo ? `${returnTo}` : '/members'}
              >
                {i18n._(t('membersNew.done')`Done`)}
              </Button>
            </div>
          </div>
        </>}

      </div>

    </>
  );
};

export default MembersNew;
