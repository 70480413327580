import createSagaMiddleware from 'redux-saga';

import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

import authSlice from './slices/authSlice';
import userSlice from './slices/userSlice';
import userEditSlice from './slices/userEditSlice';
import teamsSlice from './slices/teamsSlice';
import membersSlice from './slices/membersSlice';
import memberUserEditSlice from './slices/memberUserEditSlice';

import mySagas from './sagas/root';

const sagaMiddleware = createSagaMiddleware();

const middleware = [
  ...getDefaultMiddleware({
    // serializableCheck: false,    // disable check for redux persist
  }),
  sagaMiddleware,
];

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    user: userSlice.reducer,
    userEdit: userEditSlice.reducer,
    teams: teamsSlice.reducer,
    members: membersSlice.reducer,
    memberUserEdit: memberUserEditSlice.reducer,
  },
  middleware,
});

sagaMiddleware.run(mySagas);

export default store;
