import React, {useEffect, useRef, useState} from 'react';

import userEditSlice, { getUserEdit } from '../store/slices/userEditSlice';
import userSlice from '../store/slices/userSlice';
import {useDispatch, useSelector} from 'react-redux';
import identityAPI from '../identityAPI';
import i18n from '../i18n';
import {t} from '@lingui/macro';
import Button from '../components/Button';
import membersSlice from '../store/slices/membersSlice';

const UserProfile = () => {
  const firstInput = useRef(null);
  const dispatch = useDispatch();
  const user = useSelector(getUserEdit);
  const [sending, setSending] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [originalLoadedForm, setOriginalLoadedForm] = useState('');

  const [form, setValues] = useState({});

  const [error, setError] = useState(null);

  useEffect(() => {
    if (firstInput.current) {
      firstInput.current.focus();
    }
  }, []);

  useEffect(() => {
    if (!user || user.isLoading) return;
    const initialLoadedForm = {
      id: user.id,
      name: user.name,
      email: user.email,
      password: '',
      passwordConfirmation: '',
    };

    setOriginalLoadedForm(JSON.stringify(initialLoadedForm));
    setValues(initialLoadedForm);
  }, [user]);

  useEffect(() => {
    dispatch(userEditSlice.actions.load('me'));
  }, []);

  const updateField = e => {
    e.preventDefault();
    const updatedForm = {
      ...form,
      [e.target.name]: e.target.value,
    }

    let updated = JSON.stringify(updatedForm) !== originalLoadedForm

    if (updated && updatedForm.password !== updatedForm.passwordConfirmation) {
      updated = false;
    }

    setValues(updatedForm);
    setUpdated(updated);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setSending(true);
    try {
      await identityAPI.updateUser(form);
      dispatch(userSlice.actions.load('me'));
      setSending(false);
    } catch (apiError) {
      console.log(apiError);
      setError(apiError.message);
      setSending(false);
    }
  };

  if (!form.id) {
    return (<div>Loading</div>);
  }
  return (<>
    <div className="pb-8">
      <h2 className="text-2xl pb-2">My Profile</h2>
      <p className="text-gray-700">
        Modify your own details.
      </p>
    </div>
    <div className="col-span-2">
      {user.error}
      {error}


      <div className="">
        <div className="text-gray-700 py-2 mt-3">
          Full name
        </div>
        <input
          ref={firstInput}
          className="input"
          type="text"
          placeholder={i18n._(t('profile.fullName')`Full Name`)}
          name="name"
          value={form.name}
          disabled={sending}
          onChange={updateField}
        />

        <div className="text-gray-700 py-2 mt-3">
          Email address
        </div>
        <input
          className="input"
          type="email"
          disabled={true}
          readOnly={true}
          placeholder={i18n._(t('profile.email')`Email`)}
          name="email"
          value={form.email}
        />

        <div className="text-gray-700 py-2 mt-3">
          Password
        </div>

        <input
          className="input"
          name="password"
          type="password"
          placeholder="********"
          value={form.password}
          disabled={sending}
          onChange={updateField}
        />

        <div className="text-gray-700 py-2 mt-3">
          Re type password
        </div>

        <input
          className="input"
          name="passwordConfirmation"
          type="password"
          placeholder="********"
          disabled={sending}
          value={form.passwordConfirmation}
          onChange={updateField}
        />

      </div>

      <div className="grid grid-cols-2 gap-2 py-2">
        <div className="">
          <Button
            size="extra-small"
            variant="actions-cancel"
            disabled={sending}
            to="/settings"
          >
            {i18n._(t('profile.cancel')`Cancel`)}
          </Button>
        </div>
        <div className="">
          <Button
            size="extra-small"
            variant="actions-accept"
            disabled={!updated || sending}
            onClick={handleUpdate}
          >
            {i18n._(t('profile.update')`Update`)}
          </Button>
        </div>
      </div>

    </div>
  </>)
};

export default UserProfile;
