import React from 'react';
import {Redirect} from 'react-router-dom';
import { useQuery} from 'urql'

import { LIST_TASK } from '@darescouts/api-graphql/tasks/tasksQueries'
import { LIST_KIDS } from '@darescouts/api-graphql/kids/kidsQueries'

const NewAccountRedirector = () => {
  const [tasks] = useQuery({
    query: LIST_TASK,
    requestPolicy: 'cache-and-network',
  });

  const [kids] = useQuery({
    query: LIST_KIDS,
    requestPolicy: 'cache-and-network',
  });

  if (tasks.fetching) return <></>;
  if (tasks.error) return <></>;
  if (!tasks.data || !tasks.data.tasks) return <></>;

  if (kids.fetching) return <></>;
  if (kids.error) return <></>;
  if (!kids.data || !kids.data.kids) return <></>;

  if (
    !kids.data.kids || !kids.data.kids.length ||
    !tasks.data.tasks || !tasks.data.tasks.length
  ) {
    return <Redirect to='/welcome' />;
  }

  return (<></>);
};

export default NewAccountRedirector;
