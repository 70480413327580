import { createSlice } from '@reduxjs/toolkit';

const userEdit = createSlice({
  name: 'userEdit',
  initialState: {
    isLoading: false,
    error: null,
    name: null,
    email: null,
    id: null,
  },
  reducers: {
    load: ((state, action) => ({
      isLoading: true,
      error: null,
      name: null,
      email: null,
      id: null,
    })),
    success: ((state, action) => ({
      isLoading: false,
      error: null,
      name: action.payload.name,
      email: action.payload.email,
      id: action.payload.id,
    })),
    error: ((state, action) => ({
      isLoading: false,
      error: action.payload,
      name: null,
      email: null,
      id: null,
    })),
  }
});

export const getUserEdit = (state) => state.userEdit;

export default userEdit;
