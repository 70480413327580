import version from './version.json';

// this app id
export const clientId = '32a14232-306d-4561-a06c-4be0294f71b9';

// this UI version
export const appName = 'Dare Scouts';
export const appVersion = version.version;
export const appUrl = process.env.APP_URL || 'http://kids.darescouts.test:8081';
export const appHost = process.env.APP_HOST || 'kids.darescouts.test';

// what api service is the one we expect to use by default
// and what versions this UI expects
export const expectedService = {
  id: 'api',
  acceptVersion: '0.1.1', // what version of the API we know to use, used in calls to allow the api server to reject us if this UI is incompatible
};

// how many seconds before expiration
export const refreshTokenBefore = 20 * 60;

export const identityServer = process.env.IDENTITY_SERVER || 'http://localhost:3000';
