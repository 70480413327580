import regeneratorRuntime from "regenerator-runtime";
import "core-js/stable";

import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';

import App from './App';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({dsn: "https://651ff1565713499388b9a2f2274d0255@sentry.voctopus.nz/3"});
}
const Root = (
  <App />
);

ReactDOM.render(
  Root,
  document.getElementById('mainApp'),
);
