import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import { t } from '@lingui/macro';
import i18n from '../i18n';

import authSlice, { getAuth } from '../store/slices/authSlice';

import identityAPI from '../identityAPI.js';

const Recover = () => {
  const auth = useSelector(getAuth);

  const [email, setEmail] = useState(process.env.NODE_ENV === 'development' ? 'cristianszwarc@gmail.com' : '');
  const [error, setError] = useState('');
  const [sending, setSending] = useState(false);
  const [done, setDone] = useState(false);

  const handleRecover = async (e) => {
    e.preventDefault();
    setSending(true);
    setError('');
    try {
      await identityAPI.recover({ email });
      setDone(true);
    } catch (apiError) {
      console.log(apiError);
      setError(apiError.message);
      setSending(false);
    }
  };

  // we are logged in
  if (auth.accessToken) {
    return <Redirect to="/" />;
  }

  if (done) {
    return (
      <>
        <div className="pb-8 col-span-3">
          <h2 className="text-2xl pb-2">Password Reset</h2>
          <p className="text-gray-700">
            Please check your email for the link to reset your password. <br/>
          </p>
          <div className="text-center mt-4">
            <Link className="link" to="/signIn">
              {i18n._(t('signIn.signIn')`Sign In`)}
            </Link>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <div className="pb-8">
        <h2 className="text-2xl pb-2">Password Reset</h2>
        <p className="text-gray-700">
          Enter your email address and we will send you a link to reset your password.
        </p>
      </div>

      <div className="col-span-2">
        <form className="w-full">
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="username">
                E-mail
              </label>
              <input
                className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="username"
                type="email"
                placeholder="your@email.com"
                value={email}
                disabled={sending}
                onChange={e => setEmail(e.target.value)}
              />
              <p className="text-gray-600 text-xs italic"></p>
            </div>
          </div>

          <div className="md:flex md:items-center">
            <div className="md:w-1/3">
              <button
                className="hover:bg-blue-500 shadow bg-blue-400 hover:bg-blue-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                type="button"
                disabled={sending}
                onClick={handleRecover}
              >
                Submit
              </button>
              {error && <p className="text-red-500 text-xl italic float-right">{JSON.stringify(error)}</p>}
            </div>
          </div>

          <div className="text-center mt-4">
            <Link className="link" to="/signIn">
              {i18n._(t('signIn.signIn')`Sign In`)}
            </Link>
          </div>
        </form>
      </div>
    </>
  );
};

export default Recover;
