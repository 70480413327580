
// export const LIST_KIDS = `query kids {
module.exports.LIST_KIDS = `query kids ($andTeamUser: Boolean) {
  kids (andTeamUser: $andTeamUser) {
    id
    name
    avatar
    totalPoints
    totalPointsByType { pointsType, points }
    pointsLog { createdAt, pointsType, points, comments }
  }
}`;

module.exports.GET_KID = `query ($id: String!) {
  kid (id: $id) {
    id
    name   
    avatar 
    totalPoints
    totalPointsByType { pointsType, points }
    pointsLog {
      id
      createdAt
      comments
      points
    }
  }
}`;
