import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {
  Switch,
  Route,
  Redirect,
  BrowserRouter as Router, // BrowserRouter / MemoryRouter
} from 'react-router-dom';

import AppLayoutPrivate from './AppLayoutPrivate';
import AppLayoutPublic from './AppLayoutPublic';

import SignIn from './pages/SignIn';
import Recover from './pages/Recover';
import SwitchTeam from './pages/SwitchTeam';
import OAuth2start from './pages/OAuth2start';
import OAuth2continue from './pages/OAuth2continue';
import TaskDateView from './pages/TaskDateView';

import Dashboard from './pages/Dashboard';

import Members from './pages/Members';
import MembersNew from './pages/MembersNew';
import MembersEdit from './pages/MembersEdit';

import UserProfile from './pages/UserProfile';

import Tasks from './pages/Tasks';
import TaskEditor from './pages/TaskEditor';

import Rewards from './pages/Rewards';
import RewardEditor from './pages/RewardEditor';
import RewardGive from './pages/RewardGive';

import NetworkSettings from './pages/NetworkSettings';
import NetworkSettingsNew from './pages/NetworkSettingsNew';
import NetworkDevices from './pages/NetworkDevices';
import NetworkDeviceEdit from './pages/NetworkDeviceEdit';
import NetworkRules from './pages/NetworkRules';
import NetworkRuleEdit from './pages/NetworkRuleEdit';

import AuthorizeKidsApp from './pages/AuthorizeKidsApp';

import NotFound from './pages/NotFound';
import Welcome from './pages/Welcome';
import Stats from './pages/Stats';
import Settings from './pages/Settings';
import { getAuth } from './store/slices/authSlice';
import authSlice from './store/slices/authSlice';
import identityAPI from './identityAPI';

const PrivateRoute = ({component, ...options }) => {
  const auth = useSelector(getAuth);
  const dispatch = useDispatch();

  // tokens are not yet loaded into memory
  // give it a try to see if we have previous tokens
  if (!auth.loaded && !auth.loading) {
    (async () => {
      console.log('PrivateRoute dispatch tokensLoading');
      dispatch(authSlice.actions.tokensLoading());
      await identityAPI.loadTokens();
      dispatch(authSlice.actions.tokensLoaded());
    })();
  }

  // tokens are being loaded .
  if (!auth.loaded || auth.loading) {
    console.log('AppRouter loading tokens');
    return <div>Loading tokens...</div>;
  }

  if (!auth.accessToken) {
    console.log('PrivateRoute no tokens');
    return <Redirect to='/signIn' />;
  }

  // require team unless allowed to continue without one
  if (!options.allowNoTeam && !identityAPI.getCurrentTeamId()) {
    console.log('PrivateRoute no selected team');
    return <Redirect to='/switch-team' />;
  }

  return <Route {...options} component={component} />;
};

const AppRouter = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/signIn" component={() => (<AppLayoutPublic><SignIn /></AppLayoutPublic>)} />
        <Route exact path="/recover" component={() => (<AppLayoutPublic><Recover /></AppLayoutPublic>)} />
        <Route exact path="/account/oauth-start" component={() => (<AppLayoutPublic><OAuth2start /></AppLayoutPublic>)} />
        <Route exact path="/oauth2continue" component={() => (<AppLayoutPublic><OAuth2continue /></AppLayoutPublic>)} />

        <PrivateRoute allowNoTeam={true} exact path="/switch-team" component={() => (<AppLayoutPublic><SwitchTeam /></AppLayoutPublic>)} />

        <PrivateRoute exact path="/" component={() => (<AppLayoutPrivate><Dashboard /></AppLayoutPrivate>)} />
        <PrivateRoute exact path="/welcome" component={() => (<AppLayoutPrivate><Welcome /></AppLayoutPrivate>)} />
        <PrivateRoute exact path="/stats" component={() => (<AppLayoutPrivate><Stats /></AppLayoutPrivate>)} />
        <PrivateRoute exact path="/settings" component={() => (<AppLayoutPrivate><Settings /></AppLayoutPrivate>)} />
        <PrivateRoute exact path="/profile" component={() => (<AppLayoutPrivate><UserProfile /></AppLayoutPrivate>)} />

        <PrivateRoute exact path="/members" component={() => (<AppLayoutPrivate><Members /></AppLayoutPrivate>)} />
        <PrivateRoute exact path="/members/new" component={() => (<AppLayoutPrivate><MembersNew /></AppLayoutPrivate>)} />
        <PrivateRoute exact path="/members/:memberId" component={() => (<AppLayoutPrivate><MembersEdit /></AppLayoutPrivate>)} />

        <PrivateRoute exact path="/tasks" component={() => (<AppLayoutPrivate><Tasks /></AppLayoutPrivate>)} />
        <PrivateRoute exact path="/tasks/new" component={() => (<AppLayoutPrivate><TaskEditor /></AppLayoutPrivate>)} />
        <PrivateRoute exact path="/tasks/byDate/:date" component={() => (<AppLayoutPrivate><Dashboard /></AppLayoutPrivate>)} />
        <PrivateRoute exact path="/tasks/:taskId" component={() => (<AppLayoutPrivate><TaskEditor /></AppLayoutPrivate>)} />
        <PrivateRoute exact path="/tasks/:taskId/:date" component={() => (<AppLayoutPrivate><TaskDateView /></AppLayoutPrivate>)} />

        <PrivateRoute exact path="/rewards" component={() => (<AppLayoutPrivate><Rewards /></AppLayoutPrivate>)} />
        <PrivateRoute exact path="/rewards/new" component={() => (<AppLayoutPrivate><RewardEditor /></AppLayoutPrivate>)} />
        <PrivateRoute exact path="/rewards/:rewardId" component={() => (<AppLayoutPrivate><RewardEditor /></AppLayoutPrivate>)} />
        <PrivateRoute exact path="/rewards_give/:rewardId/:userOrTeamId" component={() => (<AppLayoutPrivate><RewardGive /></AppLayoutPrivate>)} />

        <PrivateRoute exact path="/net/settings" component={() => (<AppLayoutPrivate><NetworkSettings /></AppLayoutPrivate>)} />
        <PrivateRoute exact path="/net/settings/new" component={() => (<AppLayoutPrivate><NetworkSettingsNew /></AppLayoutPrivate>)} />
        <PrivateRoute exact path="/net/devices" component={() => (<AppLayoutPrivate><NetworkDevices /></AppLayoutPrivate>)} />
        <PrivateRoute exact path="/net/devices/new" component={() => (<AppLayoutPrivate><NetworkDeviceEdit /></AppLayoutPrivate>)} />
        <PrivateRoute exact path="/net/devices/:deviceId" component={() => (<AppLayoutPrivate><NetworkDeviceEdit /></AppLayoutPrivate>)} />
        <PrivateRoute exact path="/net/rules" component={() => (<AppLayoutPrivate><NetworkRules /></AppLayoutPrivate>)} />
        <PrivateRoute exact path="/net/rules/new" component={() => (<AppLayoutPrivate><NetworkRuleEdit /></AppLayoutPrivate>)} />
        <PrivateRoute exact path="/net/rules/:ruleId" component={() => (<AppLayoutPrivate><NetworkRuleEdit /></AppLayoutPrivate>)} />

        <PrivateRoute exact path="/kids/authorize" component={() => (<AppLayoutPrivate><AuthorizeKidsApp /></AppLayoutPrivate>)} />

        <Route path="*" component={() => (<AppLayoutPublic><NotFound /></AppLayoutPublic>)} />
      </Switch>
    </Router>
  )
};

export default AppRouter;
