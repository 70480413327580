import React, {useEffect, useState} from 'react';
import identityAPI from './identityAPI.js';
import useInterval from './hooks/interval';
import {useSelector} from 'react-redux';
import authSlice, {getAuth} from './store/slices/authSlice';

const Version = () => {
  const auth = useSelector(getAuth);
  const [serviceDetails, setServiceDetails] = useState(identityAPI.getServiceDetails());
  const [appVersionDetails, setAppVersionDetails] = useState({
    ...identityAPI.getAppVersionDetails()
  });

  useInterval(() => {
    identityAPI.refreshAppVersionDetails(
      null,
      (apiError, refreshed) => {
        if (apiError) {
          // setError(apiError);
          console.log(apiError);
          return;
        }

        if (refreshed) {
          setAppVersionDetails({
            ...identityAPI.getAppVersionDetails()
          });
        }

      }
    );
  }, 30 * 1000, true);

  useEffect(() => {
    setServiceDetails(identityAPI.getServiceDetails());
  }, [auth.accessToken]);


  if ((appVersionDetails.available !== '0.0.0' && appVersionDetails.loaded !== appVersionDetails.available)) {
    return <div className="bg-green-400 pl-2 text-sm">
      Update available please
      <button className="bg-green-600 m-1 p-1 hover:bg-blue-500 rounded text-sm text-white" onClick={() => window.location.reload(true)}>
        Reload
      </button>
    </div>
  }

  return (
    <></>
  );
};

export default Version;
