
// export const UPSERT_TASK = ...
module.exports.UPSERT_TASK = `
  mutation upsertTask(
      $id: String
      $title: String!
      $scheduleMode: String!
      $effortMode: String!
      $pointsType: String!
      $points: Int!
      $who: [String!]
      $tags: [String!]
      $when: WhenInput!
      $startsAt: Date!
    ) {
    upsertTask(
      id: $id
      title: $title
      scheduleMode: $scheduleMode
      effortMode: $effortMode
      pointsType: $pointsType
      points: $points
      who: $who
      tags: $tags
      when: $when
      startsAt: $startsAt
    )
  }
`;

module.exports.DELETE_TASK = `
  mutation deleteTask(
      $id: String!
    ) {
    deleteTask(
      id: $id
    )
  }
`;

module.exports.DELETE_TASK_LOG = `
  mutation deleteAchievement(
      $id: String!
    ) {
    deleteAchievement(
      id: $id
    )
  }
`;

module.exports.DONE_TASK_MANUAL = `
  mutation doneTaskManual(
      $taskId: String!
      $doneById: String!
      $dateAt: String!
    ) {
    doneTaskManual(
      taskId: $taskId
      doneById: $doneById
      dateAt: $dateAt
    )
  }
`;


module.exports.DONE_TASK = `
  mutation doneTask(
      $taskId: String!
      $doneById: String!
    ) {
    doneTask(
      taskId: $taskId
      doneById: $doneById
    )
  }
`;
