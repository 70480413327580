import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import { t } from '@lingui/macro';
import i18n from '../i18n';

import authSlice, { getAuth } from '../store/slices/authSlice';

import identityAPI from '../identityAPI.js';
import {getTeams} from '../store/slices/teamsSlice';
import Button from '../components/Button';

const Recover = () => {
  // Careful! this page must be session agnostic,
  // it should work in public and private
  // getTeams should not be expected/used
  const auth = useSelector(getAuth);

  const [error, setError] = useState('');
  const [sending, setSending] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(identityAPI.getCurrentTeamId);
  const [availableTeams, setAvailableTeams] = useState(auth.availableTeams);

  const handleSwitch = async (e, selectedTeam) => {
    e.preventDefault();
    setSending(true);
    setError('');
    try {
      await identityAPI.switchTeam(selectedTeam);
      // todo: reload? redirect?
      // window.location.replace('/');
      window.location.href = '/';
      // window.location.reload(true)

    } catch (apiError) {
      console.log(apiError);
      setError(apiError.message);
      setSending(false);
    }
  };

  // identityAPI.getTeams()
  if (!availableTeams.length) {
    return <p>Error loading the teams available for your user.<button className="bg-green-600 m-1 p-1 hover:bg-blue-500 rounded text-sm text-white" onClick={() => window.location.reload(true)}>
      Reload
    </button></p>
  }

  if (sending) {
    return <p>Selecting team...</p>
  }

  return (
    <>
      <div className="pb-8">
        <h2 className="text-2xl pb-2">Select Team</h2>
        <p className="text-gray-700">
          Your user is related to multiple teams, please select the one you want to use for this session.<br/>
        </p>
      </div>

      <div className="col-span-2">
        <form className="w-full">
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              {availableTeams.map(({ id, name }) => (
                <div key={`schedule-mode-${id}`} className="py-2 m-1">
                  {name} &nbsp;
                  <strong>
                    {selectedTeam === id ? <a className="link cursor-pointer"  onClick={(e) => handleSwitch(e, id)}>(current)</a> : <a className="link cursor-pointer"  onClick={(e) => handleSwitch(e, id)}>Select</a>}
                  </strong>
                </div>
              ))}
            </div>
          </div>
          {error && <p className="text-xs italic text-red-500">{error.message}</p>}

        </form>
      </div>
    </>
  );
};

export default Recover;
