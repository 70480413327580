import { createSlice } from '@reduxjs/toolkit';

import identityAPI from '../../identityAPI.js';

const auth = createSlice({
  name: 'auth',
  initialState: {
    loaded: false,
    loading: false,
    ...identityAPI.getTokens(),
  },
  reducers: {
    tokensLoaded: ((state, action) => {
      return {
        loaded: true,
        loading: false,
        ...identityAPI.getTokens(),
      };
    }),
    tokensLoading: ((state, action) => {
      return {
        loaded: false,
        loading: true,
        ...identityAPI.getTokens(),
      };
    }),
    signInSuccess: ((state, action) => {
      return {
        loaded: true,
        loading: false,
        ...identityAPI.getTokens(),
      };
    }),
    signOut: (() => {
      return {
        loaded: true,
        loading: false,
        ...identityAPI.getTokens(),
      };
    }),
  }
});

export const getAuth = (state) => state.auth;

export default auth;
