import React, { useState, useEffect, useRef } from 'react';
import {Link, Redirect, useLocation, useParams} from 'react-router-dom';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz';
import {useSelector} from 'react-redux';
import omitDeep from '../utils/omitDeep';

import { t } from '@lingui/macro';

import i18n from '../i18n';
import { formatDate, parseDate, getDateFromDate, getTimeFromDate } from '../utils';

import Button from '../components/Button';

import { useMutation, useQuery } from 'urql';
import {UPSERT_REWARD, DELETE_REWARD} from '@darescouts/api-graphql/rewards/rewardsMutations';
import {GET_REWARD} from '@darescouts/api-graphql/rewards/rewardsQueries';
import { LIST_KIDS } from '@darescouts/api-graphql/kids/kidsQueries'
import Points from '../components/Points';

const RewardEditor = () => {
  const { rewardId } = useParams();
  const firstInput = useRef(null);
  const returnTo = new URLSearchParams(useLocation().search).get('returnTo');
  const [deleteRewardResult, deleteReward] = useMutation(DELETE_REWARD);

  const [listKidsQuery] = useQuery({
    query: LIST_KIDS,
    requestPolicy: 'cache-and-network',
  });

  const [reward] = useQuery({
    query: GET_REWARD,
    variables: { id: rewardId },
    pause: !rewardId,
    requestPolicy: 'cache-and-network',
  });

  const [createRewardResult, createReward] = useMutation(UPSERT_REWARD);
  const { fetching, data, error } = createRewardResult;
  const [ready, setReady] = useState(false);
  const [step, setStep] = useState(0);
  const [stepsStarted, setStepsStarted] = useState(true); // assumes create
  const [deleting, setDeleting] = useState(false);

  const steps = [
    {
      tag: 'title',
    },
    {
      tag: 'who',
    },
    {
      tag: 'schedule',
    },
  ];

  // round current date and time to nearest 5 minutes ex. 11:23 becomes 11:25
  // (new Date((Math.round((new Date().getTime() + 150000) / 300000)) * 300000)).toTimeString().slice(0,5)
  const currentRoundedDateTime = new Date((Math.round((new Date().getTime() + 150000) / 300000)) * 300000);

  const [form, setValues] = useState({
    title: '',
    who: [],
    availableHours: '',
    pointsType: 'S',
    points: 1,
  });

  useEffect(() => {
    if (firstInput.current) {
      firstInput.current.focus();
    }
  }, []);

  useEffect(() => {
    if (!reward || reward.fetching || reward.error || !reward.data || !reward.data.reward) return;
    console.log('reward loaded')
    const initialLoadedForm = {
      id: reward.data.reward.id,
      title: reward.data.reward.title,
      who: reward.data.reward.who,
      availableHours:  reward.data.reward.availableHours,
      pointsType: reward.data.reward.pointsType,
      points: reward.data.reward.points,
    };

    setStepsStarted(false);
    setValues(initialLoadedForm);
  }, [reward]);

  useEffect(() => {
    let isReady;

    if (
      step < steps.findIndex(s => s.tag === 'who') // before who
      && form.title
    ) {
      isReady = true;
    } else if (
      form.title
      && form.who
      && form.who.length > 0
    ) {
      isReady = true;
    } else {
      isReady = false;
    }

    setReady(isReady);
  }, [form, step]);

  const updateField = e => {
    e.preventDefault();
    setValues({
      ...form,
      [e.target.name]: e.target.value
    });
  };

  const handleSelectKid = (kidId) => {
    let newWho;

    if(['Anyone'].includes(kidId)) {
      newWho = [kidId];
    } else {
      // keep only the items that are NOT the kidId
      newWho = form.who.filter(who => who !== kidId && !['Anyone'].includes(who));

      if (!form.who.includes(kidId)) {
        newWho.push(kidId);
      }
    }

    setValues({
      ...form,
      who: newWho,
    });
  };

  const handleSelectPointsType = (pointsType) => {
    let points = 1
    if (pointsType === form.pointsType) {
      points = parseInt(form.points) < 999 ? parseInt(form.points) + 1 : 1;
    }

    setValues({
      ...form,
      pointsType: pointsType,
      points: points,
    });
  };

  const handleSetPoints = (points) => {
    const newPoints = parseInt(form.points) + points;
    setValues({
      ...form,
      points: newPoints > 0 ? newPoints : 0,
    });
  };

  const handleCreate = async (e) => {
    e.preventDefault();
    createReward(form).then(result => {
      // The result is almost identical to `updateTodoResult` with the exception
      // of `result.fetching` not being set.
      console.log('NewReward -> handleCreate', result);
    });
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    const reallyDelete = confirm('Are you sure you want to delete this item?')
    if (!reallyDelete) {
      return;
    }
    setDeleting(true);
    deleteReward({ id: rewardId }).then(result => {
      console.log('deleteReward', result);
    });
  };

  if (!error && data) {
    return <Redirect to={returnTo ? `${returnTo}` : '/rewards'} />;
  }

  if (deleting && deleteRewardResult.data && deleteRewardResult.data.deleteReward) {
    return <Redirect to="/rewards" />;
  }

  if (reward.fetching) return <p>Loading reward...</p>;
  if (reward.error) return <p>Error loading reward: {reward.error.message}<button className="bg-green-600 m-1 p-1 hover:bg-blue-500 rounded text-sm text-white" onClick={() => window.location.reload(true)}>
    Reload
  </button></p>;

  const handleSelectScheduleMode = (value) => {
    form.availableHours = value;

    setValues({
      ...form,
    });
  };

  return (<>
    <div className="pb-8">
      {!form.id && <h2 className="text-2xl pb-2">
        {i18n._(t('rewardEditor.new')`Create a new reward`)}
      </h2>}
      {form.id && <h2 className="text-2xl pb-2">
        {i18n._(t('rewardEditor.edit')`Reward editor`)}
      </h2>}

      {!form.id && <p className="text-gray-700">
        Give the new reward a title and schedule it.
      </p>}
      {form.id && <p className="text-gray-700">
        Update or delete this reward.
      </p>}
    </div>
    <div className="col-span-2">

      {!stepsStarted &&
      <>
        <h1 className="text-2xl text-gray-900">
          {form.title}
        </h1>
        <div className="text-gray-700 py-2">
          <div>
            Available: {form.availableHours} <br/>
            Cost: <Points points={form.points} type={form.pointsType} />
          </div>
        </div>

        <div className="grid grid-cols-3 gap-2 py-2">
          <div className="">
            <Button
              size="extra-small"
              variant="warning"
              disabled={false}
              onClick={() => setStepsStarted(true)}
            >
              {i18n._(t('rewardEditor.actionEdit')`Modify`)}
            </Button>
          </div>
          <div className="">
            <Button
              size="extra-small"
              variant="actions-cancel"
              disabled={false}
              onClick={handleDelete}
            >
              {i18n._(t('rewardEditor.delete')`Delete`)}
            </Button>
          </div>
          <div className="">
            <Button
              size="extra-small"
              variant="actions-accept"
              disabled={false}
              to="/rewards"
            >
              {i18n._(t('rewardEditor.cancel')`Done`)}
            </Button>
          </div>
        </div>
      </>
      }

      {stepsStarted && <>
        {steps[step].tag === 'title' &&
        <>
          <div className="text-gray-700 py-2">
            Title for the new reward:
          </div>
          <div className="">
            <input
              ref={firstInput}
              className="input"
              type="text"
              placeholder={i18n._(t('rewardEditor.title')`Title`)}
              name="title"
              value={form.title}
              onChange={updateField}
            />
          </div>

          <div className="text-gray-700 py-2">
            Cost
          </div>
          <div className="flex flex-wrap">
            {[
              { id: 'S', name: 'Silver' },
              { id: 'G', name: 'Gold' },
              { id: 'D', name: 'Diamond' },
            ].map(({ id, name }) => (
              <div key={`schedule-mode-${id}`} className="py-2 m-1">
                <Button
                  size="extra-small"
                  variant={
                    (form.pointsType === id) ?
                      'primary' : 'light'
                  }
                  onClick={() => handleSelectPointsType(id)}
                >
                  <Points points={form.pointsType === id ? form.points : 0} type={id} />
                </Button>
              </div>
            ))}
          </div>
          <Link className="link pl-2" onClick={() => handleSetPoints(-10)}>
            -10
          </Link>
          <Link className="link pl-2" onClick={() => handleSetPoints(-1)}>
            -1
          </Link>
          <Link className="link pl-2" onClick={() => handleSetPoints(1)}>
            1
          </Link>
          <Link className="link pl-2" onClick={() => handleSetPoints(10)}>
            10
          </Link>
        </>
        }

        {steps[step].tag === 'who' &&
        <>
          <div className="text-gray-700 py-2">
            Select who is allowed to collect this reward:
          </div>
          {listKidsQuery.data &&
          <div className="flex flex-wrap">
            {[{ id: 'Anyone', name: 'Anyone' }].concat(listKidsQuery.data.kids).map(({ id, name }) => (
              <div key={`kid-${id}`} className="py-2 m-1">
                <Button
                  size="extra-small"
                  variant={
                    (form.who.includes(id)) ?
                      'primary' : 'light'
                  }
                  onClick={() => handleSelectKid(id)}
                >
                  {name}
                </Button>
              </div>
            ))}
          </div>
          }
          {listKidsQuery.fetching &&<div className="">loading kids</div>}
          {listKidsQuery.error &&<div className="">{listKidsQuery.error.message}</div>}
        </>
        }

        {steps[step].tag === 'schedule' &&
        <>
          <div className="text-gray-700 py-2">
            Availability
          </div>
          <div className="flex flex-wrap">
            {[
              { id: 'Anytime', name: 'Anytime', value: ''},
              { id: 'Mon-Fri', name: 'Mon-Fri after 3pm', value: 'mon-fri 15:00-23:59'},
              { id: 'Weekends', name: 'Weekends', value: 'sat-sun 08:00-23:59'},
            ].map(({ id, name , value}) => (
              <div key={`schedule-mode-${id}`} className="py-2 m-1">
                <Button
                  size="extra-small"
                  variant={
                    (form.availableHours === value) ?
                      'primary' : 'light'
                  }
                  onClick={() => handleSelectScheduleMode(value)}
                >
                  {name}
                </Button>
              </div>
            ))}
          </div>
        </>
        }
        <div className="grid grid-cols-2 gap-2 py-2">
          <div className="">
            {step === 0 &&<Button
              size="extra-small"
              variant="actions-cancel"
              disabled={fetching}
              to={returnTo ? `${returnTo}` : '/rewards'}
            >
              {i18n._(t('rewardEditor.cancel')`Cancel`)}
            </Button>}
            {step > 0 &&<Button
              size="extra-small"
              variant="warning"
              disabled={fetching}
              onClick={() => setStep(step - 1)}
            >
              {i18n._(t('rewardEditor.previousStep')`Previous`)}
            </Button>}
          </div>
          <div className="">
            {step < steps.length - 1 &&<Button
              size="extra-small"
              variant="primary"
              disabled={!ready || fetching}
              onClick={() => setStep(step + 1)}
            >
              {i18n._(t('rewardEditor.continue')`Continue`)}
            </Button>}
            {step === steps.length - 1 && !form.id &&<Button
              size="extra-small"
              variant="actions-accept"
              disabled={!ready || fetching}
              onClick={handleCreate}
            >
              {i18n._(t('rewardEditor.create')`Create`)}
            </Button>}
            {step === steps.length - 1 && form.id &&<Button
              size="extra-small"
              variant="actions-accept"
              disabled={!ready || fetching}
              onClick={handleCreate}
            >
              {i18n._(t('rewardEditor.update')`Update`)}
            </Button>}
          </div>
        </div>
      </>}


      {error && <p className="text-xs italic text-red-500">{error.message}</p>}



    </div>
  </>);
};

export default RewardEditor;
