import {Client, dedupExchange, fetchExchange, subscriptionExchange } from '@urql/core';
import { errorExchange} from 'urql';
// import {SubscriptionClient} from 'subscriptions-transport-ws'; // todo: switch to graphql-ws and learn what can be done for auth on connect (currently it fails when token is old)
import {offlineExchange} from '@urql/exchange-graphcache';
import { makeDefaultStorage } from '@urql/exchange-graphcache/default-storage';

import { retryExchange } from '@urql/exchange-retry';
import { authExchange } from '@urql/exchange-auth';

import identityAPI from './identityAPI';

const encodedTokenIsExpiring = (token, minutesMarging) => {
  const decoded = JSON.parse(atob(token.split('.')[1]));
  const expiration = decoded.exp;
  const currTime = parseInt(Date.now() / 1000);
  const validFor = parseInt( (decoded.exp - currTime) / 60 );
  console.log('valid for', validFor, 'minutes')

  return expiration < (currTime + (minutesMarging * 60));
};

const storage = makeDefaultStorage({
  idbName: 'graphcache-parents', // The name of the IndexedDB database
  maxAge: 1, // The maximum age of the persisted data in days
});

const cache = offlineExchange({
  storage,
  updates: {
    Mutation: {
      //...
    },
    Subscription: {
      //...
    }
  }
});

// None of these options have to be added, these are the default values.
const retryOptions = {
  initialDelayMs: 2000,
  maxDelayMs: 15000,
  randomDelay: true,
  maxNumberAttempts: 4,
  retryIf: err => {
    // all good
    if (!err) {
      return false;
    }

    console.log('graphql call error', err);

    // retry on network errors
    if (err.networkError) {
      return true;
    }

    return false;
  },
};

// let subscriptionClient;
let client;

const configureClient = () => {
  // already configured, return it
  if (client) {
    return client;
  }

  if (!identityAPI.getService() || !identityAPI.getService().graphqlUrl) {
    console.log('configureClient ignored, client instantiated?:', !!client);
    return client;
  }

  // subscriptionClient = new SubscriptionClient(
  //   identityAPI.getService().graphqlWS,
  //   {
  //     reconnect: true,
  //     reconnectionAttempts: 100,
  //     connectionParams: {
  //       accessToken: identityAPI.getService().accessToken,
  //       acceptVersion: identityAPI.getServiceDetails().expectedService.acceptVersion,
  //     },
  //     timeout: 20000,
  //     inactivityTimeout: 5 * 60 * 1000,
  //   }
  // );

  const getAuth = async ({ authState }) => {
    console.log('***** getAuth *****')
    // at start, urql have no authState
    if (!authState) {
      console.log('***** !authState')
      try {
        await identityAPI.loadTokens();  // load tokens from storage (async!)

        // is reloading / fresh start, but the token is kinda old
        // force refresh
        if (encodedTokenIsExpiring(identityAPI.getService().accessToken, 3)) {
          console.log('***** refreshing at start')
          await identityAPI.refreshToken(identityAPI.getTokens(), true)
        }

        const token = identityAPI.getService().accessToken;         // context token!
        const refreshToken = identityAPI.getTokens().refreshToken;  // identity refresh!

        if (token && refreshToken) {
          console.log('***** new or fresh token ok')
          return { token, refreshToken };
        }

      } catch (e) {
        console.log('error refreshing token', error);
      }

      console.log('***** !authState returns null')

      // This is where auth has gone wrong and we need to clean up and redirect to a login page
      await identityAPI.resetTokens(true);
      window.location.reload();

      return new Promise((resolve) => setTimeout(resolve, 1000));
    }

    // if we have an authState, either failed or it will fail
    // force refresh
    try {
      await identityAPI.loadTokens();  // load tokens from storage (async!)
      console.log('***** refreshing before or after failure')
      await identityAPI.refreshToken(identityAPI.getTokens(), true)
      console.log('***** refreshToken ok')
      const token = identityAPI.getService().accessToken;         // context token!
      const refreshToken = identityAPI.getTokens().refreshToken;  // identity refresh!
      console.log('***** get token/refreshToken ok')

      if (token && refreshToken) {
        console.log('***** refreshing token ok')
        return { token, refreshToken };
      }

      console.log('***** tried to refresh but returning null')
    } catch (e) {
      console.log('error refreshing token', e);
    }

    // retry failed
    console.log('***** getAuth nothing happened?')

    // This is where auth has gone wrong and we need to clean up and redirect to a login page
    await identityAPI.resetTokens(true);
    window.location.reload();

    return new Promise((resolve) => setTimeout(resolve, 1000));
  }

  const addAuthToOperation = ({ authState, operation, }) => {
    console.log('addAuthToOperation:')
    if (!authState || !authState.token) {
      console.log('_________ no auth')
      return operation;
    }

    const fetchOptions =
      typeof operation.context.fetchOptions === 'function'
        ? operation.context.fetchOptions()
        : operation.context.fetchOptions || {};

    console.log('_________ with auth')
    return {
      ...operation,
      context: {
        ...operation.context,
        fetchOptions: {
          ...fetchOptions,
          headers: {
            ...fetchOptions.headers,
            authorization: `Bearer ${authState.token}`,
            'Accept-version': identityAPI.getServiceDetails().expectedService.acceptVersion
          },
        },
      },
    };
  }

  const didAuthError = ({ error }) => {
    // return error.graphQLErrors.some(
    //   e => e.response.status === 401,
    // );

    if (error && error.message.includes('Invalid token')) {
      console.log('didAuthError YES Invalid token')
      return true;
    }

    if (error && error.message.includes('UI expects API version')) {
      console.log('didAuthError YES API version')
      return true;
    }

    return false;
  };

  // check if token is valid before even trying call
  const willAuthError = ({ authState }) => {
    if (!authState) {
      console.log('willAuthError because !authState')
      return true;
    }

    if (!authState.token) {
      console.log('willAuthError because !authState.token')
      return true;
    }

    // check expiration
    if (authState.token) {
      console.log('willAuthError check expiration')
      if (encodedTokenIsExpiring(authState.token, 2)) {
        console.log('willAuthError because expiration < (currTime + 2)')
        return true;
      }
    }

    console.log('willAuthError no expiring')
    return false;
  }

  console.log('configureClient: new Client');
  client = new Client({
    url: identityAPI.getService().graphqlUrl,
    // fetchOptions: () => {
    //   const token = identityAPI.getService().accessToken;
    //   return {
    //     headers: {
    //       authorization: token ? `Bearer ${token}` : '',
    //       'Accept-version': identityAPI.getServiceDetails().expectedService.acceptVersion
    //     }
    //   }
    // },
    exchanges: [
      dedupExchange,
      cache,
      errorExchange({
        onError: async (error) => {
          const isAuthError = error.graphQLErrors.some(
            e => e.extensions?.code === 'FORBIDDEN',
          );
          if (isAuthError) {
            await identityAPI.resetTokens();
          }
        }
      }),
      authExchange({
        getAuth: getAuth,
        addAuthToOperation: addAuthToOperation,
        // didAuthError: didAuthError,
        willAuthError: willAuthError,
      }),
      retryExchange(retryOptions),
      fetchExchange,
      // subscriptionExchange({
      //   forwardSubscription: operation => subscriptionClient.request(operation)
      // })
    ]
  });

  return client;
}

export default configureClient;
