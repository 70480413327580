
// export const UPSERT_NETWORK = ...
module.exports.UPSERT_NETWORK = `
  mutation upsertNetwork(
      $id: String
      $teamId: String
      $name: String
      $password: String
      $dns: [String]
      $key: String
    ) {
    upsertNetwork(
      id: $id
      teamId: $teamId
      name: $name
      password: $password
      dns: $dns
      key: $key
    )
  }
`;

module.exports.UPSERT_NETWORK_DEVICE = `
  mutation upsertNetworkDevice(
      $id: String
      $teamId: String
      $netId: String
      $userId: String
      $name: String
      $mac: String
      $ip: String
    ) {
    upsertNetworkDevice(
      id: $id
      teamId: $teamId
      netId: $netId
      userId: $userId
      name: $name
      mac: $mac
      ip: $ip
    )
  }
`;

module.exports.UPSERT_NETWORK_RULE = `
  mutation upsertNetworkRule(
      $id: String
      $teamId: String
      $netId: String
      $name: String
      $devices: [String]
      $schedule: networkRuleScheduleTypeInput
      $condition: networkRuleConditionTypeInput
    ) {
    upsertNetworkRule(
      id: $id
      teamId: $teamId
      netId: $netId
      name: $name
      devices: $devices
      schedule: $schedule
      condition: $condition
    )
  }
`;

module.exports.OVERRIDE_NETWORK_DEVICE = `
  mutation overrideNetworkDevice(
      $id: String
      $enableFor: Int
      $disableFor: Int
    ) {
    overrideNetworkDevice(
      id: $id
      enableFor: $enableFor
      disableFor: $disableFor
    )
  }
`;

module.exports.RESET_OVERRIDE_NETWORK_DEVICE = `
  mutation resetOverrideNetworkDevice(
      $id: String
    ) {
    resetOverrideNetworkDevice(
      id: $id
    )
  }
`;
