import React, { useState, useEffect, useRef } from 'react';
import {Link, Redirect, useLocation, useParams} from 'react-router-dom';

import { t } from '@lingui/macro';

import i18n from '../i18n';

import Button from '../components/Button';

import { useMutation, useQuery } from 'urql';
import { UPSERT_NETWORK } from '@darescouts/api-graphql/networks/networksMutations';
import { GET_NETWORK } from '@darescouts/api-graphql/networks/networksQueries';

import {useSelector} from 'react-redux';
import {getCurrentTeam} from '../store/slices/teamsSlice';

const NetworkSettings = () => {
  const currentTeam = useSelector(getCurrentTeam);

  const firstInput = useRef(null);
  const returnTo = new URLSearchParams(useLocation().search).get('returnTo');

  const [settings] = useQuery({
    query: GET_NETWORK,
    variables: { id: 'main' },
    requestPolicy: 'cache-and-network',
  });

  const [upsertNetworkResult, upsertNetwork] = useMutation(UPSERT_NETWORK);
  const { fetching, upsertData, error } = upsertNetworkResult;
  const [ready, setReady] = useState(true); // todo: add validation
  const [done, setDone] = useState(false);

  const [form, setValues] = useState({
    id: 'main',
    teamId: currentTeam.id,
    name: 'DareScouts Default',
    password: 'internet',
    dns: ['1.1.1.1', '8.8.8.8'],
    key: '',
  });

  useEffect(() => {
    if (firstInput.current) {
      firstInput.current.focus();
    }
  }, []);

  useEffect(() => {
    if (!settings || settings.fetching || settings.error || !settings.data || !settings.data.network) return;
    console.log('settings loaded', settings.data.network)
    const initialLoadedForm = {
      id: settings.data.network.id,
      teamId: settings.data.network.teamId,
      name: settings.data.network.name,
      password: settings.data.network.password,
      dns: settings.data.network.dns,
      key: settings.data.network.key,
    };

    setValues(initialLoadedForm);
  }, [settings]);

  const updateField = e => {
    e.preventDefault();
    setValues({
      ...form,
      [e.target.name]: e.target.value
    });
  };

  const handleUpsert = async (e) => {
    e.preventDefault();

    upsertNetwork(form).then(result => {
      // The result is almost identical to `updateTodoResult` with the exception
      // of `result.fetching` not being set.
      console.log('handleUpsert', result);
      setDone(true)
    });
  };
  console.log('upsertData', upsertData)

  // no error but we upserted, redirect
  if (done) {
    return <Redirect to={returnTo ? `${returnTo}` : '/settings'} />;
  }

  if (settings.fetching) return <p>Loading settings...</p>;
  if (settings.error) return <p>Error loading network settings: {settings.error.message}<button className="bg-green-600 m-1 p-1 hover:bg-blue-500 rounded text-sm text-white" onClick={() => window.location.reload(true)}>
    Reload
  </button></p>;

  if (!settings.data.network) {
    return <div>
      <h2 className="text-2xl pb-2">Network</h2>
      <p className="text-gray-700">
        To manage internet access for your home devices, you first need to configure a network.
      </p>
      <Link className="link" to="/net/settings/new">
        Click here to configure a network.
      </Link>
    </div>
  }

  return (<>
    <div className="pb-8">
      <h2 className="text-2xl pb-2">
        {i18n._(t('networkSettings.edit')`Edit network settings`)}
      </h2>

      <p className="text-gray-700">
        Configure your network.
      </p>
    </div>
    <div className="col-span-2">
        <div className="">
          <div className="text-gray-700 py-2 mt-3">
            Name
          </div>
          <input
            ref={firstInput}
            className="input"
            type="text"
            placeholder={i18n._(t('networkSettings.name')`Network Name`)}
            name="name"
            value={form.name}
            disabled={fetching || true}
            onChange={updateField}
          />
        </div>

        <div className="">
          <div className="text-gray-700 py-2 mt-3">
            Key
          </div>
          <input
            ref={firstInput}
            className="input"
            type="text"
            name="key"
            value={form.key}
            disabled={fetching || true}
            onChange={updateField}
          />
        </div>

        <div className="">
          <div className="text-gray-700 py-2 mt-3 text-sm">
            darescounts.json
          </div>
          <pre className="text-xs bg-gray-100 p-2">
            {JSON.stringify({
              id: form.id,
              key: form.key,
            }, null, 2)}
          </pre>

        </div>

        <div className="hidden grid grid-cols-2 gap-2 py-2">
          <div className="">
            <Button
              size="extra-small"
              variant="actions-cancel"
              disabled={fetching}
              to={returnTo ? `${returnTo}` : '/settings'}
            >
              {i18n._(t('networkSettings.cancel')`Cancel`)}
            </Button>
          </div>
          <div className="">
            <Button
              size="extra-small"
              variant="actions-accept"
              disabled={!ready || fetching}
              onClick={handleUpsert}
            >
              {i18n._(t('networkSettings.save')`Save`)}
            </Button>
          </div>
        </div>


      {error && <p className="text-xs italic text-red-500">{error.message}</p>}

      <br/>
      <Link className="link" to="/net/settings/new">
        Run setup wizard again
      </Link>
    </div>
  </>);
};

export default NetworkSettings;
