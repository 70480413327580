import { fork } from 'redux-saga/effects';
import watchUserLoad from './watchUserLoad';
import watchUserEditLoad from './watchUserEditLoad';
import watchSetCurrentTeamId from './watchSetCurrentTeamId';
import watchMembersLoad from './watchMembersLoad';
import watchMemberUserEditLoad from './watchMemberUserEditLoad';

export default function* rootSaga() {
  yield fork(watchUserLoad);
  yield fork(watchUserEditLoad);
  yield fork(watchSetCurrentTeamId);
  yield fork(watchMembersLoad);
  yield fork(watchMemberUserEditLoad);
}
