import { createSlice } from '@reduxjs/toolkit';

const members = createSlice({
  name: 'members',
  initialState: {
    isLoading: false,
    error: null,
    items: [],
  },
  reducers: {
    load: ((state) => ({
      isLoading: true,
      error: null,
      items: state.items,
    })),
    success: ((state, action) => ({
      isLoading: false,
      error: null,
      items: action.payload,
    })),
    error: ((state, action) => ({
      isLoading: false,
      error: action.payload,
      items: state.items, // keep what ever was already there
    })),
  }
});

export const getMembers = (state) => state.members;

export default members;
