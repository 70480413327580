import React, { useState, useEffect, useRef } from 'react';
import {Link, Redirect, useLocation, useParams} from 'react-router-dom';

import { t } from '@lingui/macro';

import i18n from '../i18n';

import Button from '../components/Button';

import { useMutation, useQuery } from 'urql';
import { UPSERT_NETWORK_DEVICE } from '@darescouts/api-graphql/networks/networksMutations';
import { GET_NETWORK_DEVICE } from '@darescouts/api-graphql/networks/networksQueries';

import {useSelector} from 'react-redux';
import {getCurrentTeam} from '../store/slices/teamsSlice';
import {LIST_KIDS} from '@darescouts/api-graphql/kids/kidsQueries';

const NetworkDeviceEdit = () => {
  const currentTeam = useSelector(getCurrentTeam);
  const { deviceId } = useParams();

  const firstInput = useRef(null);
  const returnTo = new URLSearchParams(useLocation().search).get('returnTo');

  const [listKidsQuery] = useQuery({
    query: LIST_KIDS,
    variables: { andTeamUser: true, },
    requestPolicy: 'cache-and-network',
  });

  const [networkDevice] = useQuery({
    query: GET_NETWORK_DEVICE,
    variables: { id: deviceId },
    pause: !deviceId,
    requestPolicy: 'cache-and-network',
  });

  const [upsertNetworkDeviceResult, upsertNetworkDevice] = useMutation(UPSERT_NETWORK_DEVICE);
  const { upserting, upsertData, upsertingError } = upsertNetworkDeviceResult;
  const [ready, setReady] = useState(true); // todo: add validation
  const [done, setDone] = useState(false);
  const [error, setError] = useState('');

  const [form, setValues] = useState({
    teamId: currentTeam.id,
    netId: 'main',
    userId: null,
    name: 'New Device',
    mac: '',
    ip: '',
  });

  useEffect(() => {
    if (firstInput.current) {
      firstInput.current.focus();
    }
  }, []);

  useEffect(() => {
    if (!networkDevice || networkDevice.fetching || networkDevice.error || !networkDevice.data || !networkDevice.data.networkDevice) return;
    console.log('device loaded', networkDevice.data.networkDevice)
    const initialLoadedForm = {
      id: networkDevice.data.networkDevice.id,
      teamId: networkDevice.data.networkDevice.teamId,
      netId: networkDevice.data.networkDevice.netId,
      userId: networkDevice.data.networkDevice.userId,
      name: networkDevice.data.networkDevice.name,
      mac: networkDevice.data.networkDevice.mac,
      ip: networkDevice.data.networkDevice.ip,
    };

    setValues(initialLoadedForm);
  }, [networkDevice]);

  const updateField = e => {
    e.preventDefault();
    setValues({
      ...form,
      [e.target.name]: e.target.value
    });
  };

  const handleUpsert = async (e) => {
    e.preventDefault();
    try {
      const result = await upsertNetworkDevice(form);

      if (result.error) {
        throw new Error(result.error)
      }

      setDone(true);
    } catch (e) {
      console.log(e);
      setError(e.message || e);
    }
  };

  const handleSelectKid = (kidId) => {
    setValues({
      ...form,
      userId: kidId,
    });
  };

  if (done) {
    return <Redirect to={returnTo ? `${returnTo}` : '/net/devices'} />;
  }

  if (networkDevice.fetching) return <p>Loading details...</p>;
  if (networkDevice.error) return <p>Error loading details: {networkDevice.error.message}<button className="bg-green-600 m-1 p-1 hover:bg-blue-500 rounded text-sm text-white" onClick={() => window.location.reload(true)}>
    Reload
  </button></p>;

  return (<>
    <div className="pb-8">
      <h2 className="text-2xl pb-2">
        {i18n._(t('networkDevices.edit')`Edit device`)}
      </h2>

      <p className="text-gray-700">
        Configure the device.
      </p>
    </div>
    <div className="col-span-2">
      <div className="">
        <div className="text-gray-700 py-2 mt-3">
          Name
        </div>
        <input
          ref={firstInput}
          className="input"
          type="text"
          placeholder={i18n._(t('networkDevices.deviceName')`Device Name`)}
          name="name"
          value={form.name}
          disabled={upserting}
          onChange={updateField}
        />
      </div>

      <div className="">
        <div className="text-gray-700 py-2 mt-3">
          MAC
        </div>
        <input
          className="input"
          type="text"
          placeholder=''
          name="mac"
          value={form.mac}
          disabled={upserting}
          onChange={updateField}
        />
      </div>

      {/*<div className="">*/}
      {/*  <div className="text-gray-700 py-2 mt-3">*/}
      {/*    IP*/}
      {/*  </div>*/}
      {/*  <input*/}
      {/*    className="input"*/}
      {/*    type="text"*/}
      {/*    placeholder='0.0.0.0'*/}
      {/*    name="ip"*/}
      {/*    value={form.ip}*/}
      {/*    disabled={upserting}*/}
      {/*    onChange={updateField}*/}
      {/*  />*/}
      {/*</div>*/}

      <div className="text-gray-700 py-2 mt-2">
        Device owner:
      </div>
      {listKidsQuery.data &&
      <div className="flex flex-wrap">
        {listKidsQuery.data.kids.map(({ id, name }) => (
          <div key={`kid-${id}`} className="py-2 m-1">
            <Button
              size="extra-small"
              variant={
                (form.userId === id) ?
                  'primary' : 'light'
              }
              onClick={() => handleSelectKid(id)}
            >
              {name}
            </Button>
          </div>
        ))}
      </div>
      }
      {listKidsQuery.fetching &&<div className="">loading kids</div>}
      {listKidsQuery.error &&<div className="">{listKidsQuery.error.message}</div>}

        <div className="grid grid-cols-2 gap-2 py-2">
          <div className="">
            <Button
              size="extra-small"
              variant="actions-cancel"
              disabled={upserting}
              to={returnTo ? `${returnTo}` : '/net/devices'}
            >
              {i18n._(t('networkDevices.cancel')`Cancel`)}
            </Button>
          </div>
          <div className="">
            <Button
              size="extra-small"
              variant="actions-accept"
              disabled={!ready || upserting}
              onClick={handleUpsert}
            >
              {i18n._(t('networkDevices.deviceSave')`Save`)}
            </Button>
          </div>
        </div>


      {error && <p className="text-xs italic text-red-500">{error}</p>}

    </div>
  </>);
};

export default NetworkDeviceEdit;
