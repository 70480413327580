import React, { useState, useEffect, useRef } from 'react';
import {Link, Redirect, useLocation, useParams} from 'react-router-dom';

import { t } from '@lingui/macro';

import i18n from '../i18n';

import Button from '../components/Button';

import { useMutation, useQuery } from 'urql';
import { UPSERT_NETWORK } from '@darescouts/api-graphql/networks/networksMutations';
import { GET_NETWORK } from '@darescouts/api-graphql/networks/networksQueries';

import {useSelector} from 'react-redux';
import {getCurrentTeam} from '../store/slices/teamsSlice';

import identityAPI from '../identityAPI';

const networkNameSuffix = ' (DareScouts)';

const NetworkSettings = () => {
  const currentTeam = useSelector(getCurrentTeam);
  const netsHost = process.env.NETS_ENDPOINT || 'http://nets.darescouts.test:5000';
  const triggerHostTpl = netsHost.includes('https') ? netsHost.replace('https://', 'https://[ACTION]-trigger.') : netsHost.replace('http://', 'http://[ACTION]-trigger.');
  const apiURL = identityAPI.getService().api;

  const firstInput = useRef(null);
  const returnTo = new URLSearchParams(useLocation().search).get('returnTo');

  const [settings] = useQuery({
    query: GET_NETWORK,
    variables: { id: 'main' },
    requestPolicy: 'cache-and-network',
  });

  const [step, setStep] = useState(0);
  const [stepAccepted, setStepAccepted] = useState(0);
  const [error, setError] = useState(false);
  const [busy, setBusy] = useState(false);

  const [riskAccepted, acceptRisk] = useState(false);

  const [form, setValues] = useState({
    id: 'main',
    teamId: currentTeam.id,
    name: 'Kids WiFi',
    password: 'internet',
    dns: ['1.1.1.1', '8.8.8.8'],
    key: '',
  });

  useEffect(() => {
    if (firstInput.current) {
      firstInput.current.focus();
    }
  }, []);

  useEffect(() => {
    if (!settings || settings.fetching || settings.error || !settings.data || !settings.data.network) return;
    console.log('settings loaded', settings.data.network)
    const initialLoadedForm = {
      id: settings.data.network.id,
      teamId: settings.data.network.teamId,
      name: settings.data.network.name ? settings.data.network.name.replaceAll(networkNameSuffix, '') : 'Kids WiFi',
      password: settings.data.network.password,
      dns: settings.data.network.dns,
      key: settings.data.network.key,
    };

    setValues(initialLoadedForm);
  }, [settings]);

  const updateField = e => {
    e.preventDefault();

    if (e.target.name === 'name' && e.target.value.toString().length + networkNameSuffix.length > 30) {
      return;
    }

    setValues({
      ...form,
      [e.target.name]: e.target.value
    });
  };

  // accept is replacing current net
  const handleAcceptRisk = async (e) => {
    e.preventDefault();
    acceptRisk(true);
  };

  // tells the RPi to send us a key
  const handleStartLink = async () => {
    try {
      console.log('handleStartLink')
      setBusy(true);
      setError('');

      // get a tmp code which is related to our team
      // this allows the RPi to do things in our name
      let res = await fetch(`${apiURL}/get-team-tmp-token`, {
        method: 'GET',
        headers: {
          authorization: `Bearer ${identityAPI.getService().accessToken}`,
          'Accept-version': identityAPI.getServiceDetails().expectedService.acceptVersion
        },
      });

      const tmpCodeAnswer = await res.json();
      if (!res.ok) {
        setError(tmpCodeAnswer.message || 'Something failed.')
        setBusy(false);
        return
      }

      // trigger the detection of the RPi
      let triggerHost = triggerHostTpl.replace('[ACTION]', `${tmpCodeAnswer.code}-detect`);
      res = await fetch(`${triggerHost}/nets/check-link`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({
          code: tmpCodeAnswer.code,
          teamId: currentTeam.id,
        }),
      });

      const checkLinkAnswer = await res.json();
      if (!res.ok) {
        setError(checkLinkAnswer.message || 'Something failed.')
        setBusy(false);
        return
      }

      setBusy(false)
      setValues({
        ...form,
        key: checkLinkAnswer.key
      });

      setStep(step + 1)

    } catch (err) {
      console.log(err);
      setError('The process of discovering your Network failed, ensure you are connected to the "DareScouts Setup" WiFi.');
      setBusy(false);
    }
  };

  // accept the RPi answer
  // AND then tells the RPi to finalize the setup
  const handleAcceptAndFinalize = async () => {
    let tmpCodeAnswer;

    try {
      console.log('handleAcceptAndFinalize')
      setBusy(true);
      setError('');

      // get a tmp code which is related to our team
      // this allows the RPi to do things in our name
      let res = await fetch(`${apiURL}/get-team-tmp-token`, {
        method: 'GET',
        headers: {
          authorization: `Bearer ${identityAPI.getService().accessToken}`,
          'Accept-version': identityAPI.getServiceDetails().expectedService.acceptVersion
        },
      });

      tmpCodeAnswer = await res.json();
      if (!res.ok) {
        setError(tmpCodeAnswer.message || 'Something failed.')
        setBusy(false);
        // we can allow the user to try again on the config form
        return
      }

      // create or update team network (direct no code in domain)
      res = await fetch(`${netsHost}/nets/set-setup-details`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({
          code: tmpCodeAnswer.code,
          teamId: currentTeam.id,
          network: {
            ...form,
            name: `${form.name} (DareScouts)`
          },
        }),
      });

      const setSetupAnswer = await res.json();
      if (!res.ok) {
        setError(setSetupAnswer.message || 'Something failed.')
        setBusy(false);
        // we can allow the user to try again on the config form
        return
      }
    } catch (err) {
      console.log(err);
      setError('The process of finishing the setup failed.');
      // we can allow the user to try again on the config form
      setBusy(false);
    }

    // if this fails we need to send the user one step back
    try {
      // trigger the finish setup of the RPi (code in domain)
      let triggerHost = triggerHostTpl.replace('[ACTION]', `${tmpCodeAnswer.code}-finish`);
      const res = await fetch(`${triggerHost}/nets/check-finish`, {
        method:  'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept':       'application/json',
        },
        body:    JSON.stringify({
          key:    form.key,
          code:   tmpCodeAnswer.code,
          teamId: currentTeam.id,
        }),
      });

      const finishAnswer = await res.json();
      if (!res.ok) {
        setError(finishAnswer.message || 'Something failed.')
        setBusy(false);
        return
      }


      await identityAPI.delay(3);
      setBusy(false)
      setStep(step + 1)
    } catch (err) {
      console.log(err);
      setError('The process of finishing the setup failed.');
      setBusy(false);
      // SEND THE USER ONE STEP BACK! so it gets fresh code and detects the pi again
      setStep(step - 1)
    }

  };

  // so we can warn the user about current settings and use them as default
  if (settings.fetching) return <p>Loading settings...</p>;
  if (settings.error) return <p>Error loading any current network settings: {settings.error.message}<button className="bg-green-600 m-1 p-1 hover:bg-blue-500 rounded text-sm text-white" onClick={() => window.location.reload(true)}>
    Reload
  </button></p>;


  const stepsToConfirm = {
    step1: 'I have a freshly flashed SD card.',
    step4: 'This device is now using the "DareScouts Setup" WiFi.',
  }

  const stepsActions = {
    step4: handleStartLink,
  }

  if (!riskAccepted && settings && settings.data && settings.data.settings) {
    return <div>
      WARNING <br/>
      Your account is already linked with a Network. <br/>
      Proceed with a new network setup ONLY if you are replacing or installing your Raspberry Pi system again. <br/>
      <div className="grid grid-cols-2 gap-2 py-2">
        <div className="">
          <Button
            size="extra-small"
            variant="actions-cancel"
            to="/settings"
          >
            {i18n._(t('networkSettings.cancel')`Cancel`)}
          </Button>
        </div>
        <div className="">
          <Button
            size="extra-small"
            variant="actions-accept"
            onClick={handleAcceptRisk}
          >
            {i18n._(t('networkSettings.Continue')`Continue`)}
          </Button>
        </div>
      </div>
    </div>
  }

  return (<>
    <div className="pb-8">
      <h2 className="text-2xl pb-2">
        {i18n._(t('networkSettings.wizard.step1.title')`Raspberry Pi setup`)}
      </h2>

      <p className="text-gray-700">
        {step <= 5 && i18n._(t('networkSettings.wizard.step1.subTitle')`Get your Raspberry Pi Ready by following this wizard.`)}
      </p>
    </div>
    <div className="col-span-2 text-gray-700">

      {step <= 4 && <>
        {step === 0 && <div>
          This wizard will guide you through the process of getting your Raspberry Pi linked to your DareScouts account. <br/><br/>
          <strong>Requirements:</strong> <br/>
          - Raspberry Pi and Ethernet cable.<br/>
          - Suitable power adapter for the Raspberry Pi and matching USB cable.<br/>
          - An SD flashed with the latest DareScout Net image found in <a className="link text-base" href="https://darescouts.com/guides/rpi">https://darescouts.com/guides/rpi</a>.<br/>
          - This setup process must be performed from a device that can access WiFi networks.<br/>
          <br/>

        </div>}

        {step > 0 && <h2 className="pb-2 text-2xl text-gray-900">Step {step}</h2>}
        {step === 1 && <div>
          Get a newly flashed SD card with the latest DareScouts server, you can request a new SD from us or follow our guide in <a className="link text-base" href="https://darescouts.com/guides/rpi">https://darescouts.com/guides/rpi</a>.
          <br/>
          <br/>
          Insert the newly flashed SD card in the Raspberry Pi SD slot. <br/>
          <br/>
          Ensure your Raspberry Pi is <strong>NOT</strong> powered when installing the SD card. <br/>
        </div>}
        {step === 2 && <div>
          Plug one end of the ethernet cable to the Raspberry Pi. <br/>
          <br/>
          And plug the other end of the ethernet cable to any free port on your internet router (<strong>NOT</strong> the Fibre box.). <br/>
          <br/>
        </div>}
        {step === 3 && <div>
          Power your Raspberry Pi using a suitable USB cable and power adapter. <br/> <br/>

          Allow a minute for the Raspberry Pi to boot. <br/><br/>
        </div>}
        {step === 4 && <div>
          Without closing this app, switch this device WiFi to use the new "DareScouts Setup" WiFi made available by the Raspberry Pi. <br/>

        </div>}

        { stepsToConfirm[`step${step}`] &&
          <>
            <br/><br/><input disabled={busy} type="checkbox" checked={stepAccepted === step} onChange={() => setStepAccepted(stepAccepted === step ? -1 : step)} /> {stepsToConfirm[`step${step}`]}
          </>
        }

        <div className="grid grid-cols-2 gap-2 py-2">
          <div className="">
            <Button
              size="extra-small"
              variant="actions-cancel"
              disabled={busy}
              to={returnTo ? `${returnTo}` : '/settings'}
            >
              {i18n._(t('networkSettings.cancel')`Cancel`)}
            </Button>
          </div>
          <div className="">
            <Button
              size="extra-small"
              variant="actions-accept"
              disabled={(stepsToConfirm[`step${step}`] && stepAccepted !== step) || busy}
              onClick={() => stepsActions[`step${step}`] ? stepsActions[`step${step}`]() : setStep(step + 1)}
            >
              {i18n._(t('networkSettings.Continue')`Continue`)}
            </Button>
          </div>
        </div>
        {error && <p className="text-red-500">{error}</p>}

      </>}

      {step === 5 && <>
        <div className="">
          <div className="text-gray-700 py-2 mt-3">
            Name for your new managed WiFi
          </div>
          <input
            ref={firstInput}
            className="input"
            type="text"
            placeholder={i18n._(t('networkSettings.name')`Network Name`)}
            name="name"
            value={form.name}
            onChange={updateField}
          />
          <span className="text-xs">WiFi name: "{form.name} {networkNameSuffix}"</span>
        </div>

        <div className="">
          <div className="text-gray-700 py-2 mt-3">
            Password for your new managed WiFi
          </div>
          <div>
            <input
              ref={firstInput}
              className="input"
              type="text"
              placeholder={i18n._(t('networkSettings.name')`Network Password`)}
              name="password"
              value={form.password}
              onChange={updateField}
            />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-2 py-2">
          <div className="">
            <Button
              size="extra-small"
              variant="actions-cancel"
              to={returnTo ? `${returnTo}` : '/settings'}
            >
              {i18n._(t('networkSettings.cancel')`Cancel`)}
            </Button>
          </div>
          <div className="">
            <Button
              size="extra-small"
              variant="actions-accept"
              disabled={busy}
              onClick={handleAcceptAndFinalize}
            >
              {i18n._(t('networkSettings.finaliseSetup')`Finish`)}
            </Button>
          </div>
        </div>
        {error && <p className="text-red-500">{error}</p>}
      </>}

      {step === 6 && <>
        Congratulations <br/>
        You can now start using the new managed Wifi for your kid's devices: <br/><br/>
        WiFi: <strong>{form.name} {networkNameSuffix}</strong><br/>
        Password: <strong>{form.password}</strong> <br/>

      </>}

      {error && <p className="text-xs italic text-red-500">{error.message}</p>}

    </div>
  </>);
};

export default NetworkSettings;
